import { Column } from "devextreme-react/cjs/data-grid";
import { EstoqueTipoHelper } from "../../../../features/estoque/estoque/models/estoque.enums";
import { EstoqueGridModel } from "../../../../models/api/estoque/estoque";
import criarNameof from "../../../../utils/common/cria-name-of";
import { gerarLookupDoDicionario } from "../../../../utils/common/enum-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";

const nameOfGridHandler = criarNameof<EstoqueGridModel>();

export const colunasEstoques = [
  <Column
    key={nameOfGridHandler("codigoItem")}
    dataField={nameOfGridHandler("codigoItem")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Item"
  />,
  <Column
    key={nameOfGridHandler("descricaoItem")}
    dataField={nameOfGridHandler("descricaoItem")}
    {...obterConfiguracaoColuna("stringGG")}
    allowEditing={false}
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("itemVendavel")}
    dataField={nameOfGridHandler("itemVendavel")}
    {...obterConfiguracaoColuna("stringMP")}
    allowEditing={false}
    caption="Item vendável"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("codigoGrupo")}
    dataField={nameOfGridHandler("codigoGrupo")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Código do grupo"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("descricaoGrupo")}
    dataField={nameOfGridHandler("descricaoGrupo")}
    {...obterConfiguracaoColuna("stringGG")}
    allowEditing={false}
    caption="Descrição do grupo"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("codigoSuperGrupo")}
    dataField={nameOfGridHandler("codigoSuperGrupo")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Dentro do grupo (código)"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("descricaoSuperGrupo")}
    dataField={nameOfGridHandler("descricaoSuperGrupo")}
    {...obterConfiguracaoColuna("stringGG")}
    allowEditing={false}
    caption="Dentro do grupo (descrição)"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("quantidade")}
    dataField={nameOfGridHandler("quantidade")}
    {...obterConfiguracaoColuna("quantidade")}
    allowEditing={false}
    caption="Quantidade em estoque"
    width={185}
  />,
  <Column
    key={nameOfGridHandler("unidadeMedidaEngenharia")}
    dataField={nameOfGridHandler("unidadeMedidaEngenharia")}
    {...obterConfiguracaoColuna("stringP")}
    allowEditing={false}
    caption="Unidade de medida"
    width={155}
  />,
  <Column
    key={nameOfGridHandler("custoDeAquisicaoOuOrcamentacao")}
    dataField={nameOfGridHandler("custoDeAquisicaoOuOrcamentacao")}
    {...obterConfiguracaoColuna("monetario")}
    allowEditing={false}
    caption="Custo de Aquisição"
    visible={false}
    width={160}
  />,
  <Column
    key={nameOfGridHandler("estoque")}
    dataField={nameOfGridHandler("estoque")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Estoque"
  />,
  <Column
    key={nameOfGridHandler("enderecoDeEstoque")}
    dataField={nameOfGridHandler("enderecoDeEstoque")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Endereço de estoque"
  />,
  <Column
    key={nameOfGridHandler("enderecoDeEstoquePai")}
    dataField={nameOfGridHandler("enderecoDeEstoquePai")}
    {...obterConfiguracaoColuna("stringG")}
    allowEditing={false}
    caption="Endereço de estoque pai"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("tipo")}
    dataField={nameOfGridHandler("tipo")}
    {...obterConfiguracaoColuna("stringMG")}
    allowEditing={false}
    lookup={gerarLookupDoDicionario(EstoqueTipoHelper.estoqueTipoDescricao)}
  />,
  <Column
    key={nameOfGridHandler("empresaDonaApelido")}
    dataField={nameOfGridHandler("empresaDonaApelido")}
    {...obterConfiguracaoColuna("stringMG")}
    allowEditing={false}
    caption="Dono"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("empresaPosseApelido")}
    dataField={nameOfGridHandler("empresaPosseApelido")}
    {...obterConfiguracaoColuna("stringMG")}
    allowEditing={false}
    caption="Em Poder de"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("quantidadeUnidadeDeVenda")}
    dataField={nameOfGridHandler("quantidadeUnidadeDeVenda")}
    {...obterConfiguracaoColuna("quantidade")}
    allowEditing={false}
    caption="Quantidade da unidade de venda"
    visible={false}
    width={240}
  />,
  <Column
    key={nameOfGridHandler("unidadeMedidaVenda")}
    dataField={nameOfGridHandler("unidadeMedidaVenda")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Unidade Medida Venda"
    visible={false}
    width={210}
  />,
  <Column
    key={nameOfGridHandler("precoVendaUnitario")}
    dataField={nameOfGridHandler("precoVendaUnitario")}
    {...obterConfiguracaoColuna("monetario")}
    allowEditing={false}
    caption="Preço de venda unitário"
    visible={false}
    width={180}
  />,
  <Column
    key={nameOfGridHandler("precoVendaTotal")}
    dataField={nameOfGridHandler("precoVendaTotal")}
    {...obterConfiguracaoColuna("monetario")}
    allowEditing={false}
    caption="Preço de venda total"
    visible={false}
    width={160}
  />,
  <Column
    key={nameOfGridHandler("qualidade")}
    dataField={nameOfGridHandler("qualidade")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Qualidade"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("dataFabricacao")}
    dataField={nameOfGridHandler("dataFabricacao")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    allowEditing={false}
    caption="Data de fabricação"
    width={160}
  />,
  <Column
    key={nameOfGridHandler("dataVencimento")}
    dataField={nameOfGridHandler("dataVencimento")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    allowEditing={false}
    caption="Data de vencimento"
    visible={false}
    width={160}
  />,
  <Column
    key={nameOfGridHandler("dataReanalise")}
    dataField={nameOfGridHandler("dataReanalise")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    allowEditing={false}
    caption="Data de reanálise"
    visible={false}
    width={160}
  />,
  <Column
    key={nameOfGridHandler("loteInterno")}
    dataField={nameOfGridHandler("loteInterno")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Lote Interno"
  />,
  <Column
    key={nameOfGridHandler("loteFabricanteNumeroSerie")}
    dataField={nameOfGridHandler("loteFabricanteNumeroSerie")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Lote do fabricante/Numero Série"
    width={260}
  />,
  <Column
    key={nameOfGridHandler("observacaoLote")}
    dataField={nameOfGridHandler("observacaoLote")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Observação Lote"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("fabricante")}
    dataField={nameOfGridHandler("fabricante")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Fabricante"
  />,
  <Column
    key={nameOfGridHandler("custoUnitario")}
    dataField={nameOfGridHandler("custoUnitario")}
    {...obterConfiguracaoColuna("monetario")}
    allowEditing={false}
    caption="Custo unitário"
    width={125}
  />,
  <Column
    key={nameOfGridHandler("custoTotal")}
    dataField={nameOfGridHandler("custoTotal")}
    {...obterConfiguracaoColuna("monetario")}
    allowEditing={false}
    caption="Custo total"
    width={125}
  />,
  <Column
    key={nameOfGridHandler("pesoLiquidoTotal")}
    dataField={nameOfGridHandler("pesoLiquidoTotal")}
    {...obterConfiguracaoColuna("peso")}
    allowEditing={false}
    caption="Peso líquido total"
    visible={false}
    width={190}
  />,
  <Column
    key={nameOfGridHandler("pesoLiquidoUnitario")}
    dataField={nameOfGridHandler("pesoLiquidoUnitario")}
    {...obterConfiguracaoColuna("peso")}
    allowEditing={false}
    caption="Peso líquido unitário"
    visible={false}
    width={190}
  />,
  <Column
    key={nameOfGridHandler("pesoBrutoUnitario")}
    dataField={nameOfGridHandler("pesoBrutoUnitario")}
    {...obterConfiguracaoColuna("peso")}
    allowEditing={false}
    caption="Peso bruto unitário"
    visible={false}
    width={180}
  />,
  <Column
    key={nameOfGridHandler("pesoBrutoTotal")}
    dataField={nameOfGridHandler("pesoBrutoTotal")}
    {...obterConfiguracaoColuna("peso")}
    allowEditing={false}
    caption="Peso bruto total"
    visible={false}
    width={180}
  />,
  <Column
    key={nameOfGridHandler("ncm")}
    dataField={nameOfGridHandler("ncm")}
    {...obterConfiguracaoColuna("stringP")}
    allowEditing={false}
    caption="NCM"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("estoqueMinimo")}
    dataField={nameOfGridHandler("estoqueMinimo")}
    width={160}
    caption="Estoque mínimo"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("descricaoComplementarItem")}
    dataField={nameOfGridHandler("descricaoComplementarItem")}
    {...obterConfiguracaoColuna("stringGG")}
    allowEditing={false}
    caption="Descrição complementar do item"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("codigoSeparadorExpedicao")}
    dataField={nameOfGridHandler("codigoSeparadorExpedicao")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Código do separador da expedição"
    visible={false}
    width={245}
  />,
  <Column
    key={nameOfGridHandler("codigoVolumeMaster")}
    dataField={nameOfGridHandler("codigoVolumeMaster")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Código do volume master"
    visible={false}
    width={190}
  />,
  <Column
    key={nameOfGridHandler("codigoVolume")}
    dataField={nameOfGridHandler("codigoVolume")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Código do volume"
    visible={false}
  />,
  ...GetColunasDeAuditoria(),
];
