import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { PedidoDeVendaGridModel } from "../models/pedidos-de-venda.api";
import { FiltrosGridPedidoVenda } from "../utils/pedidos-de-venda.filtros";

export default class PedidosDeVendaService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.PedidoDeVenda);
  }

  public GetDadosSelectBoxPedidoDeVenda(): [
    DataSource<PedidoDeVendaGridModel>,
    ConfiguracaoExibicaoEBusca<PedidoDeVendaGridModel>
  ] {
    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<PedidoDeVendaGridModel>({
        camposRetorno: ["id", "numero", "apelidoCliente"],
        camposOrdenacao: [{ campo: "numero", desc: false }],
        filtroExato: FiltrosGridPedidoVenda.pedidoNaoFinalizadoRaw,
      });

    const configs =
      assertConfiguracaoExibicaoEBuscaType<PedidoDeVendaGridModel>({
        nomeCampoChave: "id",
        expressaoDeBusca: ["numero"],
        nomeCampoExibicao: (pv) =>
          pv ? `${pv.numero} (${pv.apelidoCliente})` : "",
      });

    return [dataSource, configs];
  }
}
