import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/cjs/data-grid";
import { useCallback, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { decodificaBooleanoEmSimNao } from "../../../../../models/api/comum/decodificadores";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { EstacaoDeTrabalhoGridModel } from "../../models/estacao-de-trabalho";
import { EstacaoDeTrabalhoServico } from "../../servicos/estacao-de-trabalho";

const service = new EstacaoDeTrabalhoServico();

const nameOfGridHandler = criarNameof<EstacaoDeTrabalhoGridModel>();

export default function GridEstacaoDeTrabalho(
  props: GridMxpProps<EstacaoDeTrabalhoGridModel>
) {
  const gridRef = useRef<DataGrid>(null);

  useSeletorGridMxp(() => gridRef.current);

  const dataSource =
    service.ObterDataSourceParaGrid<EstacaoDeTrabalhoGridModel>(
      props.filtrosNoServidor
    );

  const handleNovoRegistro = useCallback(() => {
    exibirNotificacaoToast({
      mensagem: "Funcionalidade não implementada.",
      tipo: TipoNotificacao.Informacao,
    });
  }, []);

  const handleEditarRegistro = useCallback(() => {
    exibirNotificacaoToast({
      mensagem: "Funcionalidade não implementada.",
      tipo: TipoNotificacao.Informacao,
    });
  }, []);

  const handleExcluirRegistro = useCallback(async () => {
    exibirNotificacaoToast({
      mensagem: "Funcionalidade não implementada.",
      tipo: TipoNotificacao.Informacao,
    });
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const gridController = new GridController<EstacaoDeTrabalhoGridModel>(
    () => gridRef.current?.instance
  );

  useRegistrarAtalhosGrid<EstacaoDeTrabalhoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "estacao-de-trabalho",
      () => gridRef.current?.instance,
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.estacaoDeTrabalho)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.filtrosNoCliente]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-estacao-de-trabalho"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<EstacaoDeTrabalhoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column
            key={nameOfGridHandler("codigo")}
            dataField={nameOfGridHandler("codigo")}
            {...obterConfiguracaoColuna("stringP")}
            sortIndex={0}
            sortOrder="asc"
            caption="Código"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Descrição"
          />
          <Column
            key={nameOfGridHandler("ativo")}
            dataField={nameOfGridHandler("ativo")}
            {...obterConfiguracaoColuna("boolSimNao")}
            caption="Ativo"
            cellRender={({ data }) => {
              const dados = data as EstacaoDeTrabalhoGridModel;
              return decodificaBooleanoEmSimNao(dados.ativo);
            }}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>
    </>
  );
}
