import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { DataSourceFiltragem } from "../../../../utils/grid/data-source-factory";
import { NumeroDeSeriePrefixoGridModel } from "../models/numero-de-serie-prefixo";

export default class NumeroDeSeriePrefixoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.NumeroDeSeriePrefixo);
  }

  public GetDadosSelectBoxNumeroDeSeriePrefixo(
    filtros:
      | DataSourceFiltragem<NumeroDeSeriePrefixoGridModel>[]
      | undefined = undefined
  ): [
    DataSource<NumeroDeSeriePrefixoGridModel, any>,
    ConfiguracaoExibicaoEBusca<NumeroDeSeriePrefixoGridModel>
  ] {
    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<NumeroDeSeriePrefixoGridModel>({
        camposRetorno: ["id"],
        camposOrdenacao: [{ campo: "prefixo", desc: false }],
        camposFiltro: filtros,
      });

    const configs =
      assertConfiguracaoExibicaoEBuscaType<NumeroDeSeriePrefixoGridModel>({
        nomeCampoChave: "id",
        expressaoDeBusca: ["prefixo"],
        nomeCampoExibicao: (c) => {
          if (c) {
            return `${c.prefixo}`;
          }

          return "";
        },
      });

    return [dataSource, configs];
  }
}
