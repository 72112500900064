import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { DataSourceFiltragem } from "../../../../utils/grid/data-source-factory";
import { OperacaoDaOrdemDeProducaoGridModel } from "../models/operacao-de-ordem-de-producao";

export class OperacaoDeOrdemDeProducaoServico extends ServiceBase {
  constructor() {
    super(NomesEndpoints.OperacaoDeOrdemDeProducao);
  }

  public GetDadosSelectBoxOperacaoDaOrdemDeProducao(
    filtros:
      | DataSourceFiltragem<OperacaoDaOrdemDeProducaoGridModel>[]
      | undefined = undefined
  ): [
    DataSource<OperacaoDaOrdemDeProducaoGridModel, any>,
    ConfiguracaoExibicaoEBusca<OperacaoDaOrdemDeProducaoGridModel>
  ] {
    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<OperacaoDaOrdemDeProducaoGridModel>(
        {
          camposRetorno: ["id", "ordemDeProducaoId", "numero", "descricao"],
          camposOrdenacao: [{ campo: "numero", desc: false }],
          camposFiltro: filtros,
        }
      );

    const configs =
      assertConfiguracaoExibicaoEBuscaType<OperacaoDaOrdemDeProducaoGridModel>({
        nomeCampoChave: "id",
        expressaoDeBusca: ["numero", "descricao"],
        nomeCampoExibicao: (c) => {
          if (c) {
            return c.descricao == null
              ? `${c.numero}`
              : `${c.numero} - ${c.descricao}`;
          }

          return "";
        },
      });

    return [dataSource, configs];
  }
}
