import { OrdemDeProducaoRequest } from "../../../models/ordem-de-producao.api";

export enum OrdemDeProducaoAbasIndices {
  Insumos = 0,
  Reservas = 1,
  Vinculos = 2,
  Aparas = 3,
  RetiradaDeMateriais = 4,
  Conclusoes = 5,
  OutroDados = 6,
  Anexos = 7,
  Anotacoes = 8,
}

export const dicionarioDosIndicesDasAbasPorCampo: {
  [key in keyof OrdemDeProducaoRequest]?: number;
} = {
  quantidadeDePerdaPrevista: OrdemDeProducaoAbasIndices.OutroDados,
  quantidadeBruta: OrdemDeProducaoAbasIndices.OutroDados,
  observacoes: OrdemDeProducaoAbasIndices.OutroDados,
};
