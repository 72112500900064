import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import { EmpresaGridModel } from "../../../../../models/api/empresa/empresa-grid";
import { GridEmpresa } from "../../../../../parts/empresas/empresa/grids";
import EmpresaServico from "../../servicos/empresa.service";

interface ComboEmpresaMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  tituloSeletor?: string;
  requerido?: boolean;
}

const apiEmpresa = new EmpresaServico();

const dataSourceEmpresaGrid = apiEmpresa.GetDataSourceSelectBoxLazy({
  camposRetorno: ["id", "apelido", "cnpjCpfCodigo"],
  camposOrdenacao: [
    {
      nomeCampo: "apelido",
      desc: false,
    },
  ],
});

const empresaGridExibicaoEBusca =
  assertConfiguracaoExibicaoEBuscaType<EmpresaGridModel>({
    expressaoDeBusca: ["apelido", "cnpjCpfCodigo"],
    nomeCampoChave: "id",
    nomeCampoExibicao: (c) => {
      if (c) {
        return `${c.apelido} - ${c.cnpjCpfCodigo}`;
      }

      return "";
    },
  });

export default function ComboEmpresaMxp<T extends FieldValues>(
  props: ComboEmpresaMxpProps<T>
) {
  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      dataSource={dataSourceEmpresaGrid}
      configuracoesExibicaoEBusca={empresaGridExibicaoEBusca}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: props.tituloSeletor || "Selecione um empresa",
        componenteGrid: <GridEmpresa />,
      }}
      labelSemDados="Sem dados"
      requerido={props.requerido}
    />
  );
}
