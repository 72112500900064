import { Accordion, TabPanel } from "devextreme-react";
import NestedOption from "devextreme-react/cjs/core/nested-option";
import ArrayStore from "devextreme/data/array_store";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { CSSProperties } from "styled-components";
import "./showhide.scss";

interface AccordionItemPropsBase {
  children: React.ReactNode;
  style?: CSSProperties;
  aberto?: boolean;
  carregarApenasQuandoAberto?: boolean;
}

interface IShowHideItemProps extends AccordionItemPropsBase {
  disabled?: boolean;
  html?: string;
  icon?: string;
  text: string;
  index?: number;
}

interface AccordionItemProps extends AccordionItemPropsBase {
  usaAba?: boolean;
}

class ShowHideItem extends NestedOption<IShowHideItemProps> {
  static OptionName: string;
  static IsCollectionItem: boolean;
  static TemplateProps: {
    tmplOption: string;
    render: string;
    component: string;
    keyFn: string;
  }[];
}

interface ShowHideProps {
  id: string;
  idRegistroEmEdicao: number;
  children: React.ReactNode;
  usarAba?: boolean;
  height?: number | string | (() => number | string);
}

const AccordionTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

const AccordionItem = (props: AccordionItemProps) => {
  if (props.usaAba) {
    return (
      <>
        {(props.aberto || props.carregarApenasQuandoAberto != true) &&
          props.children}
      </>
    );
  }
  return (
    <div
      className="dx-item-content showhide-item"
      style={{
        ...props.style,
      }}
    >
      {(props.aberto || props.carregarApenasQuandoAberto != true) &&
        props.children}
    </div>
  );
};

const CriarShowHideItem = (
  props: IShowHideItemProps,
  usaAba: boolean | undefined
) => {
  return (
    <AccordionItem
      style={props.style}
      carregarApenasQuandoAberto={props.carregarApenasQuandoAberto}
      aberto={props.aberto}
      usaAba={usaAba}
    >
      {props.children}
    </AccordionItem>
  );
};

const arrayVazio = new ArrayStore({
  key: "text",
  data: [],
});

function ShowHide(props: ShowHideProps) {
  const [selectedItems, setSelectedItems] = useState([] as string[]);
  const [itensData, setItensData] = useState<ArrayStore>(arrayVazio);
  const [carregar] = useState([] as boolean[]);
  const ref = useRef<Accordion>(null);

  const selectedItemKeysChange = useCallback(async (e: any[]) => {
    setSelectedItems(e);
  }, []);

  useEffect(() => {
    carregar.length = 0;
    selectedItems.length = 0;
  }, [props.idRegistroEmEdicao]);

  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    carregarItens();
  }, [props.children]);

  async function carregarItens() {
    const itensAbertos = [] as string[];
    const elementos = React.Children.map(props.children, (child, index) => {
      if (!React.isValidElement<IShowHideItemProps>(child)) {
        return undefined;
      }

      const elementChild = child.props;
      if (elementChild.aberto) {
        itensAbertos.push(elementChild.text);
      }

      carregar[index] =
        elementChild.carregarApenasQuandoAberto != true ||
        itensAbertos.includes(elementChild.text);

      return { ...elementChild, index: index };
    })?.filter((x) => x != undefined) as IShowHideItemProps[];

    const dataSouce = new ArrayStore({
      key: "text",
      data: elementos,
    });

    setItensData(dataSouce);
    ref.current?.instance.option("dataSource", dataSouce);
    ref.current?.instance.option("selectedItemKeys", itensAbertos);
  }

  const itemRender = useCallback(
    (e: IShowHideItemProps) => {
      return CriarShowHideItem(e, props.usarAba);
    },
    [props.usarAba]
  );
  const itemTitleRender = useCallback((e: IShowHideItemProps) => {
    return <AccordionTitle>{e.text}</AccordionTitle>;
  }, []);
  const mostrarItem = useCallback(
    (e: any) => {
      if (carregar[e.itemIndex]) {
        return;
      }
      carregar[e.itemIndex] = true;
      const obj = {
        ...e.itemData,
        aberto: carregar[e.itemIndex],
      };
      itensData.push([{ type: "update", data: obj, key: obj.text }]);
      itensData.update(obj.text, obj);
    },
    [itensData]
  );
  const itemKeyExpr = useCallback((e: IShowHideItemProps) => e.text, []);

  if (props.usarAba) {
    return (
      <TabPanel
        id={props.id}
        className="showhide"
        deferRendering={false}
        showNavButtons
        repaintChangesOnly={true}
        swipeEnabled={false}
        onTitleClick={mostrarItem}
        dataSource={itensData}
        itemRender={itemRender}
        itemTitleRender={itemTitleRender}
        height={props.height}
      />
    );
  }
  return (
    <Accordion
      ref={ref}
      id={props.id}
      className="showhide"
      collapsible
      multiple
      repaintChangesOnly={true}
      dataSource={itensData}
      selectedItemKeys={selectedItems}
      keyExpr={itemKeyExpr}
      onItemClick={mostrarItem}
      onSelectedItemKeysChange={selectedItemKeysChange}
      deferRendering={false}
      itemRender={itemRender}
      itemTitleRender={itemTitleRender}
    />
  );
}

export default ShowHide;
export { ShowHideItem };
