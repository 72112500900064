import { UseFormReturn } from "react-hook-form";
import RastreabilidadePorLote from "../../../../../../estoque/lote/componentes/formulario-cadastro-de-rastreabilidade";
import { ConcluirOrdemDeProducaoRequest } from "../../../../models/ordem-de-producao.api";

interface ConcluirOpAbaRastreabilidadeLoteProps {
  hookForms: UseFormReturn<ConcluirOrdemDeProducaoRequest>;
}

export default function ConcluirOpAbaRastreabilidadeLote(
  props: ConcluirOpAbaRastreabilidadeLoteProps
) {
  const { watch } = props.hookForms;

  return (
    <RastreabilidadePorLote
      hookForms={props.hookForms as any}
      itemUtilizaPrefixo={watch("itemUtilizaPrefixo") ?? false}
    />
  );
}
