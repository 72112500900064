import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { ItemSelectionChangedType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import { DataSourceFiltragem } from "../../../../../utils/grid/data-source-factory";
import { InsumoDaOrdemDeProducaoGridModel } from "../../models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoService } from "../../servicos/insumo-da-ordem-de-producao";

interface ComboInsumoPorOpMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  idOrdemDeProducao: number;
  titulo: string;
  requerido?: boolean;
  somenteLeitura?: boolean;
  onSelectionChanged?:
    | ((e: ItemSelectionChangedType) => Promise<void>)
    | (() => void);
}

const service = new InsumoDaOrdemDeProducaoService();

export default function ComboInsumoPorOpMxp<T extends FieldValues>(
  props: ComboInsumoPorOpMxpProps<T>
) {
  const [dataSourceOperacao, configuracoesExibicaoEBuscaOperacao] =
    useMemo(() => {
      const filtros: DataSourceFiltragem<InsumoDaOrdemDeProducaoGridModel>[] = [
        {
          campo: "ordemDeProducaoId",
          operador: "=",
          valor: props.idOrdemDeProducao,
        },
      ];
      return service.GetDadosSelectBoxItem(filtros);
    }, [props.idOrdemDeProducao]);

  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      requerido={props.requerido}
      somenteLeitura={props.somenteLeitura}
      onSelectionChanged={props.onSelectionChanged}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBuscaOperacao}
      dataSource={dataSourceOperacao}
      labelSemDados="Sem dados"
    />
  );
}
