import { PropsWithChildren, useState } from "react";
import ContextoMenuEdicaoOrdemDeProducao, {
  ItensMenuEdicaoOrdemDeProducao,
} from "../contexto-menu-op";

export function ProvedorMenuEdicaoOrdemDeProducao(
  props: PropsWithChildren<any>
) {
  const [menu, setMenu] = useState<ItensMenuEdicaoOrdemDeProducao>(
    new ItensMenuEdicaoOrdemDeProducao()
  );
  return (
    <ContextoMenuEdicaoOrdemDeProducao.Provider
      value={{
        menu: menu,
        setMenu: setMenu,
      }}
    >
      {props.children}
    </ContextoMenuEdicaoOrdemDeProducao.Provider>
  );
}
