import { AxiosResponse, HttpStatusCode } from "axios";
import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import getApi from "../../../../configs/api";
import { ArquivoBaseModel } from "../../../../models/api/arquivos/arquivo";
import { CadastrarEmMassaResponse } from "../../../../models/api/comum/cadastrar-varios";
import {
  ResponseBase,
  ResponseBaseArquivo,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { ReservaDaOrdemDeProducaoGridModel } from "../../../../models/api/reserva/reserva-da-ordem-de-producao-grid-model";
import { VinculoDaOrdemDeProducaoGridModel } from "../../../../models/api/vinculo/vinculo-da-ordem-de-producao-grid-model";
import APIAnexos, {
  IAnexosService,
} from "../../../../services/comum/anexos/anexos.service";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { tratarErroApi } from "../../../../utils/api/api-utils";
import DataSourceFactory, {
  DataSourceFiltragem,
} from "../../../../utils/grid/data-source-factory";
import { TarefaOuAnotacaoGridModel } from "../../../crm/tarefa-ou-anotacao/models/tarefa-ou-anotacao.api";
import { ItemService } from "../../../itens/item/servicos/item.service";
import {
  ConcluirOrdemDeProducaoRequest,
  OrdemDeProducaoCadastrarVariosExcelRequest,
  OrdemDeProducaoGridModel,
  OrdemDeProducaoRequest,
  OrdemDeProducaoResponse,
  RetiradaDeMaterialRequest,
} from "../models/ordem-de-producao.api";
import {
  EstadoDecodificado,
  OrdemDeProducaoEstado,
} from "../utils/enums/ordem-de-producao-enums";

const itemService = new ItemService();

export class OrdemDeProducaoService
  extends ServiceBase
  implements IAnexosService
{
  constructor() {
    super(NomesEndpoints.OrdemDeProducao);
  }

  public GetDadosSelectBoxOrdemDeProducao(): [
    DataSource<OrdemDeProducaoGridModel, any>,
    ConfiguracaoExibicaoEBusca<OrdemDeProducaoGridModel>
  ] {
    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<OrdemDeProducaoGridModel>({
        camposRetorno: [
          "id",
          "numero",
          "itemId",
          "itemCodigo",
          "itemDescricao",
          "quantidade",
        ],
        camposOrdenacao: [{ campo: "numero", desc: true }],
        camposFiltro: [
          {
            campo: "estadoDecodificado",
            operador: "=",
            valor: EstadoDecodificado.AProduzir,
          },
        ],
      });

    const configs =
      assertConfiguracaoExibicaoEBuscaType<OrdemDeProducaoGridModel>({
        nomeCampoChave: "id",
        expressaoDeBusca: ["numero", "itemCodigo", "itemDescricao"],
        nomeCampoExibicao: (c) => {
          if (c) {
            return c.itemDescricao
              ? `${c.numero} - ${c.itemCodigo} - (${c.itemDescricao})`
              : `${c.numero} - ${c.itemCodigo}`;
          }

          return "";
        },
      });

    return [dataSource, configs];
  }

  public async obterPorIdComItem(id: number) {
    const api = getApi();

    const response = await api.get<ResponseModel<OrdemDeProducaoResponse>>(
      `${this._nomeEndpoint}/obterPorIdComItem?id=${id}`
    );

    return response.data;
  }

  public async CadastrarEmMassaExcel(
    ops: OrdemDeProducaoCadastrarVariosExcelRequest[]
  ) {
    try {
      const idsItens = await itemService.obterIdPorCodigo(
        ops.map((op) => op.codigoItem)
      );

      ops.forEach((op) => {
        if (!idsItens[op.codigoItem]) {
          op.erros += `\nNão foi possível encontrar item com o código: "${op.codigoItem}"`;
        }
      });

      const requests = ops
        .filter((op) => op.erros.length === 0)
        .map((op) => ({
          id: 0,
          numero: NaN,
          itemId: idsItens[op.codigoItem],
          fixarQuantidade: true,
          fixarNecessidadeData: true,
          estado: OrdemDeProducaoEstado.AProduzir,
          estadoConfiguravelId: NaN,
          arquivos: [],
          ...op,
          observacoes: op.observacoes || "",
        }));

      const resultado = await this.CadastrarEmMassa(requests);

      if (!resultado.sucesso) {
        return resultado;
      }

      resultado.model.forEach(
        (m, i) => (m.indiceDoRegistro = requests.at(i)?.linha ?? 0)
      );

      ops
        .filter((x) => x.erros.length > 0)
        .forEach((x) =>
          resultado.model.push({
            indiceDoRegistro: x.linha,
            id: 0,
            erros: x.erros,
          })
        );

      return resultado;
    } catch (error) {
      tratarErroApi(error);
      return {
        sucesso: false,
        model: [],
        mensagem: error,
        erros: [],
      } as ResponseModel<CadastrarEmMassaResponse[]>;
    }
  }

  public async CadastrarEmMassa(
    model: OrdemDeProducaoRequest[]
  ): Promise<ResponseModel<CadastrarEmMassaResponse[]>> {
    const api = getApi();
    const response = await api.post<ResponseModel<CadastrarEmMassaResponse[]>>(
      `${this._nomeEndpoint}/CadastrarEmMassa`,
      model
    );

    return response.data;
  }

  public async obterAnexos(id: number): Promise<ArquivoBaseModel[]> {
    return await APIAnexos.obterAnexos(id, this._nomeEndpoint);
  }

  public async anexoAlterarLista(
    id: number,
    anexos: ArquivoBaseModel[]
  ): Promise<ResponseBase> {
    return await APIAnexos.anexoAlterarLista(id, anexos, this._nomeEndpoint);
  }

  public async anexoObterDadosParaDownload(
    id: number,
    idAnexo: number
  ): Promise<AxiosResponse<ResponseBaseArquivo, any>> {
    return await APIAnexos.anexoObterDadosParaDownload(
      id,
      idAnexo,
      this._nomeEndpoint
    );
  }

  public async ObterDadosParaConcluirOrdemDeProducao(id: number) {
    const api = getApi();

    const response = await api.get<
      ResponseModel<ConcluirOrdemDeProducaoRequest>
    >(`${this._nomeEndpoint}/DadosParaConcluirOrdemDeProducao?id=${id}`);

    return response.data;
  }

  public ObterDataSourceParaGridReservas(
    id: number,
    filtroPadrao?: DataSourceFiltragem<ReservaDaOrdemDeProducaoGridModel>[]
  ) {
    return DataSourceFactory.CriarParaGrid<
      ReservaDaOrdemDeProducaoGridModel,
      number
    >(`${this._nomeEndpoint}/GridReservas?id=${id}`, filtroPadrao);
  }

  public ObterDataSourceParaGridTarefasEAnotacoes(
    id: number,
    filtroPadrao?: DataSourceFiltragem<TarefaOuAnotacaoGridModel>[]
  ) {
    return DataSourceFactory.CriarParaGrid<TarefaOuAnotacaoGridModel, number>(
      `${this._nomeEndpoint}/GridTarefasEAnotacoes?id=${id}`,
      filtroPadrao
    );
  }

  public ObterDataSourceParaGridVinculos(
    id: number,
    filtroPadrao?: DataSourceFiltragem<VinculoDaOrdemDeProducaoGridModel>[]
  ) {
    return DataSourceFactory.CriarParaGrid<
      VinculoDaOrdemDeProducaoGridModel,
      number
    >(`${this._nomeEndpoint}/GridVinculos?id=${id}`, filtroPadrao);
  }

  public async Concluir(model: ConcluirOrdemDeProducaoRequest) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/Concluir`,
      model
    );

    return response.data;
  }

  public async RetirarMaterial(model: RetiradaDeMaterialRequest) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/RetirarMaterial`,
      model
    );

    return response.data;
  }

  public async DesconcluirConclusaoDaOrdemDeProducao(idMovimentacao: number) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/DesconcluirConclusaoDaOrdemDeProducao?idMovimentacao=${idMovimentacao}`
    );

    return response.data;
  }

  public async VerificarSePodeConcluirOpSemForcarConclusao(
    model: ConcluirOrdemDeProducaoRequest
  ) {
    const api = getApi();

    try {
      const response = await api.post<ResponseBase>(
        `${this._nomeEndpoint}/VerificarSePodeConcluirOpSemForcarConclusao`,
        model
      );

      return response.data;
    } catch (error: any) {
      if (error.response.status === HttpStatusCode.BadRequest) {
        return error.response.data;
      } else {
        throw error;
      }
    }
  }
}
