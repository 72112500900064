import { Column } from "devextreme-react/data-grid";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { MxpGrid } from "../../../../components/grid";
import {
  useExcluirRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../hooks/grid.hooks";
import { useSeletorGridMxp } from "../../../../hooks/seletor.hooks";
import { EnderecoEstoqueGridModel } from "../../../../models/api/endereco-estoque/endereco-estoque";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.EnderecoEstoque;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<EnderecoEstoqueGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    caption="Código"
    {...obterConfiguracaoColuna("stringM")}
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    caption="Descrição"
    {...obterConfiguracaoColuna("stringM")}
  />,
  ...GetColunasDeAuditoria(),
];

export const GridEnderecoEstoque = forwardRef((_, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);

  usePropagarReferenciaGrid(ref, gridRef);

  useSeletorGridMxp(() => gridRef.current?.getGridRef());

  const handleExcluirRegistro = useExcluirRegistroGrid(async () => {
    exibirNotificacaoToast({
      mensagem: "Funcionalidade não implementada.",
      tipo: TipoNotificacao.Informacao,
    });

    return;
  });

  return (
    <>
      <MxpGrid<EnderecoEstoqueGridModel>
        dataSource={dataSource}
        id={"endereco-de-estoque"}
        ref={gridRef}
        colunas={colunas}
        exibirIconeEditarMenuAcoes={false}
        excluirRegistro={handleExcluirRegistro}
        nomeDoArquivoAoExportar={NomesTelas.enderecoEstoque}
      />
    </>
  );
});
