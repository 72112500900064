import DataGrid, { Column } from "devextreme-react/cjs/data-grid";
import { useMemo, useRef, useState } from "react";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import FormIncluirReservaOrdemProducao from "../../../../../features/estoque/reserva/componentes/formulario/form-incluir-reserva-ordem-producao";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { ReservaDaOrdemDeProducaoGridModel } from "../../../../../models/api/reserva/reserva-da-ordem-de-producao-grid-model";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { ItemResponse } from "../../../../itens/item/models/item.api";
import { ItemService } from "../../../../itens/item/servicos/item.service";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";

const service = new OrdemDeProducaoService();
const itemService = new ItemService();
const nameOfGridHandler = criarNameof<ReservaDaOrdemDeProducaoGridModel>();

interface GridEmbutidaReservasDaOrdemDeProducaoProps
  extends GridMxpProps<ReservaDaOrdemDeProducaoGridModel> {
  ordemDeProducaoId: number;
  ordemDeProducaoCodigo: number | undefined;
  ordemDeProducaoIdItem: number;
  ordemDeProducaoDescricaoItem: string;
  ordemDeProducaoEhSugerida: boolean;
}

export default function GridEmbutidaReservasDaOrdemDeProducao(
  props: GridEmbutidaReservasDaOrdemDeProducaoProps
) {
  const gridRef = useRef<DataGrid>(null);

  const [modalNovaReservaVisivel, setModalNovaReservaVisivel] = useState(false);
  const [ordemDeProducaoCodigoItem, setOrdemDeProducaoCodigoItem] = useState<
    string | undefined
  >(undefined);

  const dataSource = service.ObterDataSourceParaGridReservas(
    props.ordemDeProducaoId
  );

  const gridController = new GridController<ReservaDaOrdemDeProducaoGridModel>(
    () => gridRef.current?.instance
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  function handleModalReservaCallback() {
    handleAtualizarGrid();
    setModalNovaReservaVisivel(false);
  }

  const menusDeContexto: ItemContextMenuMxp[] = [];

  useRegistrarAtalhosGrid({
    controller: gridController,
    // Adicionar handlers de manipulação se/quando forem criados.
  });

  useMenuDeContextosGrid(menusDeContexto);

  const configuracoesGrid = useMemo(
    () =>
      GridBuilder.criar("reservas-op", () => gridRef?.current?.instance)
        .definirDataSource(dataSource)
        .definirFiltros()
        .definirRolagem()
        .definirBotaoNovo(async () => {
          const item = await itemService.ObterPorId<ItemResponse>(
            props.ordemDeProducaoIdItem
          );
          setOrdemDeProducaoCodigoItem(item.model.codigo);
          setModalNovaReservaVisivel(true);
        })
        .configurarSelecionadorDeColunas()
        .definirGravacaoPreferenciasGrid()
        .definirPaginacao()
        .configurarExportacao("Reservas da Ordem de Produção")
        .definirBotaoRefresh(handleAtualizarGrid)
        .definirOrdenacao()
        .definirMenuDeContexto(menusDeContexto)
        .build(),
    [gridRef, dataSource, menusDeContexto]
  );

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column
          key={nameOfGridHandler("numeroPedidoEItemFormatado")}
          dataField={nameOfGridHandler("numeroPedidoEItemFormatado")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Pedido / Item"
          width={220}
          allowFiltering={false}
        />
        <Column
          key={nameOfGridHandler("apelidoCliente")}
          dataField={nameOfGridHandler("apelidoCliente")}
          {...obterConfiguracaoColuna("stringGG")}
          caption="Cliente"
        />
        <Column
          key={nameOfGridHandler("dataEntregaItemPedidoVenda")}
          dataField={nameOfGridHandler("dataEntregaItemPedidoVenda")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Data de entrega"
          width={150}
        />
        <Column
          key={nameOfGridHandler("quantidade")}
          dataField={nameOfGridHandler("quantidade")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Quantidade"
        />
        <Column
          key={nameOfGridHandler("codigoItem")}
          dataField={nameOfGridHandler("codigoItem")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Item"
        />
        <Column
          key={nameOfGridHandler("descricaoItem")}
          dataField={nameOfGridHandler("descricaoItem")}
          {...obterConfiguracaoColuna("stringXG")}
          caption="Item"
        />
        {GetColunasDeAuditoria(false, false, [
          { ordenarPor: "criacaoData", ordem: "desc" },
        ])}
      </DataGrid>

      <ModalMxp
        titulo={"Nova reserva"}
        visivel={modalNovaReservaVisivel}
        handleFechar={handleModalReservaCallback}
      >
        <FormIncluirReservaOrdemProducao
          idOrdemDeProducao={props.ordemDeProducaoId}
          codigoItem={ordemDeProducaoCodigoItem ?? ""}
          descricaoItem={props.ordemDeProducaoDescricaoItem}
          codigoOrdemDeProducao={props.ordemDeProducaoCodigo}
          ordemDeProducaoEhSugerida={props.ordemDeProducaoEhSugerida}
          handleCallback={handleModalReservaCallback}
        />
      </ModalMxp>
    </>
  );
}
