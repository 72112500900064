import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/cjs/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import ImportadorDeRegistros, {
  colunaUtcParaData,
  MxpSchemaImportar,
} from "../../../../../components/formularios/importador";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { PermissoesLote } from "../../../../../models/direitos/estoque/lote/permissoes-lote";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  checarResponseExibeMensagemExecutadoComSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import {
  GestorEventoClickSimples,
  GestorEventoClickUnicaLinha,
} from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import yupPt from "../../../../../utils/validacao/validacao";
import {
  LoteCadastrarVariosExcelRequest,
  LoteGridModel,
} from "../../models/lote.api";
import LoteContantes from "../../models/lote.constantes";
import LoteServico from "../../servicos/lote.servico";
import {
  abrirModalDetalhesDoLoteMxp1,
  criarBoletimDeInspecao,
} from "../../utils/lote.utils";
import FormLote from "../formulario";

const service = new LoteServico();

const nameOfGridHandler = criarNameof<LoteGridModel>();

const schema: MxpSchemaImportar<LoteCadastrarVariosExcelRequest> = {
  "Lote do fabricante/número de série*": {
    prop: "loteDoFabricanteOuNumeroDeSerie",
    type: String,
    dataType: "string",
  },
  "Item*": {
    // JSON object property name.
    prop: "codigoItem",
    type: String,
    dataType: "string",
  },
  "Fabricante*": {
    prop: "codigoFabricante",
    type: String,
    dataType: "string",
  },
  "Data de fabricação": {
    prop: "fabricacaoData",
    type: colunaUtcParaData,
    dataType: "date",
  },
  "Data de vencimento": {
    prop: "vencimentoData",
    type: colunaUtcParaData,
    dataType: "date",
  },
  "Data de reanálise": {
    prop: "reanaliseData",
    type: colunaUtcParaData,
    dataType: "date",
  },
  Observações: {
    prop: "observacoes",
    type: String,
    dataType: "string",
  },
  "Observações internas": {
    prop: "observacoesInternas",
    type: String,
    dataType: "number",
  },
};

const schemaYup = yupPt.object().shape({
  codigoItem: yupPt.string().label("Item*").required(),
  codigoFabricante: yupPt.string().label("Fabricante*").required(),
  loteDoFabricanteOuNumeroDeSerie: yupPt
    .string()
    .label("Lote do fabricante/número de serie*")
    .required()
    .max(LoteContantes.LoteDoFabricanteOuNumeroDeSerieMaxLength),
});

export default function GridLote(props: GridMxpProps<LoteGridModel>) {
  const gridRef = useRef<DataGrid>(null);
  const parametroId = useParametroId();
  const [importarVisivel, setImportarVisivel] = useState(false);

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const dataSource = service.ObterDataSourceParaGrid<LoteGridModel>(
    props.filtrosNoServidor
  );

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesLote.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback((registro: LoteGridModel) => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesLote.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(registro.id);
    }
  }, []);

  const handleExcluirRegistro = useCallback(async (registro: LoteGridModel) => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([PermissoesLote.Excluir])
    ) {
      return;
    }

    const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
    const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

    if (excluir) {
      try {
        const resposta = await service.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    }
  }, []);

  function obterMensagemExclusao(registro: LoteGridModel) {
    return `Tem certeza de que deseja excluir o lote ${registro.loteDoFabricanteOuNumeroDeSerie}?`;
  }

  const gridController = new GridController<LoteGridModel>(
    () => gridRef.current?.instance
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  const handleCancelarLote = useCallback(async (data: LoteGridModel) => {
    const mensagem = renderToString(
      <>{"Tem certeza de que deseja cancelar o lote selecionado?"}</>
    );

    const cancelar = await exibirConfirmacao(
      "Confirmar cancelamento",
      mensagem
    );

    if (cancelar) {
      service
        .Cancelar(data.id)
        .then((resposta) => {
          if (resposta) {
            checarResponseExibeMensagemExecutadoComSucesso(resposta);
            handleAtualizarGrid();
          }
        })
        .catch((erro) => tratarErroApi(erro));
    }
  }, []);

  const menusDeContexto: ItemContextMenuMxp[] = [
    {
      text: "Imprimir",
      icon: "ic-material-symbols-outlined ic-print",
      gestorEventoClick: new GestorEventoClickUnicaLinha(
        (data) => {
          abrirModalDetalhesDoLoteMxp1(data.id);
        },
        () => gridController
      ),
      exibirNaLinhaDaGrid: "sempre",
      exibirNoMenuPrincipal: false,
      hint: "Imprimir lote",
    },
    {
      text: "Cancelar lote",
      icon: "ic-material-symbols-outlined ic-cancel",
      exibirNaLinhaDaGrid: "menuDeContexto",
      exibirNoMenuPrincipal: false,
      gestorEventoClick: new GestorEventoClickUnicaLinha(
        handleCancelarLote,
        () => gridController
      ),
    },
    {
      text: "Ações",
      icon: "ic-material-symbols-outlined ic-vertical",
      hint: "Menu com opções de ação",
      exibirNoMenuPrincipal: true,
      items: [
        {
          text: "Importar lotes de arquivo Excel",
          icon: "ic-material-symbols-outlined ic-file-upload",
          gestorEventoClick: new GestorEventoClickSimples(() =>
            setImportarVisivel(true)
          ),
        },
        {
          text: "Cancelar lote",
          icon: "ic-material-symbols-outlined ic-cancel",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            handleCancelarLote,
            () => gridController
          ),
        },
        {
          text: "Criar boletim de inspeção",
          exibirNaLinhaDaGrid: "menuDeContexto",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            (data) => {
              criarBoletimDeInspecao(
                data.id,
                data.loteDoFabricanteOuNumeroDeSerie
              );
            },
            () => gridController
          ),
        },
      ],
    },
  ];

  useRegistrarAtalhosGrid<LoteGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleImportarExcel = useCallback(
    async (registros: LoteCadastrarVariosExcelRequest[]) => {
      const resultado = await service.CadastrarEmMassaExcel(registros);
      handleAtualizarGrid();
      return resultado;
    },
    [service]
  );

  useMenuDeContextosGrid(menusDeContexto);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "lote",
      () => gridRef.current?.instance,
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirFiltros()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.lotes)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirMenuDeContexto(menusDeContexto)
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.filtrosNoCliente]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-lote"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<LoteGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column
            key={nameOfGridHandler("item")}
            dataField={nameOfGridHandler("item")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Item"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Descrição"
          />
          <Column
            key={nameOfGridHandler("fabricante")}
            dataField={nameOfGridHandler("fabricante")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Fabricante"
          />
          <Column
            key={nameOfGridHandler("loteDoFabricanteOuNumeroDeSerie")}
            dataField={nameOfGridHandler("loteDoFabricanteOuNumeroDeSerie")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Lote/número de série do fabricante"
            alignment="right"
          />
          <Column
            key={nameOfGridHandler("estocagem")}
            dataField={nameOfGridHandler("estocagem")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Estocagem"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("dono")}
            dataField={nameOfGridHandler("dono")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Dono"
          />
          <Column
            key={nameOfGridHandler("reanalise")}
            dataField={nameOfGridHandler("reanalise")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Reanálise"
            visible={false}
            width={115}
          />
          <Column
            key={nameOfGridHandler("vencimento")}
            dataField={nameOfGridHandler("vencimento")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Vencimento"
            width={115}
          />
          <Column
            key={nameOfGridHandler("fabricacao")}
            dataField={nameOfGridHandler("fabricacao")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Fabricação"
            visible={false}
            width={115}
          />
          <Column
            key={nameOfGridHandler("observacoes")}
            dataField={nameOfGridHandler("observacoes")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Observações"
          />
          <Column
            key={nameOfGridHandler("observacoesInternas")}
            dataField={nameOfGridHandler("observacoesInternas")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Observações internas"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("estadoDecodificado")}
            dataField={nameOfGridHandler("estadoDecodificado")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Estado"
            visible={false}
          />
          {GetColunasDeAuditoria(true, false, [
            { ordenarPor: "criacaoData", ordem: "desc" },
          ])}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.lote
        )}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(60vw, 800px)"}
        altura={"max(60vh, 625px)"}
      >
        <FormLote
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>

      <ImportadorDeRegistros
        titulo="Importar lotes de arquivo Excel"
        onFechar={() => setImportarVisivel(false)}
        nomeDaPlanilha="Lotes"
        schema={schema}
        visivel={importarVisivel}
        onImportar={handleImportarExcel}
        modeloUrl={"../modelosXlsx/Planilha_Modelo_Lotes_MAXIPROD.xlsx"}
        schemaYup={schemaYup}
      />
    </>
  );
}
