import { Column } from "devextreme-react/cjs/data-grid";
import criarNameof from "../../../../../utils/common/cria-name-of";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { InsumoDaOrdemDeProducaoGridModel } from "../../models/insumo-da-ordem-de-producao";

const nameOfGridHandler = criarNameof<InsumoDaOrdemDeProducaoGridModel>();

export const colunasInsumos = [
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Código"
    width={160}
  />,
  <Column
    key={nameOfGridHandler("descricaoComplementar")}
    dataField={nameOfGridHandler("descricaoComplementar")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Descrição complementar"
    allowEditing={false}
    width={400}
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("quantidadeSemPercentualDePerda")}
    dataField={nameOfGridHandler("quantidadeSemPercentualDePerda")}
    {...obterConfiguracaoColuna("quantidadeComNoMaximoCincoCasasDecimais")}
    caption="Qt sem percentual de perda"
    width={230}
  />,
  <Column
    key={nameOfGridHandler("perdaEmPercentual")}
    dataField={nameOfGridHandler("perdaEmPercentual")}
    {...obterConfiguracaoColuna("percentualComMinimoDuasCasasDecimais")}
    width={200}
    allowEditing={false}
    alignment="right"
    caption="Perda em percentual"
  />,
  <Column
    key={nameOfGridHandler("quantidadeTotal")}
    dataField={nameOfGridHandler("quantidadeTotal")}
    {...obterConfiguracaoColuna("quantidadeComNoMaximoCincoCasasDecimais")}
    caption="Qt total"
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("unidade")}
    dataField={nameOfGridHandler("unidade")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Unidade"
    visible={false}
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("quantidadeParaUmaUnidade")}
    dataField={nameOfGridHandler("quantidadeParaUmaUnidade")}
    {...obterConfiguracaoColuna("quantidadeComNoMaximoCincoCasasDecimais")}
    caption="Qt para uma unidade"
    visible={false}
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("estadoDecodificado")}
    dataField={nameOfGridHandler("estadoDecodificado")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Estado"
    allowEditing={false}
    visibleIndex={12}
  />,
  <Column
    key={nameOfGridHandler("loteInterno")}
    dataField={nameOfGridHandler("loteInterno")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Lote interno"
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("loteDoFabricante")}
    dataField={nameOfGridHandler("loteDoFabricante")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Lote do fabricante"
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("enderecoDeEstoque")}
    dataField={nameOfGridHandler("enderecoDeEstoque")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Endereço de estoque"
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("estoqueDisponivel")}
    dataField={nameOfGridHandler("estoqueDisponivel")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Estoque disponível"
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("estoqueDisponivelExplosao")}
    dataField={nameOfGridHandler("estoqueDisponivelExplosao")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Estoque disponível para explosão"
    visible={false}
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("quantidadeReservada")}
    dataField={nameOfGridHandler("quantidadeReservada")}
    {...obterConfiguracaoColuna("decimalComMaximoQuatroCasasDecimais")}
    caption="Reservado"
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("dono")}
    dataField={nameOfGridHandler("dono")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Dono"
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("tipoDoEstoque")}
    dataField={nameOfGridHandler("tipoDoEstoque")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Tipo do estoque"
    visible={false}
    allowEditing={false}
  />,
];
