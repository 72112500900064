import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { DataSourceFiltragem } from "../../../../../utils/grid/data-source-factory";
import { OperacaoDaOrdemDeProducaoGridModel } from "../../models/operacao-de-ordem-de-producao";
import { OperacaoDeOrdemDeProducaoServico } from "../../servicos/operacao-de-ordem-de-producao-service";

interface ComboOperacaoPorOpMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  idOrdemDeProducao: number;
  titulo: string;
  requerido?: boolean;
  somenteLeitura?: boolean;
  onSelectionChanged?: ((e: any) => Promise<void>) | (() => void);
}

const operacaoService = new OperacaoDeOrdemDeProducaoServico();

export default function ComboOperacaoPorOpMxp<T extends FieldValues>(
  props: ComboOperacaoPorOpMxpProps<T>
) {
  const [dataSourceOperacao, configuracoesExibicaoEBuscaOperacao] =
    useMemo(() => {
      const filtros: DataSourceFiltragem<OperacaoDaOrdemDeProducaoGridModel>[] =
        [
          {
            campo: "ordemDeProducaoId",
            operador: "=",
            valor: props.idOrdemDeProducao,
          },
        ];

      return operacaoService.GetDadosSelectBoxOperacaoDaOrdemDeProducao(
        filtros
      );
    }, [props.idOrdemDeProducao]);

  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      requerido={props.requerido}
      somenteLeitura={props.somenteLeitura}
      onSelectionChanged={props.onSelectionChanged}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBuscaOperacao}
      dataSource={dataSourceOperacao}
      labelSemDados="Sem dados"
    />
  );
}
