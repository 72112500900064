import { yupResolver } from "@hookform/resolvers/yup";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/cjs/tab-panel";
import _ from "lodash";
import { createContext, useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormSelectBox,
  FormSelectBoxLazy,
  FormSelectBoxLazyMxp,
  FormTextBox,
} from "../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../components/formularios/selectbox-lazy-mxp";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import TabContainer from "../../../../components/layout/tab-container";
import ComboContaContabilMxp from "../../../../components/mxp/inputs-custom/conta-contabil";
import { QuebrarLinhas } from "../../../../components/texto/quebrar-linhas";
import GridItem from "../../../../features/itens/item/componentes/grid";
import { ItemGridModel } from "../../../../features/itens/item/models/item.api";
import { ItemService } from "../../../../features/itens/item/servicos/item.service";
import { useCarregarRegistro } from "../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import {
  EstadoDoImobilizado,
  ImobilizadoResponse,
  TipoDeDepreciacao,
} from "../../../../models/api/imobilizado/imobilizado";
import { SelectItemEnumEstadoDoImobilizado } from "../../../../models/const/dicionario-combos/imobilizado";
import { PermissoesImobilizado } from "../../../../models/permissoes/contabilidade/imobilizado/permissoes-imobilizado";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import {
  CiapDoImobilizadoViewModel,
  ImobilizadoViewModel,
} from "../../../../models/viewmodels/contabilidade/imobilizado/imobilizado.viewmodel";
import { GridCentroDeCustos } from "../../../../parts/contabilidade/centro-de-custos/grids/grid-padrao";
import AbaCiapImobilizado from "../../../../parts/contabilidade/imobilizado/abas/aba-ciap";
import AbaDepreciacaoImobilizado from "../../../../parts/contabilidade/imobilizado/abas/aba-depreciacao";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import { ImobilizadoService } from "../../../../services/contabilidade/imobilizado/imobilizado.service";
import ImobilizadoAdapter from "../../../../utils/adapters/contabilidade/imobilizado.adapter";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import { checarSeFormFoiTocado } from "../../../../utils/common/form-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { formatarNumeroMonetario } from "../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../utils/formatadores/formatador-de-selectbox";
import { DataSourceFiltragem } from "../../../../utils/grid/data-source-factory";

const imobilizadoService = new ImobilizadoService();
const itemService = new ItemService();

const novoRegistro: ImobilizadoViewModel = {
  id: 0,
  itemId: null,
  estado: EstadoDoImobilizado.Ativo,
  numeroDoPatrimonio: "",
  descricao: "",
  descricaoEstaFixa: false,
  valorDaAquisicao: 0,
  dataDaAquisicao: "",
  contaContabilId: null,
  utilizacaoDoBem: "",
  centroDeCustosId: null,
  contaDeDebitoDaDepreciacaoId: null,
  centroDeCustosDeDebitoDaDepreciacaoId: null,
  contaDeCreditoDaDepreciacaoId: null,
  centroDeCustosDeCreditoDaDepreciacaoId: null,
  percentualDeDepreciacao: null,
  tipoDeDepreciacao: null,
  vidaUtilEmMeses: 0,
  valorParcelaDepreciacao: 0,
  valorResidual: 0,
  valorInicialDepreciado: 0,
  quantidadeInicialDeParcelasDepreciadas: 0,
  valorDepreciadoPorLancamentoContabil: 0,
  quantidadeParcelasDepreciadasPorLancamentoContabil: 0,
  dataUltimaDepreciacao: null,
  sujeitoAoCiap: false,
  ciapDoImobilizado: {
    itemNotaFiscalId: null,
    parcelas: 0,
    parcelasApropriadas: 0,
    tipoDeDocumentoFiscalId: null,
    fornecedorId: null,
    numero: "",
    serie: null,
    chaveAcesso: null,
    dataEmissao: "",
    quantidade: 0,
    unidadeId: null,
    baseCalculoIcms: 0,
    aliquotaIcms: null,
    valorIcms: null,
    valorIcmsSt: null,
    valorIcmsFrete: null,
    valorIcmsDifal: null,
  },
};

const centroDeCustosDataSource = APIBase.getDataSourceSelectBoxLazy(
  {
    camposRetorno: ["Id", "Codigo", "Descricao", "Classificacao"],
    camposOrdenacao: [
      {
        nomeCampo: "Classificacao",
        desc: false,
      },
    ],
  },
  NomesEndpoints.CentroDeCustos
);

const configuracaoExibicaoEBuscaItem =
  assertConfiguracaoExibicaoEBuscaType<ItemGridModel>({
    nomeCampoChave: "id",
    expressaoDeBusca: ["codigo", "descricao"],
    nomeCampoExibicao: (c) => {
      if (c) {
        return FormataDescricao(
          FormatadoresSelectBox.CodigoDescricaoParenteses,
          c.codigo,
          c.descricao
        );
      }

      return "";
    },
  });

const filtroPadraoParaItem: DataSourceFiltragem<ItemGridModel>[] = [
  { campo: "estado", operador: "=", valor: "Ativo" },
];

const itemDataSource =
  itemService.ObterDataSourceParaSelectBoxLazy<ItemGridModel>({
    camposRetorno: ["id", "codigo", "descricao"],
    camposOrdenacao: [{ campo: "codigo", desc: false }],
    camposFiltro: filtroPadraoParaItem,
  });

const exibicaoCentroCustos = (c: any) => {
  if (c) {
    return c.Descricao == null
      ? `${c.Codigo}`
      : `${c.Classificacao} ${c.Descricao} (${c.Codigo})`;
  }

  return "";
};

const centroDeCustosExpressaoDeBusca = ["Codigo", "Descricao", "Classificacao"];

interface ContextoModelosDocumentos55e67CadastroImobilizadoProps {
  modelos: number[];
  setModelos: (valores: number[]) => void;
}

export const ContextoModelosDocumentos55e67CadastroImobilizado =
  createContext<ContextoModelosDocumentos55e67CadastroImobilizadoProps>({
    modelos: [],
    setModelos: () => {
      /*default*/
    },
  });

export default function EditFormImobilizado(props: FormularioEdicaoBaseProps) {
  const [carregando, setCarregando] = useState(false);
  const [modelos, setModelos] = useState<number[]>([]);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
  const [descricaoFoiPersonalizada, setDescricaoFoiPersonalizada] =
    useState(false);

  const schema = yup
    .object()
    .shape<Record<keyof ImobilizadoViewModel, yup.AnySchema>>({
      id: yup.number().required().moreThan(-1).integer(),
      itemId: yup.number().required().moreThan(-1).integer(),
      estado: yup
        .mixed<EstadoDoImobilizado>()
        .required()
        .oneOf(
          Object.values(EstadoDoImobilizado).map((x) => x as number),
          "Valor inválido"
        ),
      numeroDoPatrimonio: yup.string().max(60).required(),
      descricao: yup.string().max(200).required(),
      descricaoEstaFixa: yup.boolean().required(),
      valorDaAquisicao: yup.number().required().moreThan(-1),
      dataDaAquisicao: yup.string().required(),
      contaContabilId: yup.number().required().moreThan(-1).integer(),
      utilizacaoDoBem: yup.string().max(200).required(),
      centroDeCustosId: yup.number().required().positive().integer(),
      contaDeDebitoDaDepreciacaoId: yup
        .number()
        .required()
        .moreThan(-1)
        .integer(),
      centroDeCustosDeDebitoDaDepreciacaoId: yup
        .number()
        .notRequired()
        .integer(),
      contaDeCreditoDaDepreciacaoId: yup
        .number()
        .required()
        .moreThan(-1)
        .integer(),
      centroDeCustosDeCreditoDaDepreciacaoId: yup
        .number()
        .notRequired()
        .integer(),
      percentualDeDepreciacao: yup.number().notRequired().min(0).max(100),
      tipoDeDepreciacao: yup
        .mixed<TipoDeDepreciacao>()
        .notRequired()
        .oneOf(
          Object.values(TipoDeDepreciacao).map((x) => x as number),
          "Valor inválido"
        ),
      vidaUtilEmMeses: yup
        .number()
        .required()
        .moreThan(-1)
        .lessThan(1000)
        .integer(),
      valorParcelaDepreciacao: yup
        .number()
        .required()
        .when(
          ["percentualDeDepreciacao", "tipoDeDepreciacao"],
          (values: any[], schema: yup.NumberSchema) => {
            const [percentualDeDepreciacao, tipoDeDepreciacao] = values as [
              number | null,
              TipoDeDepreciacao | null
            ];

            if (percentualDeDepreciacao && tipoDeDepreciacao) {
              const valorMinimo =
                tipoDeDepreciacao == TipoDeDepreciacao.Mensal ? 0.31 : 3.66;
              return schema.min(
                valorMinimo,
                `Para que seja possível gerar lançamentos contábeis, o valor mínimo é de R$ ${formatarNumeroMonetario(
                  valorMinimo
                )}.`
              );
            }

            return schema;
          }
        ),
      valorResidual: yup
        .number()
        .moreThan(-1)
        .test(
          "valor_residual_valido",
          "Deve ser menor ou igual ao campo 'Custo da aquisição'.",
          function (valorResidual, contexto) {
            if (valorResidual) {
              const valorDaAquisicao = contexto.parent
                .valorDaAquisicao as number;
              return valorResidual <= valorDaAquisicao;
            }
            return true;
          }
        ),
      valorInicialDepreciado: yup.number().required().moreThan(-1),
      quantidadeInicialDeParcelasDepreciadas: yup
        .number()
        .required()
        .moreThan(-1)
        .lessThan(1000)
        .integer(),
      valorDepreciadoPorLancamentoContabil: yup
        .number()
        .required()
        .moreThan(-1),
      quantidadeParcelasDepreciadasPorLancamentoContabil: yup
        .number()
        .required()
        .moreThan(-1)
        .lessThan(1000)
        .integer(),
      dataUltimaDepreciacao: yup.string().notRequired(),
      sujeitoAoCiap: yup.boolean().required(),
      ciapDoImobilizado: yup.object().when("sujeitoAoCiap", (valor, schema) => {
        const sujeitoAoCiap = valor[0] as boolean;
        if (!sujeitoAoCiap) {
          return schema;
        }
        schema = schema.shape<
          Record<keyof CiapDoImobilizadoViewModel, yup.AnySchema>
        >({
          itemNotaFiscalId: yup.number().notRequired().integer(),
          parcelas: yup
            .number()
            .required()
            .moreThan(0)
            .lessThan(1000)
            .integer(),
          parcelasApropriadas: yup
            .number()
            .required()
            .moreThan(-1)
            .lessThan(1000)
            .integer()
            .test(
              "parcela_apropriada_valida",
              "Deve ser menor ou igual ao campo 'Parcelas'.",
              function (parcelaApropriada, contexto) {
                if (parcelaApropriada) {
                  const parcela = contexto.parent.parcelas as number;
                  return parcelaApropriada <= parcela;
                }
                return true;
              }
            ),
          tipoDeDocumentoFiscalId: yup
            .number()
            .required()
            .moreThan(-1)
            .integer(),
          fornecedorId: yup.number().required().moreThan(-1).integer(),
          numero: yup.string().max(9).required(),
          serie: yup.string().max(3).notRequired(),
          chaveAcesso: yup
            .string()
            .max(44)
            .when(["tipoDeDocumentoFiscalId"], (valores, schema) => {
              if (modelos.some((x) => x == valores[0])) {
                return schema.required();
              } else {
                return schema.notRequired();
              }
            }),
          dataEmissao: yup.string().required(),
          quantidade: yup.number().required().moreThan(-1),
          unidadeId: yup.number().required().moreThan(-1).integer(),
          baseCalculoIcms: yup.number().required().moreThan(-1),
          aliquotaIcms: yup.number().notRequired().min(0).max(100),
          valorIcms: yup.number().notRequired().moreThan(-1),
          valorIcmsSt: yup.number().notRequired().moreThan(-1),
          valorIcmsFrete: yup.number().notRequired().moreThan(-1),
          valorIcmsDifal: yup.number().notRequired().moreThan(-1),
        });

        return schema;
      }),
    });

  const hookForm = useForm<ImobilizadoViewModel>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    register,
    control,
    reset,
    getValues,
    handleSubmit,
    setValue,
    formState,
  } = hookForm;

  useCarregarRegistro(props.idRegistroEdicao, CarregarRegistro);

  async function tratarDescricao(e: any) {
    const descricaoAtual = getValues("descricao");
    const descricaoAtualEstaFixa = getValues("descricaoEstaFixa");
    const descricaoNova = e.selectedItem.Descricao;

    if (descricaoAtual) {
      if (descricaoFoiPersonalizada || descricaoAtualEstaFixa) {
        await confirmarAlteracaoDaDescricao(descricaoAtual, descricaoNova);
        return;
      }
    }

    setValue("descricao", descricaoNova);
  }

  async function confirmarAlteracaoDaDescricao(atual: string, nova: string) {
    const mensagem = renderToString(
      <QuebrarLinhas texto={obterMensagemAlteracaoDescricao(atual, nova)} />
    );
    const alterar = await exibirConfirmacao("Confirmar alteração", mensagem);

    if (alterar) {
      setValue("descricao", nova);
      setValue("descricaoEstaFixa", false);
    }
  }

  function obterMensagemAlteracaoDescricao(atual: string, novo: string) {
    return (
      `A descrição no cadastro do item (${novo}) é diferente da descrição nesta tela (${atual}).\n` +
      `Deseja sobrescrever a descrição em tela com a descrição do cadastro do item?`
    );
  }

  // Usado para limpar o formulário se for NaN
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEdicao)) {
      reset(novoRegistro);
    }
  }, [props.idRegistroEdicao]);

  async function CarregarRegistro() {
    try {
      setCarregando(true);
      const resposta = await imobilizadoService.ObterPorId<ImobilizadoResponse>(
        props.idRegistroEdicao
      );
      const viewModel = ImobilizadoAdapter.ToViewModel(resposta.model);
      reset(viewModel);
      setDadosAuditoria(resposta.model);
    } catch (error) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function removerCiapDoImobilizadoSeNecessario(model: ImobilizadoViewModel) {
    if (
      _.isEqual(model.ciapDoImobilizado, novoRegistro.ciapDoImobilizado) ||
      !model.sujeitoAoCiap
    ) {
      model.ciapDoImobilizado = null;
    }
  }

  async function handleSalvar() {
    try {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesImobilizado.InserirEditar,
        ])
      ) {
        return;
      }
      setCarregando(true);
      const model = getValues();

      removerCiapDoImobilizadoSeNecessario(model);

      const request = ImobilizadoAdapter.ToRequest(model);

      const resposta =
        props.idRegistroEdicao > 0
          ? await imobilizadoService.Atualizar(request)
          : await imobilizadoService.Cadastrar(request);

      checarResponse(resposta);

      if (resposta?.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({ concluido: true, precisaAtualizar: true });
      }
    } catch (error: any) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  return (
    <>
      <FormBase2
        visivel={props.visivel}
        carregando={carregando}
        configuracoesModal={props.configuracoesModal}
        modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
        titulo={NomesModais.imobilizado}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        auditoria={dadosAuditoria}
      >
        <ProvedorAjuda id="edit-form-imobilizado">
          <input type="hidden" {...register("id")} defaultValue={0} />
          <Linha>
            <Coluna md={3}>
              <FormTextBox
                name="numeroDoPatrimonio"
                titulo="Número do patrimônio"
                control={control}
                tamanhoMaximo={60}
                requerido
              />
            </Coluna>
            <Coluna md={3}>
              <FormSelectBox
                name="estado"
                titulo="Estado"
                control={control}
                requerido
                dataSource={SelectItemEnumEstadoDoImobilizado}
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={6}>
              <FormSelectBoxLazyMxp
                name="itemId"
                titulo="Item"
                control={control}
                requerido
                configuracoesExibicaoEBusca={configuracaoExibicaoEBuscaItem}
                dataSource={itemDataSource}
                seletorConfig={{
                  modo: "selecaoUnica",
                  titulo: "Selecionar item",
                  componenteGrid: (
                    <GridItem filtrosNoServidor={filtroPadraoParaItem} />
                  ),
                }}
                labelSemDados="Sem dados"
                onSelectionChanged={(e) => {
                  if (!checarSeFormFoiTocado(formState)) {
                    setDescricaoFoiPersonalizada(false);
                    return;
                  }

                  tratarDescricao(e);
                }}
              />
            </Coluna>
            <Coluna md={6}>
              <ComboContaContabilMxp
                name={"contaContabilId"}
                control={control}
                titulo="Conta contábil"
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <FormTextBox
                name="descricao"
                titulo="Descrição"
                control={control}
                tamanhoMaximo={200}
                requerido
                onKeyDown={() => {
                  setValue("descricaoEstaFixa", true);
                  setDescricaoFoiPersonalizada(true);
                }}
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={6}>
              <FormTextBox
                name="utilizacaoDoBem"
                titulo="Utilização do bem"
                control={control}
                tamanhoMaximo={200}
                requerido
              />
            </Coluna>
            <Coluna md={6}>
              <FormSelectBoxLazy
                name="centroDeCustosId"
                titulo="Centro de custos"
                control={control}
                requerido
                nomeCampoChave="Id"
                nomeCampoExibicao={exibicaoCentroCustos}
                expressaoDeBusca={centroDeCustosExpressaoDeBusca}
                dataSource={centroDeCustosDataSource}
                lupaConfig={{
                  modo: "selecaoUnica",
                  titulo: "Selecionar centro de custos",
                  componente: (r) => <GridCentroDeCustos ref={r} />,
                }}
                labelSemDados="Sem dados"
              />
            </Coluna>
          </Linha>
          <TabPanel
            showNavButtons
            swipeEnabled={false}
            itemTitleRender={(item: any) => item.text}
            deferRendering={false}
            height={"max(40vh, 465px)"}
          >
            <Item text="Depreciação">
              <TabContainer>
                <AbaDepreciacaoImobilizado hookForm={hookForm} />
              </TabContainer>
            </Item>
            <Item text="CIAP">
              <TabContainer>
                <ContextoModelosDocumentos55e67CadastroImobilizado.Provider
                  value={{ modelos, setModelos }}
                >
                  <AbaCiapImobilizado
                    idRegistroEdicao={props.idRegistroEdicao}
                    hookForm={hookForm}
                  />
                </ContextoModelosDocumentos55e67CadastroImobilizado.Provider>
              </TabContainer>
            </Item>
          </TabPanel>
        </ProvedorAjuda>
      </FormBase2>
    </>
  );
}
