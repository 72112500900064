import { Control } from "react-hook-form";
import TabContainer from "../../../../../../../components/layout/tab-container";
import { OrdemDeProducaoRequest } from "../../../../models/ordem-de-producao.api";
import GridEmbutidaVinculosDaOrdemDeProducao from "../../../grid-embutida/vinculos-da-ordem-de-producao-grid";

interface OrdemDeProducaoAbaVinculosProps {
  idRegistro: number;
  control: Control<OrdemDeProducaoRequest, any>;
}

export default function OrdemDeProducaoAbaVinculos({
  idRegistro,
}: OrdemDeProducaoAbaVinculosProps) {
  return (
    <TabContainer>
      <GridEmbutidaVinculosDaOrdemDeProducao ordemDeProducaoId={idRegistro} />
    </TabContainer>
  );
}
