import { ReactNode } from "react";
import { PainelCarregando } from "../painel-carregamento";

interface FormMxpProps {
  children: ReactNode;
  carregando: boolean;
}

export default function FormMxp(props: FormMxpProps) {
  return (
    <form
      style={{
        overflow: "auto",
        padding: "0px 15px 10px 15px",
        margin: "-5px -15px",
        flexGrow: 2,
        flexShrink: 2,
      }}
      onSubmit={(e) => e.preventDefault()}
    >
      <PainelCarregando visivel={props.carregando} />
      {props.children}
    </form>
  );
}
