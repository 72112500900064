import { AxiosRequestConfig } from "axios";
import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import getApi from "../../../../configs/api";
import { ArquivoBaseModel } from "../../../../models/api/arquivos/arquivo";
import { CadastrarEmMassaResponse } from "../../../../models/api/comum/cadastrar-varios";
import {
  ResponseBase,
  ResponseBaseArquivo,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import ApiEmpresa from "../../../../services/empresa/empresa.service";
import { tratarErroApi } from "../../../../utils/api/api-utils";
import { ItemService } from "../../../itens/item/servicos/item.service";
import {
  LoteCadastrarVariosExcelRequest,
  LoteGridModel,
  LoteRequestDTO,
  LoteResponseDTO,
} from "../models/lote.api";

const itemService = new ItemService();

export default class LoteServico extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Lote);
  }

  public GetDadosSelectBoxLote(): [
    DataSource<LoteGridModel, any>,
    ConfiguracaoExibicaoEBusca<LoteGridModel>
  ] {
    const dataSource = this.GetDataSourceSelectBoxLazyMxp<LoteGridModel>({
      camposRetorno: ["id", "loteDoFabricanteOuNumeroDeSerie"],
      camposOrdenacao: [
        {
          nomeCampo: "loteDoFabricanteOuNumeroDeSerie",
          desc: false,
        },
      ],
    });

    const configs = assertConfiguracaoExibicaoEBuscaType<LoteGridModel>({
      expressaoDeBusca: ["loteDoFabricanteOuNumeroDeSerie"],
      nomeCampoChave: "id",
      nomeCampoExibicao: (c) => {
        if (c) {
          return c.loteDoFabricanteOuNumeroDeSerie ?? "";
        }

        return "";
      },
    });

    return [dataSource, configs];
  }

  public async Cancelar(id: number) {
    const api = getApi();
    const response = await api.patch<ResponseModel<LoteResponseDTO>>(
      `${this._nomeEndpoint}/CancelaLote?loteId=${id}`
    );

    return response.data;
  }

  public async CadastrarEmMassa(
    model: LoteRequestDTO[]
  ): Promise<ResponseModel<CadastrarEmMassaResponse[]>> {
    const api = getApi();
    const response = await api.post<ResponseModel<CadastrarEmMassaResponse[]>>(
      `${this._nomeEndpoint}/CadastrarEmMassa`,
      model
    );

    return response.data;
  }

  public async CadastrarEmMassaExcel(dados: LoteCadastrarVariosExcelRequest[]) {
    try {
      const [idsEmpresa, idsItem] = await Promise.all([
        ApiEmpresa.obterIdPorApelido(dados.map((m) => m.codigoFabricante)),
        itemService.obterIdPorCodigo(dados.map((m) => m.codigoItem)),
      ]);

      dados.forEach((m) => {
        if (!idsItem[m.codigoItem]) {
          m.erros += `\nNão foi possível encontrar item com o código: "${m.codigoItem}"`;
        }
        if (!idsEmpresa[m.codigoFabricante]) {
          m.erros += `\nNão foi possível encontrar fabricante com o apelido: "${m.codigoFabricante}"`;
        }
      });

      const dadosCadastrar = dados
        .filter((x) => x.erros.length === 0)
        .map((m) => ({
          id: 0,
          itemId: idsItem[m.codigoItem],
          fabricanteId: idsEmpresa[m.codigoFabricante],
          historico: null,
          arquivos: [],
          utilizarPrefixo: false,
          ...m,
        }));

      const resultado = await this.CadastrarEmMassa(dadosCadastrar);

      if (!resultado.sucesso) {
        return resultado;
      }

      resultado.model.forEach(
        (m, i) => (m.indiceDoRegistro = dadosCadastrar.at(i)?.linha ?? 0)
      );
      dados
        .filter((x) => x.erros.length > 0)
        .forEach((x) =>
          resultado.model.push({
            indiceDoRegistro: x.linha,
            id: 0,
            erros: x.erros,
          })
        );

      return resultado;
    } catch (error) {
      tratarErroApi(error);
      return {
        sucesso: false,
        model: [],
        mensagem: error,
        erros: [],
      } as ResponseModel<CadastrarEmMassaResponse[]>;
    }
  }

  public async ObterAnexos(id: number) {
    const api = getApi();
    const response = await api.get<ResponseModel<ArquivoBaseModel[]>>(
      `${this._nomeEndpoint}/ObterAnexos?id=${id}`
    );

    return response.data.model;
  }

  public async AnexoAlterarLista(id: number, anexos: ArquivoBaseModel[]) {
    const api = getApi();
    anexos.forEach((x) => (x.arquivo ??= ""));
    const response = await api.put<ResponseBase>(
      `${this._nomeEndpoint}/AnexoAlterarLista`,
      { id: id, anexos: anexos }
    );

    return response.data;
  }

  public async AnexoObterDadosParaDownload(id: number, idAnexo: number) {
    const api = getApi();

    const resquestConfig: AxiosRequestConfig = {
      responseType: "blob",
    };

    const response = await api.get<ResponseBaseArquivo>(
      `${this._nomeEndpoint}/AnexoObterDadosParaDownload?id=${id}&idAnexo=${idAnexo}`,
      resquestConfig
    );
    return response;
  }
}
