import Decimal from "decimal.js";
import { useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  FormDateBox,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
} from "../../../../../components/formularios";
import FormNumberBoxSimples from "../../../../../components/formularios/numberbox-simples";
import FormSelectBoxSimples from "../../../../../components/formularios/selectbox-simples";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ComboContaContabilMxp from "../../../../../components/mxp/inputs-custom/conta-contabil";
import Sessao from "../../../../../components/organizacao/sessao";
import { ObrigatoriedadeCentroDeCustos } from "../../../../../models/api/conta-contabil/conta-contabil";
import {
  EstadoDaDepreciacao,
  TipoDeDepreciacao,
} from "../../../../../models/api/imobilizado/imobilizado";
import {
  SelectItemEnumEstadoDaDepreciacao,
  SelectItemEnumTipoDeDepreciacao,
} from "../../../../../models/const/dicionario-combos/imobilizado";
import { ImobilizadoViewModel } from "../../../../../models/viewmodels/contabilidade/imobilizado/imobilizado.viewmodel";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import { GridCentroDeCustos } from "../../../centro-de-custos/grids/grid-padrao";

const centroDeCustosDataSource = APIBase.getDataSourceSelectBoxLazy(
  {
    camposRetorno: ["Id", "Codigo", "Descricao", "Classificacao"],
    camposOrdenacao: [
      {
        nomeCampo: "Classificacao",
        desc: false,
      },
    ],
  },
  NomesEndpoints.CentroDeCustos
);

const exibicaoCentroCustos = (c: any) => {
  if (c) {
    return c.Descricao == null
      ? `${c.Codigo}`
      : `${c.Classificacao} ${c.Descricao} (${c.Codigo})`;
  }

  return "";
};

const centroDeCustosExpressaoDeBusca = ["Codigo", "Descricao", "Classificacao"];

interface AbaDepreciacaoImobilizadoProps {
  hookForm: UseFormReturn<ImobilizadoViewModel, any, undefined>;
}

export default function AbaDepreciacaoImobilizado({
  hookForm,
}: AbaDepreciacaoImobilizadoProps) {
  const { control, watch, setValue, trigger } = hookForm;

  const existeLancamentosContabeis =
    watch("valorDepreciadoPorLancamentoContabil") > 0;

  const [
    desabilitarCentroDeCustosDeDebito,
    setDesabilitarCentroDeCustosDeDebito,
  ] = useState(false);

  const [
    desabilitarCentroDeCustosDeCredito,
    setDesabilitarCentroDeCustosDeCredito,
  ] = useState(false);

  const custoAquisicao = watch("valorDaAquisicao");
  const valorResidual = watch("valorResidual");
  const percentual = watch("percentualDeDepreciacao") ?? 0;
  const tipoDepreciacao = watch("tipoDeDepreciacao");

  const valorParcelaDepreciacao = useMemo(() => {
    if (!tipoDepreciacao) {
      return 0;
    }

    let valor = (custoAquisicao - valorResidual) * (percentual / 100);

    if (tipoDepreciacao == TipoDeDepreciacao.Mensal) {
      valor = valor / 12;
    }

    return new Decimal(valor).toNumber();
  }, [custoAquisicao, valorResidual, percentual, tipoDepreciacao]);

  useEffect(() => {
    setValue("valorParcelaDepreciacao", valorParcelaDepreciacao);
    trigger("valorParcelaDepreciacao");
  }, [valorParcelaDepreciacao]);

  function calcularVidaRestante() {
    return (
      watch("vidaUtilEmMeses") -
      watch("quantidadeInicialDeParcelasDepreciadas") -
      watch("quantidadeParcelasDepreciadasPorLancamentoContabil")
    );
  }

  function obterEstadoDaDepreciacao() {
    const vidaRestante = calcularVidaRestante();
    const vidaUtil = watch("vidaUtilEmMeses");

    if (vidaRestante == vidaUtil) {
      return EstadoDaDepreciacao.NaoIniciada;
    } else if (vidaRestante > 0 && vidaRestante < vidaUtil) {
      return EstadoDaDepreciacao.EmAndamento;
    } else if (vidaRestante == 0) {
      return EstadoDaDepreciacao.Concluida;
    }
  }

  function editCentroDeCustosDeDebito(e: any) {
    const desabilitar =
      e.selectedItem.ObrigatoriedadeCentroDeCustos ==
      ObrigatoriedadeCentroDeCustos.Proibida;

    setDesabilitarCentroDeCustosDeDebito(desabilitar);

    if (desabilitar) {
      setValue("centroDeCustosDeDebitoDaDepreciacaoId", null);
    }
  }

  function editCentroDeCustosDeCredito(e: any) {
    const desabilitar =
      e.selectedItem.ObrigatoriedadeCentroDeCustos ==
      ObrigatoriedadeCentroDeCustos.Proibida;

    setDesabilitarCentroDeCustosDeCredito(desabilitar);

    if (desabilitar) {
      setValue("centroDeCustosDeCreditoDaDepreciacaoId", null);
    }
  }

  return (
    <>
      <Linha>
        <Coluna md={3}>
          <FormDateBox
            name="dataDaAquisicao"
            titulo="Data de aquisição"
            control={control}
            requerido
            exibirBotaoLimpar
            tipo="date"
            aceitaValorCustomizado={true}
            formatoExibicao="dd/MM/yy"
            aceitaDigitacaoComMascara={true}
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="valorDaAquisicao"
            titulo="Custo de aquisição"
            control={control}
            requerido
            formato={obterFormatStringNumero(2)}
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="valorResidual"
            titulo="Valor residual"
            control={control}
            formato={obterFormatStringNumero(2)}
          />
        </Coluna>
        <Coluna md={3}>
          <FormSelectBoxSimples
            titulo="Estado"
            requerido
            valor={obterEstadoDaDepreciacao()}
            dataSource={SelectItemEnumEstadoDaDepreciacao}
            somenteLeitura
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={3}>
          <FormSelectBox
            name="tipoDeDepreciacao"
            titulo="Tipo de depreciação"
            control={control}
            dataSource={SelectItemEnumTipoDeDepreciacao}
          />
        </Coluna>
        <Coluna md={2}>
          <FormNumberBox
            name="percentualDeDepreciacao"
            titulo="Percentual (%)"
            control={control}
            formato={obterFormatStringNumero(2)}
            minimo={0}
            maximo={100}
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="vidaUtilEmMeses"
            titulo="Vida útil (em meses)"
            control={control}
            formato={obterFormatStringNumero(0)}
            minimo={0}
            maximo={999}
            requerido
          />
        </Coluna>
        <Coluna md={4}>
          <FormNumberBox
            name="valorParcelaDepreciacao"
            titulo="Valor da parcela de depreciação"
            formato={obterFormatStringNumero(2)}
            control={control}
            requerido
            somenteLeitura
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <ComboContaContabilMxp
            name={"contaDeDebitoDaDepreciacaoId"}
            control={control}
            titulo="Conta contábil de débito"
            onSelectionChanged={editCentroDeCustosDeDebito}
          />
        </Coluna>
        <Coluna md={6}>
          <FormSelectBoxLazy
            name="centroDeCustosDeDebitoDaDepreciacaoId"
            titulo="Centro de custos de débito"
            control={control}
            somenteLeitura={desabilitarCentroDeCustosDeDebito}
            nomeCampoChave="Id"
            nomeCampoExibicao={exibicaoCentroCustos}
            expressaoDeBusca={centroDeCustosExpressaoDeBusca}
            dataSource={centroDeCustosDataSource}
            lupaConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar centro de custos",
              componente: (r) => <GridCentroDeCustos ref={r} />,
            }}
            labelSemDados="Sem dados"
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <ComboContaContabilMxp
            name={"contaDeCreditoDaDepreciacaoId"}
            control={control}
            titulo="Conta contábil de crédito"
            onSelectionChanged={editCentroDeCustosDeCredito}
          />
        </Coluna>
        <Coluna md={6}>
          <FormSelectBoxLazy
            name="centroDeCustosDeCreditoDaDepreciacaoId"
            titulo="Centro de custos de crédito"
            control={control}
            somenteLeitura={desabilitarCentroDeCustosDeCredito}
            nomeCampoChave="Id"
            nomeCampoExibicao={exibicaoCentroCustos}
            expressaoDeBusca={centroDeCustosExpressaoDeBusca}
            dataSource={centroDeCustosDataSource}
            lupaConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar centro de custos",
              componente: (r) => <GridCentroDeCustos ref={r} />,
            }}
            labelSemDados="Sem dados"
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <FormNumberBox
            name="quantidadeInicialDeParcelasDepreciadas"
            titulo="Quantidade inicial de parcelas depreciadas"
            control={control}
            formato={obterFormatStringNumero(0)}
            somenteLeitura={existeLancamentosContabeis}
            requerido
            minimo={0}
          />
        </Coluna>
        <Coluna md={6}>
          <FormNumberBox
            name="valorInicialDepreciado"
            titulo="Valor depreciado inicial"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura={existeLancamentosContabeis}
            requerido
            minimo={0}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <FormNumberBox
            name="quantidadeParcelasDepreciadasPorLancamentoContabil"
            titulo="Qt de parcelas depreciadas por lançamentos contábeis"
            control={control}
            formato={obterFormatStringNumero(0)}
            somenteLeitura
            requerido
            minimo={0}
          />
        </Coluna>
        <Coluna md={6}>
          <FormNumberBox
            name="valorDepreciadoPorLancamentoContabil"
            titulo="Valor depreciado por lançamentos contábeis"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura
            requerido
            minimo={0}
          />
        </Coluna>
      </Linha>
      <Sessao titulo="Totalizadores">
        <Linha>
          <Coluna md={4}>
            <FormNumberBoxSimples
              titulo="Vida restante (em meses)"
              formato={obterFormatStringNumero(0)}
              requerido
              somenteLeitura
              minimo={0}
              valor={calcularVidaRestante()}
            />
          </Coluna>
          <Coluna md={4}>
            <FormNumberBoxSimples
              titulo="Valor depreciado"
              formato={obterFormatStringNumero(2)}
              requerido
              somenteLeitura
              minimo={0}
              valor={
                watch("valorDepreciadoPorLancamentoContabil") +
                watch("valorInicialDepreciado")
              }
            />
          </Coluna>
          <Coluna md={4}>
            <FormDateBox
              name="dataUltimaDepreciacao"
              titulo="Data da última depreciação"
              control={control}
              requerido
              somenteLeitura
              tipo="date"
              formatoExibicao="dd/MM/yy"
            />
          </Coluna>
        </Linha>
      </Sessao>
    </>
  );
}
