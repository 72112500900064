import { HttpStatusCode } from "axios";
import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import getApi from "../../../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import {
  EstoqueMovimentacaoGridModel,
  EstoqueMovimentacaoSelecionarGridModel,
} from "../../../../models/api/estoque-movimentacao/estoque-movimentacao";
import {
  EstoqueGridModelSelecionar,
  EstoqueSelecionadoParaBaixaDeInsumo,
} from "../../../../models/api/estoque/estoque";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import { criarDatasourceGridComParametros } from "../../../../utils/grid/back-end-grid-utils";
import { DataSourceFiltragem } from "../../../../utils/grid/data-source-factory";
import {
  EstornarBaixasDoInsumoResponse,
  InsumoDaOrdemDeProducaoBaixarResponse,
  InsumoDaOrdemDeProducaoGridModel,
} from "../models/insumo-da-ordem-de-producao";

export class InsumoDaOrdemDeProducaoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.InsumoDaOrdemDeProducao);
  }

  public GetGridSourceFiltradoPorOp(parametros: object) {
    return criarDatasourceGridComParametros(
      `${this._nomeEndpoint}/grid-filtrado-por-op`,
      parametros
    );
  }

  public async obterEstoquesDisponiveisParaInsumo(
    insumoId: number,
    quantidadeParaBaixar: number
  ) {
    try {
      const api = getApi();
      const response = await api.post<
        ResponseModel<EstoqueGridModelSelecionar[]>
      >(`${this._nomeEndpoint}/obterEstoquesDisponiveisParaInsumo`, {
        insumoId: insumoId,
        quantidadeParaBaixar: quantidadeParaBaixar,
      });

      checarResponse(response.data);
      return response.data;
    } catch (error) {
      tratarErroApi(error);
      return this.ErrorToResponse(error) as ResponseModel<
        EstoqueGridModelSelecionar[]
      >;
    }
  }

  public async BaixaAutomatica(
    insumoId: number,
    quantidadeParaBaixar: number,
    forcar: boolean
  ) {
    try {
      const api = getApi();
      const response = await api.post<
        ResponseModel<InsumoDaOrdemDeProducaoBaixarResponse>
      >(`${this._nomeEndpoint}/baixarAutomaticamente`, {
        insumoId: insumoId,
        quantidadeParaBaixar: quantidadeParaBaixar,
        forcarConclusao: forcar,
      });

      checarResponse(response.data);
      return response.data;
    } catch (error) {
      tratarErroApi(error);
      const mensagem = (error as any)?.response?.data?.mensagem;
      return {
        sucesso: false,
        mensagem: mensagem,
        model: null as any,
      } as ResponseModel<InsumoDaOrdemDeProducaoBaixarResponse>;
    }
  }

  public async BaixarEstoquesSelecionadosParaInsumo(
    idInsumo: number,
    estoques: EstoqueSelecionadoParaBaixaDeInsumo[],
    forcarConclusao: boolean
  ) {
    try {
      const api = getApi();
      const response = await api.post<
        ResponseModel<InsumoDaOrdemDeProducaoBaixarResponse>
      >(`${this._nomeEndpoint}/baixarEstoquesSelecionadosParaInsumo`, {
        insumoId: idInsumo,
        estoquesSelecionadosParaBaixar: estoques,
        forcarConclusao: forcarConclusao,
      });

      checarResponse(response.data);
      return response.data;
    } catch (error) {
      tratarErroApi(error);
      return this.ErrorToResponse(error);
    }
  }

  public async EstornarBaixasDoInsumo(
    movimentacoes: EstoqueMovimentacaoSelecionarGridModel[]
  ) {
    try {
      const api = getApi();
      const response = await api.post<ResponseBase>(
        `${this._nomeEndpoint}/estornarBaixasDoInsumo`,
        {
          movimentacoes: movimentacoes.map((mov) => {
            return {
              movimentacaoId: mov.id,
              quantidade: mov.quantidadeParaEstornar,
            };
          }),
        }
      );

      checarResponse(response.data);
      return response.data;
    } catch (error) {
      tratarErroApi(error);
      return this.ErrorToResponse(error);
    }
  }

  public async ForcarConclusaoDeInsumo(insumoId: number) {
    try {
      const api = getApi();
      const response = await api.put<ResponseBase>(
        `${this._nomeEndpoint}/forcarConclusaoDeInsumo?insumoId=${insumoId}`
      );

      checarResponse(response.data);
      return response.data;
    } catch (error) {
      tratarErroApi(error);
      return this.ErrorToResponse(error);
    }
  }

  public async VerificarSePermiteBaixarInsumosTerceirosNaoTotalmenteReservados(
    insumoId: number
  ) {
    try {
      const api = getApi();
      const response = await api.get<ResponseBase>(
        `${this._nomeEndpoint}/verificarSePermiteBaixarInsumosTerceirosNaoTotalmenteReservado?insumoId=${insumoId}`
      );

      return response.data.sucesso;
    } catch (error) {
      tratarErroApi(error);
      return undefined;
    }
  }

  public async ObterValorMedioDasBaixasDoInsumo(
    insumoId: number
  ): Promise<number> {
    try {
      const api = getApi();
      const response = await api.get<ResponseModel<number>>(
        `${this._nomeEndpoint}/ObterValorMedioDasBaixasDoInsumo?insumoId=${insumoId}`
      );

      checarResponse(response.data);

      return response.data?.model || 0;
    } catch (error) {
      tratarErroApi(error);
      return 0;
    }
  }

  public async EstornarInsumo(insumoId: number, quantidade: number) {
    const api = getApi();
    try {
      const response = await api.post<ResponseBase>(
        `${this._nomeEndpoint}/estornarInsumo`,
        {
          insumoId: insumoId,
          quantidade: quantidade,
        }
      );

      checarResponse(response.data);
      return {
        sucesso: response.data.sucesso,
        mensagem: response.data.mensagem,
        erros: [],
        precisaSelecionarMovs: false,
        movimentacoes: [],
      } as EstornarBaixasDoInsumoResponse;
    } catch (error) {
      const erro = error as any;
      // Tem mais de um insumo para estornar parcialmente
      if (erro?.response?.status == HttpStatusCode.PreconditionRequired) {
        const response = await api.get<
          ResponseModel<EstoqueMovimentacaoGridModel[]>
        >(
          `${this._nomeEndpoint}/gridMovimentacoesDeInsumo?insumoId=${insumoId}`
        );

        checarResponse(response.data);

        const movimentacoes = response.data.model
          .map((x) => x as EstoqueMovimentacaoSelecionarGridModel)
          .sort((a, b) => {
            return (
              new Date(b.dataDaMovimentacao).getTime() -
              new Date(a.dataDaMovimentacao).getTime()
            );
          });
        let quantidadeParaEstornar = quantidade;
        movimentacoes.forEach((x) => {
          x.quantidadeParaEstornar = Math.min(
            x.quantidade,
            quantidadeParaEstornar
          );
          quantidadeParaEstornar -= x.quantidadeParaEstornar;
        });

        return {
          sucesso: response.data.sucesso,
          mensagem: response.data.mensagem,
          precisaSelecionarMovs: true,
          movimentacoes: movimentacoes,
        } as EstornarBaixasDoInsumoResponse;
      }

      tratarErroApi(error);

      return {
        sucesso: false,
        mensagem:
          (error as any)?.response?.data?.mensagem ?? "Erro ao estornar insumo",
        erros: [],
        precisaSelecionarMovs: false,
        movimentacoes: [],
      } as EstornarBaixasDoInsumoResponse;
    }
  }

  public async EstornarInsumos(insumosIds: number[]) {
    const api = getApi();
    try {
      const response = await api.post<ResponseBase>(
        `${this._nomeEndpoint}/estornarInsumos`,
        {
          insumosIds: insumosIds,
        }
      );

      checarResponse(response.data);
      return response.data;
    } catch (error) {
      tratarErroApi(error);
      return this.ErrorToResponse(error);
    }
  }

  public GetDadosSelectBoxItem(
    filtros:
      | DataSourceFiltragem<InsumoDaOrdemDeProducaoGridModel>[]
      | undefined = undefined
  ): [
    DataSource<InsumoDaOrdemDeProducaoGridModel, number>,
    ConfiguracaoExibicaoEBusca<InsumoDaOrdemDeProducaoGridModel>
  ] {
    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<InsumoDaOrdemDeProducaoGridModel>({
        camposRetorno: ["id", "operacao", "codigo", "descricao"],
        camposOrdenacao: [{ campo: "operacao", desc: false }],
        camposFiltro: filtros,
      });

    const configs =
      assertConfiguracaoExibicaoEBuscaType<InsumoDaOrdemDeProducaoGridModel>({
        nomeCampoChave: "id",
        expressaoDeBusca: ["operacao", "codigo", "descricao"],
        nomeCampoExibicao: (c) => {
          if (c) {
            return c.descricao == null
              ? `${c.operacao}`
              : `[${c.operacao}] | ${c.codigo} (${c.descricao})`;
          }

          return "";
        },
      });

    return [dataSource, configs];
  }

  private ErrorToResponse(error: any) {
    return {
      sucesso: false,
      mensagem: error.response.data.mensagem,
    } as ResponseBase;
  }
}
