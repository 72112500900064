import {
  DataSourceFiltragem,
  DataSourceRawFilter,
} from "../../../../utils/grid/data-source-factory";
import { PedidoDeVendaEstadoDecodificado } from "../../../vendas/pedidos-de-venda/models/pedidos-de-venda.enum";
import { ItemPedidoDeVendaGridModel } from "../models/item-pedido-de-venda.api";

export class FiltrosGridItemPedidoVenda {
  static readonly pedidoNaoFinalizado: DataSourceFiltragem<ItemPedidoDeVendaGridModel>[] =
    [
      {
        campo: "estadoDecodificadoPedidoDeVenda",
        operador: "<>",
        valor: PedidoDeVendaEstadoDecodificado.Faturado,
      },
      {
        campo: "estadoDecodificadoPedidoDeVenda",
        operador: "<>",
        valor: PedidoDeVendaEstadoDecodificado.Cancelado,
      },
    ];

  static readonly pedidoNaoFinalizadoRaw: DataSourceRawFilter<ItemPedidoDeVendaGridModel> =
    [
      [
        "estadoDecodificadoPedidoDeVenda",
        "<>",
        PedidoDeVendaEstadoDecodificado.Faturado,
      ],
      "and",
      [
        "estadoDecodificadoPedidoDeVenda",
        "<>",
        PedidoDeVendaEstadoDecodificado.Cancelado,
      ],
    ];
}
