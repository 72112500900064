import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormGrupo,
  FormNumberBox,
  FormSelectBoxLazyMxp,
  FormTextBoxSimples,
} from "../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import {
  IFormularioBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApiComoModal,
} from "../../../../../utils/api/api-utils";
import { navegarParaMxpAntigo } from "../../../../../utils/common/menu-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import yupPt from "../../../../../utils/validacao/validacao";
import GridItemPedidoDeVenda from "../../../../itens/item-pedido-de-venda/componentes/grid";
import { ItemPedidoDeVendaGridModel } from "../../../../itens/item-pedido-de-venda/models/item-pedido-de-venda.api";
import ItemPedidoDeVendaService from "../../../../itens/item-pedido-de-venda/servicos/item-pedido-de-venda.service";
import { FiltrosGridItemPedidoVenda } from "../../../../itens/item-pedido-de-venda/utils/item-pedido-de-venda.filtros";
import GridPedidoDeVenda from "../../../../vendas/pedidos-de-venda/componentes/grid";
import { PedidoDeVendaGridModel } from "../../../../vendas/pedidos-de-venda/models/pedidos-de-venda.api";
import PedidosDeVendaService from "../../../../vendas/pedidos-de-venda/servicos/pedidos-de-venda.servico";
import { FiltrosGridPedidoVenda } from "../../../../vendas/pedidos-de-venda/utils/pedidos-de-venda.filtros";
import { CadastrarReservaParaOrdemDeProducaoRequest } from "../../models/reserva.api";
import ReservaService from "../../service/reserva.service";
import { valorMaximoQuantidadeReserva } from "../../utils/reserva.constantes";

const serviceReserva = new ReservaService();
const servicePedidoDeVenda = new PedidosDeVendaService();
const serviceItemPedidoDeVenda = new ItemPedidoDeVendaService();

const [dataSourcePedidoDeVenda, configuracaoExibicaoPedidoDeVenda] =
  servicePedidoDeVenda.GetDadosSelectBoxPedidoDeVenda();

const configuracaoExibicaoItemPedidoDeVenda =
  assertConfiguracaoExibicaoEBuscaType<ItemPedidoDeVendaGridModel>({
    nomeCampoChave: "id",
    expressaoDeBusca: [
      "numeroPedidoDeVenda",
      "numeroItemPedidoDeVenda",
      "codigoItem",
      "descricaoItem",
      "apelidoCliente",
    ],
    nomeCampoExibicao: (pvit) =>
      pvit
        ? `${pvit.numeroPedidoDeVenda} #${pvit.numeroItemPedidoDeVenda} - ${pvit.codigoItem} (${pvit.descricaoItem}) - ${pvit.apelidoCliente}`
        : "",
  });

interface FormIncluirReservaProps extends IFormularioBase {
  idOrdemDeProducao: number;
  codigoItem: string;
  descricaoItem: string;
  codigoOrdemDeProducao?: number;
  ordemDeProducaoEhSugerida: boolean;
}

const novoRegistro: CadastrarReservaParaOrdemDeProducaoRequest = {
  idPedidoDeVendaDestino: NaN,
  idItemPedidoDeVendaDestino: NaN,
  idOrdemDeProducaoOrigem: NaN,
  quantidade: NaN,
};

const schema = yupPt.object({
  idPedidoDeVendaDestino: yupPt
    .number()
    .label("Pedido de venda")
    .required()
    .moreThan(0),
  idItemPedidoDeVendaDestino: yupPt
    .number()
    .label("Item do pedido de venda")
    .required()
    .moreThan(0),
  quantidade: yupPt
    .number()
    .label("Quantidade")
    .required()
    .moreThan(0)
    .lessThan(99_999_999_999.9999),
});

export default function FormIncluirReservaOrdemProducao(
  props: FormIncluirReservaProps
) {
  const [isCarregando, setCarregando] = useState(false);
  const [pedidoDeVenda, setPedidoDeVenda] =
    useState<PedidoDeVendaGridModel | null>(null);
  const [itemPedidoDeVenda, setItemPedidoDeVenda] =
    useState<ItemPedidoDeVendaGridModel | null>(null);

  const hookForms = useForm<CadastrarReservaParaOrdemDeProducaoRequest>({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUseNativeValidation: true,
    resolver: yupResolver(schema),
  });

  const {
    control,
    reset,
    handleSubmit,
    resetField,
    setValue,
    trigger,
    getValues,
  } = hookForms;

  // Limpar a tela quando muda a OP.
  useEffect(() => {
    limparTela();
  }, [props.idOrdemDeProducao]);

  const dataSourcePvit = useMemo(
    () =>
      serviceItemPedidoDeVenda.ObterDataSourceParaSelectBoxLazy<ItemPedidoDeVendaGridModel>(
        {
          camposRetorno: [
            "id",
            "pedidoVendaId",
            "numeroPedidoDeVenda",
            "numeroItemPedidoDeVenda",
            "codigoItem",
            "descricaoItem",
            "apelidoCliente",
          ],
          camposOrdenacao: [
            { campo: "numeroPedidoDeVenda", desc: false },
            { campo: "numeroItemPedidoDeVenda", desc: false },
          ],
          filtroExato: pedidoDeVenda
            ? [
                FiltrosGridItemPedidoVenda.pedidoNaoFinalizadoRaw,
                ["numeroPedidoDeVenda", "=", pedidoDeVenda.numero],
              ]
            : FiltrosGridItemPedidoVenda.pedidoNaoFinalizadoRaw,
        }
      ),
    [pedidoDeVenda]
  );

  const onPedidoDeVendaChanged = useCallback(
    (e: any) => {
      const pv = e.selectedItem as PedidoDeVendaGridModel;
      setPedidoDeVenda(pv);

      // Limpar o campo de item se ele não for do pedido selecionado.
      if (pv && itemPedidoDeVenda && itemPedidoDeVenda.pedidoVendaId != pv.id) {
        setItemPedidoDeVenda(null);
        resetField("idItemPedidoDeVendaDestino");
        trigger("idItemPedidoDeVendaDestino");
      }
    },
    [itemPedidoDeVenda]
  );

  const onItemPedidoDeVendaChanged = useCallback(
    (e: any) => {
      const pvit = e.selectedItem as ItemPedidoDeVendaGridModel;
      setItemPedidoDeVenda(pvit);

      // Limpar o campo de pedido de venda se ele não for do item selecionado.
      if (pvit && (!pedidoDeVenda || pedidoDeVenda.id != pvit.pedidoVendaId)) {
        setPedidoDeVenda(null);
        setValue("idPedidoDeVendaDestino", pvit.pedidoVendaId);
        trigger("idPedidoDeVendaDestino");
      }
    },
    [pedidoDeVenda]
  );

  function limparTela() {
    reset(novoRegistro);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  async function handleSalvar() {
    if (
      props.ordemDeProducaoEhSugerida &&
      !(await exibirConfirmacao(
        "Atenção",
        "Esta reserva poderá ser apagada pelo MRP, pois a ordem de produção está sugerida. Deseja continuar?"
      ))
    ) {
      return;
    }

    setCarregando(true);
    const model: CadastrarReservaParaOrdemDeProducaoRequest = {
      ...getValues(),
      idOrdemDeProducaoOrigem: props.idOrdemDeProducao,
    };
    try {
      const response = await serviceReserva.CadastrarReservaOrdemProducaoAsync(
        model
      );

      if (!response.sucesso && response.ordemDeProducaoPossuiTudoReservado) {
        const resposta = await exibirConfirmacao(
          "Atenção",
          "A ordem de produção selecionada possui toda a sua quantidade reservada. Deseja ver suas reservas?"
        );
        if (resposta) {
          navegarParaMxpAntigo(
            `/Reserva/Index?idOf=${props.idOrdemDeProducao}`
          );
        }
        return;
      }

      const exibirErroComoModal = true;
      checarResponse(response, exibirErroComoModal);

      if (response.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Reserva criada com sucesso.",
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApiComoModal(erro);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={isCarregando}>
          <FormGrupo titulo="Origem da reserva">
            <Linha>
              <Coluna md={8}>
                <FormTextBoxSimples
                  titulo="Item"
                  valor={`${props.codigoItem} - ${props.descricaoItem}`}
                  somenteLeitura={true}
                />
              </Coluna>
              <Coluna md={4}>
                <FormTextBoxSimples
                  titulo="Ordem de produção"
                  valor={
                    props.codigoOrdemDeProducao
                      ? `${props.codigoOrdemDeProducao}`
                      : ""
                  }
                  somenteLeitura={true}
                />
              </Coluna>
            </Linha>
          </FormGrupo>

          <FormGrupo titulo="Destino da reserva">
            <Linha>
              <Coluna md={4}>
                <FormSelectBoxLazyMxp
                  name="idPedidoDeVendaDestino"
                  titulo="Pedido de venda"
                  control={control}
                  requerido
                  configuracoesExibicaoEBusca={
                    configuracaoExibicaoPedidoDeVenda
                  }
                  dataSource={dataSourcePedidoDeVenda}
                  seletorConfig={{
                    modo: "selecaoUnica",
                    titulo: "Selecionar item",
                    componenteGrid: (
                      <GridPedidoDeVenda
                        filtrosNoServidor={
                          FiltrosGridPedidoVenda.pedidoNaoFinalizado
                        }
                      />
                    ),
                  }}
                  labelSemDados="Sem dados"
                  onSelectionChanged={onPedidoDeVendaChanged}
                />
              </Coluna>
              <Coluna md={8}>
                <FormSelectBoxLazyMxp
                  name="idItemPedidoDeVendaDestino"
                  titulo="Item do pedido de venda"
                  control={control}
                  requerido
                  configuracoesExibicaoEBusca={
                    configuracaoExibicaoItemPedidoDeVenda
                  }
                  dataSource={dataSourcePvit}
                  seletorConfig={{
                    modo: "selecaoUnica",
                    titulo: "Selecionar item",
                    componenteGrid: (
                      <GridItemPedidoDeVenda
                        filtrosNoServidor={
                          FiltrosGridItemPedidoVenda.pedidoNaoFinalizado
                        }
                      />
                    ),
                  }}
                  labelSemDados="Sem dados"
                  onSelectionChanged={onItemPedidoDeVendaChanged}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6}>
                <FormNumberBox
                  name="quantidade"
                  titulo="Quantidade"
                  toolTip="Quantidade do item a ser reservada"
                  control={control}
                  minimo={0}
                  maximo={valorMaximoQuantidadeReserva}
                  requerido
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </FormMxp>
        <ToolbarMxp>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
