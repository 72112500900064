import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import * as yup from "yup";
import { DataSourceOpcoesBuilder } from "../../../../../../../src/utils/grid/data-source-factory";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../components/botoes/botao-salvar-mxp";
import {
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazyMxp,
} from "../../../../../../components/formularios";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ItemSelectionChangedType,
} from "../../../../../../components/formularios/selectbox-lazy-mxp";
import FormMxp from "../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { formatarNumeroQuantidade } from "../../../../../../utils/formatadores/formatador-de-numeros";
import SelecionarEstoqueDestino from "../../../../../estoque/estoque-movimentacao/componentes/partes/selecionar-estoque-destino/selecionar-estoque-destino";
import { EstoqueMovimentacaoTipo } from "../../../../../estoque/estoque-movimentacao/models/estoque-movimentacao.enums";
import RastreabilidadePorLote, {
  novaRastreabilidadeLote,
  yupRastreabilidadeLote,
} from "../../../../../estoque/lote/componentes/formulario-cadastro-de-rastreabilidade";
import ComboItemMxp from "../../../../../itens/item/componentes/select-box-lazy";
import { ItemGridModel } from "../../../../../itens/item/models/item.api";
import {
  Estado,
  EstocagemTipo,
} from "../../../../../itens/item/models/item.enums";
import ComboInsumoPorOpMxp from "../../../../insumo-da-ordem-de-producao/componentes/select-box-insumo-por-op";
import { InsumoDaOrdemDeProducaoGridModel } from "../../../../insumo-da-ordem-de-producao/models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoService } from "../../../../insumo-da-ordem-de-producao/servicos/insumo-da-ordem-de-producao";
import ComboOperacaoPorOpMxp from "../../../../operacao-da-ordem-de-producao/componentes/select-box-operacao-por-op";
import {
  OrdemDeProducaoGridModel,
  RetiradaDeMaterialRequest,
} from "../../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../../servicos/ordem-de-producao.service";
import {
  RetiradaDeMaterialValorTipo,
  RetiradaDeMaterialValorTipoHelper,
} from "../../../utils/enums/retirada-de-material.enums";

const service = new OrdemDeProducaoService();
const insumoService = new InsumoDaOrdemDeProducaoService();

const novoRegistro: RetiradaDeMaterialRequest = {
  id: 0,
  idOrdemDeProducao: null,
  operacaoDeOrdemDeProducaoId: null,
  quantidade: 0,
  valorUnitario: 0,
  valorUnitarioTipoOrigem: RetiradaDeMaterialValorTipo.CadastroDoItem,
  destino: EstoqueMovimentacaoTipo.Estoque,
  idInsumoOrigemValor: null,
  idContaContabilDestino: null,
  idEnderecoDeEstoque: null,
  itemId: null,
  rastreabilidadeLote: novaRastreabilidadeLote,
};

interface FormRetirarMaterialProps extends IFormularioEditavelBase {
  ordemDeProducaoId: number;
}

const ordemDeProducaoService = new OrdemDeProducaoService();

const [dataSourceOrdemDeProducao] =
  ordemDeProducaoService.GetDadosSelectBoxOrdemDeProducao();

const configuracoesExibicaoEBuscaOrdemDeProducao =
  assertConfiguracaoExibicaoEBuscaType<OrdemDeProducaoGridModel>({
    nomeCampoChave: "id",
    expressaoDeBusca: ["numero", "itemCodigo", "itemDescricao"],
    nomeCampoExibicao: (c) => {
      if (c) {
        return `${c.numero} - ${c.itemCodigo} (${c.itemDescricao})`;
      }

      return "";
    },
  });

interface ComponenteValorUnitarioProps {
  ordemDeProducaoId: number;
  valorDoItem: number;
  hookForms: UseFormReturn<RetiradaDeMaterialRequest>;
}

const ComponenteValorUnitario = (props: ComponenteValorUnitarioProps) => {
  const { control, setValue, watch } = props.hookForms;
  const [mediaDasBaixas, setMediaDasBaixas] = useState<number>(0);

  useEffect(() => {
    if (
      watch("valorUnitarioTipoOrigem") ==
      RetiradaDeMaterialValorTipo.CadastroDoItem
    ) {
      setValue("valorUnitario", props.valorDoItem);
    }
    if (
      watch("valorUnitarioTipoOrigem") ==
      RetiradaDeMaterialValorTipo.MediaDasBaixas
    ) {
      setValue("valorUnitario", mediaDasBaixas);
    }
  }, [props.valorDoItem, mediaDasBaixas, watch("valorUnitarioTipoOrigem")]);

  const onInsumoChanged = useCallback(
    async (e: ItemSelectionChangedType<InsumoDaOrdemDeProducaoGridModel>) => {
      if (!e?.selectedItem) {
        setMediaDasBaixas(0);
        return;
      }

      const valor = await insumoService.ObterValorMedioDasBaixasDoInsumo(
        e.selectedItem.id
      );

      setMediaDasBaixas(valor);
    },
    [setMediaDasBaixas]
  );

  return (
    <Linha>
      <Coluna md={3}>
        <FormSelectBox
          name="valorUnitarioTipoOrigem"
          titulo="Valor unitário"
          control={control}
          dataSource={RetiradaDeMaterialValorTipoHelper.AsSelectItems()}
          requerido
        />
      </Coluna>

      {watch("valorUnitarioTipoOrigem") ==
        RetiradaDeMaterialValorTipo.MediaDasBaixas && (
        <Coluna md={6}>
          <ComboInsumoPorOpMxp
            name="idInsumoOrigemValor"
            titulo="Insumo"
            idOrdemDeProducao={props.ordemDeProducaoId}
            onSelectionChanged={onInsumoChanged}
            control={control}
          />
        </Coluna>
      )}
      <Coluna md={3}>
        <FormNumberBox
          name="valorUnitario"
          titulo="ㅤ"
          control={control}
          formato={formatarNumeroQuantidade}
          minimo={0}
          somenteLeitura={
            watch("valorUnitarioTipoOrigem") !=
            RetiradaDeMaterialValorTipo.Manual
          }
          quantidadeIncrementarDecrementar={1}
        />
      </Coluna>
    </Linha>
  );
};

const dataSourceOpcoesItem: DataSourceOpcoesBuilder<ItemGridModel> = {
  camposRetorno: [
    "id",
    "codigo",
    "descricao",
    "custo",
    "estocagemPor",
    "utilizaPrefixoESufixo",
  ],
  camposFiltro: [
    {
      campo: "estado",
      operador: "<>",
      valor: Estado.Inativo,
    },
  ],
};

export default function FormRetirarMaterial(props: FormRetirarMaterialProps) {
  const [carregando, setCarregando] = useState(false);
  const [itemSelecionado, setItemSelecionado] = useState<
    ItemGridModel | undefined
  >();

  const schema = yup.object<RetiradaDeMaterialRequest>().shape({
    quantidade: yup.number().required().positive(),
    operacaoDeOrdemDeProducaoId: yup.number().required().positive().integer(),
    destino: yup
      .number()
      .oneOf([
        EstoqueMovimentacaoTipo.Estoque,
        EstoqueMovimentacaoTipo.ContaContabil,
      ]),
    itemId: yup.number().required().positive().integer(),
    idContaContabilDestino: yup.number().required().positive().integer(),
    rastreabilidadeLote: yupRastreabilidadeLote(
      () => itemSelecionado?.estocagemPor
    ),
  });

  const hookForm = useForm<RetiradaDeMaterialRequest>({
    resolver: yupResolver(schema),
  });

  const { control, getValues, register, handleSubmit, setValue, reset } =
    hookForm;

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();

    return () => limparTela();
  }, [props.idRegistroEmEdicao]);

  useEffect(() => {
    setValue("idOrdemDeProducao", props.ordemDeProducaoId);
    novoRegistro.idOrdemDeProducao = props.ordemDeProducaoId;
  }, [props.ordemDeProducaoId]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    } else {
      limparTela();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);

      limparTela();
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }
  function limparTela() {
    setItemSelecionado(undefined);
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta = await service.RetirarMaterial(model);
      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });

        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  async function onItemChanged(e: ItemSelectionChangedType<ItemGridModel>) {
    setItemSelecionado(e.selectedItem);
  }

  return (
    <ContainerFormMxp>
      <FormMxp carregando={carregando}>
        <ProvedorAjuda id="edit-form-retirada-de-materiais-ordem-de-producao">
          <input type="hidden" {...register("id")} />
          <FormGrupo>
            <Linha>
              <Coluna md={8}>
                <FormSelectBoxLazyMxp
                  name="idOrdemDeProducao"
                  titulo="Ordem de producão"
                  control={control}
                  somenteLeitura
                  dataSource={dataSourceOrdemDeProducao}
                  configuracoesExibicaoEBusca={
                    configuracoesExibicaoEBuscaOrdemDeProducao
                  }
                  labelSemDados="Sem dados"
                />
              </Coluna>

              <Coluna md={4}>
                <ComboOperacaoPorOpMxp
                  name="operacaoDeOrdemDeProducaoId"
                  titulo="Operação"
                  requerido
                  idOrdemDeProducao={props.ordemDeProducaoId}
                  control={control}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={8}>
                <ComboItemMxp
                  name={"itemId"}
                  titulo="Item"
                  control={control}
                  requerido
                  dataSourceOpcoes={dataSourceOpcoesItem}
                  onSelectionChanged={onItemChanged}
                />
              </Coluna>
              <Coluna md={4}>
                <FormNumberBox
                  name="quantidade"
                  titulo="Quantidade"
                  control={control}
                  formato={formatarNumeroQuantidade}
                  minimo={0}
                  quantidadeIncrementarDecrementar={1}
                  requerido
                />
              </Coluna>
            </Linha>
            <ComponenteValorUnitario
              hookForms={hookForm}
              ordemDeProducaoId={props.ordemDeProducaoId}
              valorDoItem={itemSelecionado?.custo || 0}
            />
            <SelecionarEstoqueDestino hookForms={hookForm as any} />
            {itemSelecionado?.estocagemPor == EstocagemTipo.LoteFabricante &&
              props.idRegistroEmEdicao == 0 && (
                <RastreabilidadePorLote
                  hookForms={hookForm as any}
                  itemUtilizaPrefixo={itemSelecionado.utilizaPrefixoESufixo}
                />
              )}
          </FormGrupo>
        </ProvedorAjuda>
      </FormMxp>
      <ToolbarMxp>
        <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
        <BotaoCancelarMxp handleClick={handleCancelar} />
      </ToolbarMxp>
    </ContainerFormMxp>
  );
}
