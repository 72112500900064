import { GridComponentSimples } from "../../../../../components/componentes-simples/grid-simples";
import {
  ConfiguracoesErros,
  TipoErro,
} from "../../../../../models/api/comum/erros";
import { QuantidadeDeInsumosBaixadosInsuficienteParaConcluir } from "./ordem-de-producao.erros";

export const configuracoesErrosOrdemDeProducao =
  Object.freeze<ConfiguracoesErros>({
    "OP-0001": {
      tipo: TipoErro.Confirmacao,
      titulo: "Não foi possível concluir a ordem de produção",
      formatador(erro) {
        const dadosExtras = (
          erro as QuantidadeDeInsumosBaixadosInsuficienteParaConcluir
        ).dadosExtras;

        return (
          <div>
            <p>
              Existem insumos baixados com quantidade proporcionalmente
              insuficiente para a quantidade que será concluída.
            </p>
            {dadosExtras && dadosExtras.length > 0 && (
              <GridComponentSimples
                dados={dadosExtras}
                nomesColunas={[
                  "Código",
                  "Descrição",
                  "Quantidade baixada",
                  "Quantidade necessária",
                ]}
                colunasParaIgnorar={["itemId"]}
              />
            )}

            <p>Tem certeza de que deseja prosseguir?</p>
          </div>
        );
      },
    },
  });
