import TabContainer from "../../../../../../../components/layout/tab-container";
import GridEmbutidaReservasDaOrdemDeProducao from "../../../grid-embutida/reservas-da-ordem-de-producao";

interface OrdemDeProducaoAbaReservasProps {
  ordemDeProducaoId: number;
  ordemDeProducaoCodigo: number | undefined;
  ordemDeProducaoIdItem: number;
  ordemDeProducaoDescricaoItem: string;
  ordemDeProducaoEhSugerida: boolean;
}

export default function OrdemDeProducaoAbaReservas(
  props: OrdemDeProducaoAbaReservasProps
) {
  return (
    <TabContainer>
      <GridEmbutidaReservasDaOrdemDeProducao {...props} />
    </TabContainer>
  );
}
