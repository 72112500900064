import { yupResolver } from "@hookform/resolvers/yup";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/cjs/tab-panel";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormCheckBox,
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazyMxp,
  FormTextBox,
} from "../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { CentroDeCustosGridModel } from "../../../../../models/api/centro-de-custos/centro-de-custos";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { PermissoesVinculoEnderecoEstoqueCentroDeTrabalho } from "../../../../../models/permissoes/producao/vinculo-endereco-estoque-centro-de-trabalho/permissoes-vinculo-endereco-estoque-centro-de-trabalho";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../models/shared/ui/select-item";
import { GridCentroDeCustos } from "../../../../../parts/contabilidade/centro-de-custos/grids/grid-padrao";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { previneDigitacaoDeCaracteres } from "../../../../../utils/common/common-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import {
  letrasApenasMaiusculasDigitosSimbolosSemEspacos,
  letrasDigitosSimbolosSemEspacos,
} from "../../../../../utils/common/regex-padrao";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import DataSourceFactory from "../../../../../utils/grid/data-source-factory";
import {
  CentroDeTrabalhoRequestDTO,
  CentroDeTrabalhoResponseDTO,
} from "../../models/centro-de-trabalho.api";
import CentroDeTrabalhoContantes from "../../models/centro-de-trabalho.constantes";
import {
  TipoCentroDeTrabalho,
  TipoCentroDeTrabalhoHelper,
  TipoSequenciamento,
} from "../../models/centro-de-trabalho.enums";
import CentroDeTrabalhoServico from "../../servicos/centro-de-trabalho.servico";
import CentroDeTrabalhoAbaCapacidadeFinita from "../abas-formulario/capacidade-finita";
import CentroDeTrabalhoAbaVinculoEnderecoEstoque from "../abas-formulario/endereco-estoque";
import CentroDeTrabalhoAbaRoteiroItem from "../abas-formulario/roteiro-item";

let dadosAuditoria: AuditavelDTO | undefined = undefined;

const novoRegistro: CentroDeTrabalhoRequestDTO = {
  id: 0,
  codigo: "",
  descricao: null,
  ativo: true,
  centroDeCustosId: null,
  tipo: null,
  custoPorHora: null,
  horasPorDia: null,
  unidadeMedidaId: null,
  cargaFabricaConsiderar: false,
  operacaoDescricaoPreferencial: null,
  operacaoDescricaoComplementarPreferencial: null,
  centroDeTrabalhoGrupoId: null,
  sequenciamentoEstaFixo: TipoSequenciamento.Livre,
  baixasLimitarPorEnderecoDeEstoque: false,
};

const service = new CentroDeTrabalhoServico();

const previneDigitacaoDeCaracteresHandler = (e: any) =>
  previneDigitacaoDeCaracteres(e, letrasDigitosSimbolosSemEspacos);

const centrosDeCustoDataSource =
  DataSourceFactory.CriarParaSelectBoxLazy<CentroDeCustosGridModel>(
    `${NomesEndpoints.CentroDeCustos}/grid`,
    {
      camposRetorno: ["id", "codigo", "descricao", "classificacao"],
      camposOrdenacao: [
        {
          campo: "classificacao",
          desc: false,
        },
      ],
    }
  );

const configuracoesExibicaoSelectCentroCusto =
  assertConfiguracaoExibicaoEBuscaType<CentroDeCustosGridModel>({
    expressaoDeBusca: ["codigo", "descricao", "classificacao"],
    nomeCampoChave: "id",
    nomeCampoExibicao: (c) => {
      if (c) {
        return c.descricao == null
          ? `${c.codigo}`
          : `${c.classificacao} ${c.descricao} (${c.codigo})`;
      }

      return "";
    },
  });

export default function FormCentroDeTrabalho(props: IFormularioEditavelBase) {
  const [carregando, setCarregando] = useState(false);
  const [unidadesMedida, setUnidadesMedida] = useState<SelectItem[]>([]);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup
      .string()
      .required()
      .max(CentroDeTrabalhoContantes.CodigoTamanhoMaximo)
      .matches(
        letrasApenasMaiusculasDigitosSimbolosSemEspacos,
        "Deve conter apenas letras maiúsculas, letras sem acento, números e símbolos"
      ),
    descricao: yup
      .string()
      .nullable()
      .max(CentroDeTrabalhoContantes.DescricaoTamanhoMaximo),
    ativo: yup.boolean().nonNullable(),
    centroDeCustosId: yup.number().required().positive().integer(),
    tipo: yup
      .mixed<TipoCentroDeTrabalho>()
      .nullable()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(TipoCentroDeTrabalho).map((x) => x as number),
        "Valor inválido"
      ),
    custoPorHora: yup.number().nullable(),
    horasPorDia: yup.number().nullable(),
    unidadeMedidaId: yup.number().notRequired().nullable().positive().integer(),
    cargaFabricaConsiderar: yup.boolean().notRequired(),
    operacaoDescricaoPreferencial: yup
      .string()
      .nullable()
      .max(
        CentroDeTrabalhoContantes.OperacaoDescricaoPreferencialTamanhoMaximo
      ),
    operacaoDescricaoComplementarPreferencial: yup
      .string()
      .nullable()
      .max(
        CentroDeTrabalhoContantes.OperacaoDescricaoComplementarPreferencialTamanhoMaximo
      ),
    centroDeTrabalhoGrupoId: yup
      .number()
      .notRequired()
      .nullable()
      .positive()
      .integer(),
    sequenciamentoEstaFixo: yup
      .mixed<TipoSequenciamento>()
      .nullable()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(TipoSequenciamento).map((x) => x as number),
        "Valor inválido"
      ),
    baixasLimitarPorEnderecoDeEstoque: yup.boolean().nonNullable(),
  });

  const hookForm = useForm<CentroDeTrabalhoRequestDTO>({
    resolver: yupResolver(schema),
  });

  const { register, control, handleSubmit, getValues, reset } = hookForm;

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    await carregarUnidadesDeMedida();

    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarUnidadesDeMedida() {
    try {
      const resposta = await service.obterUnidadesMedida();
      checarResponse(resposta);
      setUnidadesMedida(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta =
        await service.ObterPorIdComDadosAuditoria<CentroDeTrabalhoResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await service.Atualizar(model)
          : await service.Cadastrar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });

        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
      fechar(ResultadoAcaoFormulario.AcaoCancelada);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-centro-de-trabalho">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <FormGrupo>
              <Linha>
                <Coluna md={3}>
                  <FormTextBox
                    name="codigo"
                    titulo="Código"
                    control={control}
                    requerido
                    tamanhoMaximo={
                      CentroDeTrabalhoContantes.CodigoTamanhoMaximo
                    }
                    transform="uppercase"
                    onKeyDown={previneDigitacaoDeCaracteresHandler}
                  />
                </Coluna>
                <Coluna md={9}>
                  <Linha>
                    <Coluna md={10}>
                      <FormTextBox
                        name="descricao"
                        titulo="Descrição"
                        control={control}
                        tamanhoMaximo={
                          CentroDeTrabalhoContantes.DescricaoTamanhoMaximo
                        }
                      />
                    </Coluna>
                    <Coluna md={2} centralizar>
                      <FormCheckBox
                        name="ativo"
                        titulo="Ativo"
                        defaultValue={true}
                        control={control}
                      />
                    </Coluna>
                  </Linha>
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  <FormSelectBoxLazyMxp
                    name="centroDeCustosId"
                    titulo="Centro de custos"
                    control={control}
                    requerido
                    dataSource={centrosDeCustoDataSource}
                    configuracoesExibicaoEBusca={
                      configuracoesExibicaoSelectCentroCusto
                    }
                    labelSemDados="Sem dados"
                    seletorConfig={{
                      modo: "selecaoUnica",
                      titulo: "Selecionar centro de custos",
                      componenteGrid: <GridCentroDeCustos />,
                    }}
                  />
                </Coluna>
                <Coluna md={6}>
                  <FormSelectBox
                    name="tipo"
                    titulo="Tipo"
                    control={control}
                    dataSource={
                      TipoCentroDeTrabalhoHelper.tipoCentroDeTrabalhoAsSelectItem
                    }
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={2}>
                  <FormNumberBox
                    name="custoPorHora"
                    titulo="Custo/h (R$)"
                    control={control}
                    minimo={0}
                    maximo={9999999999}
                    formato={obterFormatStringNumero(2)}
                    quantidadeIncrementarDecrementar={1}
                  />
                </Coluna>
                <Coluna md={2}>
                  <FormNumberBox
                    name="horasPorDia"
                    titulo="h/dia"
                    control={control}
                    minimo={0}
                    maximo={9999999999}
                    formato={obterFormatStringNumero(2)}
                    quantidadeIncrementarDecrementar={1}
                  />
                </Coluna>
                <Coluna md={2}>
                  <FormSelectBox
                    name="unidadeMedidaId"
                    titulo="Unidade"
                    control={control}
                    dataSource={unidadesMedida}
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
                <Coluna md={6} centralizar>
                  <FormCheckBox
                    name="cargaFabricaConsiderar"
                    titulo="Considera na carga fábrica"
                    defaultValue={false}
                    control={control}
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
            <TabPanel
              deferRendering={false}
              showNavButtons
              swipeEnabled={false}
              itemTitleRender={(item) => item.text}
              height={"max(40vh, 320px)"}
            >
              <Item text="Inicialização do roteiro do item">
                <CentroDeTrabalhoAbaRoteiroItem control={control} />
              </Item>
              <Item text="Capacidade finita">
                <CentroDeTrabalhoAbaCapacidadeFinita control={control} />
              </Item>
              <Item text="Vínculos com endereços de estoque">
                <RequerPermissao
                  codigoPermissoes={[
                    PermissoesVinculoEnderecoEstoqueCentroDeTrabalho.Consultar,
                  ]}
                >
                  <CentroDeTrabalhoAbaVinculoEnderecoEstoque
                    idCentroDeTrabalhoVinculado={props.idRegistroEmEdicao}
                    hookForm={hookForm}
                  />
                </RequerPermissao>
              </Item>
            </TabPanel>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
