import getApi from "../../../configs/api";
import { VerificacaoDeDadosEncontradosNaTranscricaoDoPrefixoESufixoResponse } from "../../../features/estoque/lote/models/lote.api";
import { ResponseModel } from "../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../comum/nomesEndpoints";

const nomeEndpoint: string = NomesEndpoints.NumeroDeSeriePrefixo;

async function getNumeroSerieOuLotePrefixoAsync(itemId: number) {
  const api = getApi();
  const response = await api.get<ResponseModel<string>>(
    `${nomeEndpoint}/NumeroSerieOuLotePrefixoAsync?itemId=${itemId}`
  );

  return response.data.model;
}

async function verificaSeTranscricaoDePrefixoOuSufixoNaoEncontrouAlgumDadoAsync(
  itemId: number
) {
  const api = getApi();
  const response = await api.get<
    ResponseModel<VerificacaoDeDadosEncontradosNaTranscricaoDoPrefixoESufixoResponse>
  >(
    `${nomeEndpoint}/VerificaSeTranscricaoDePrefixoOuSufixoNaoEncontrouAlgumDadoAsync?itemId=${itemId}`
  );

  return response.data.model;
}

export default {
  getNumeroSerieOuLotePrefixoAsync,
  verificaSeTranscricaoDePrefixoOuSufixoNaoEncontrouAlgumDadoAsync,
};
