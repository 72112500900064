import { Column, IColumnProps } from "devextreme-react/cjs/data-grid";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import { MovimentacaoBaseGridModel } from "../../../models/estoque-movimentacao.api";

const nameOfGridHandler = criarNameof<MovimentacaoBaseGridModel>();

export type OrdenacaoMovimentacao = {
  ordenarPor:
    | "dataDaMovimentacao"
    | "itemCodigo"
    | "itemDescricao"
    | "quantidade"
    | "unidadeDeMedida"
    | "valorUnitario"
    | "valorTotal"
    | "loteOuNumeroDeSerie"
    | "loteInterno"
    | "destino";
  ordem: "asc" | "desc";
};

export default function GetColunasMovimentacao(
  ordenacao?: OrdenacaoMovimentacao[]
) {
  const definirOrdenacao = (key: string): IColumnProps => {
    if (ordenacao === undefined) {
      return {};
    }

    const index = ordenacao?.findIndex((x) => x.ordenarPor === key);
    if (index === -1 || index === undefined) {
      return {};
    }

    return {
      sortOrder: ordenacao[index].ordem,
      sortIndex: index,
    };
  };

  const colunas = [
    <Column
      key={nameOfGridHandler("dataDaMovimentacao")}
      dataField={nameOfGridHandler("dataDaMovimentacao")}
      {...obterConfiguracaoColuna("dataAnoCurtoComHoraMinutoSegundos")}
      {...definirOrdenacao("dataDaMovimentacao")}
      caption="Data da movimentação"
      width={150}
    />,
    <Column
      key={nameOfGridHandler("itemCodigo")}
      dataField={nameOfGridHandler("itemCodigo")}
      {...obterConfiguracaoColuna("stringG")}
      {...definirOrdenacao("itemCodigo")}
      caption="Item"
    />,
    <Column
      key={nameOfGridHandler("itemDescricao")}
      dataField={nameOfGridHandler("itemDescricao")}
      {...obterConfiguracaoColuna("stringXG")}
      {...definirOrdenacao("itemDescricao")}
      caption="Descrição do item"
    />,
    <Column
      key={nameOfGridHandler("quantidade")}
      dataField={nameOfGridHandler("quantidade")}
      {...obterConfiguracaoColuna("quantidade")}
      {...definirOrdenacao("quantidade")}
      caption="Quantidade"
    />,
    <Column
      key={nameOfGridHandler("unidadeDeMedida")}
      dataField={nameOfGridHandler("unidadeDeMedida")}
      {...obterConfiguracaoColuna("unidade")}
      {...definirOrdenacao("unidadeDeMedida")}
      caption="Unidade de medida"
      width={160}
    />,
    <Column
      key={nameOfGridHandler("valorUnitario")}
      dataField={nameOfGridHandler("valorUnitario")}
      {...obterConfiguracaoColuna("quantidade")}
      {...definirOrdenacao("valorUnitario")}
      caption="Valor unitário"
      width={120}
    />,
    <Column
      key={nameOfGridHandler("valorTotal")}
      dataField={nameOfGridHandler("valorTotal")}
      {...obterConfiguracaoColuna("quantidade")}
      {...definirOrdenacao("valorTotal")}
      caption="Valor total"
      width={120}
    />,
    <Column
      key={nameOfGridHandler("loteOuNumeroDeSerie")}
      dataField={nameOfGridHandler("loteOuNumeroDeSerie")}
      {...obterConfiguracaoColuna("stringGG")}
      {...definirOrdenacao("loteOuNumeroDeSerie")}
      caption="Lote/número de série"
    />,
    <Column
      key={nameOfGridHandler("loteInterno")}
      dataField={nameOfGridHandler("loteInterno")}
      {...obterConfiguracaoColuna("stringGG")}
      {...definirOrdenacao("loteInterno")}
      caption="Lote interno"
    />,
    <Column
      key={nameOfGridHandler("destino")}
      dataField={nameOfGridHandler("destino")}
      {...obterConfiguracaoColuna("stringG")}
      {...definirOrdenacao("destino")}
      caption="Destino"
      width={250}
    />,
  ];

  return colunas;
}
