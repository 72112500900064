import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/cjs/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import GridColunaAcoes from "../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../../hooks/form.hooks";
import { useParametroId } from "../../../../../../hooks/route.hooks";
import { PermissoesEstoqueMovimentacao } from "../../../../../../models/direitos/estoque/estoque-movimentacao/permissoes-estoque-movimentacao";
import { PermissoesOrdemDeProducao } from "../../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { ResultadoAcaoFormulario } from "../../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { EstoqueMovimentacaoService } from "../../../../../../services/estoque-movimentacoes/estoque-movimentacoes-service";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import NomesModais from "../../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../../utils/common/normaliza-titulo";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../../utils/url/url-utils";
import FormRetirarMaterial from "../../../../../producao/ordem-de-producao/componentes/formularios/retirar-material";
import { OrdemDeProducaoEstado } from "../../../../../producao/ordem-de-producao/utils/enums/ordem-de-producao-enums";
import { MovimentacaoBaseGridModel } from "../../../models/estoque-movimentacao.api";
import GetColunasMovimentacao from "../colunas/colunas-movimentacao";

const service = new EstoqueMovimentacaoService();

interface GridEmbutidaRetiradaDeMaterialProps
  extends GridMxpProps<MovimentacaoBaseGridModel> {
  ordemDeProducaoId: number;
  ordemDeProducaoEstado?: OrdemDeProducaoEstado;
}

export default function GridEmbutidaRetiradaDeMaterial(
  props: GridEmbutidaRetiradaDeMaterialProps
) {
  const gridRef = useRef<DataGrid>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const dataSource = service.ObterDataSourceParaGridRetiradaDeMaterial(
    props.ordemDeProducaoId,
    props.filtrosNoServidor
  );

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  const handleNovoRegistro = useCallback(() => {
    if (props.ordemDeProducaoEstado !== OrdemDeProducaoEstado.AProduzir) {
      exibirNotificacaoToast({
        mensagem: `O estado da ordem de produção precisa ser "A produzir" para poder criar uma retirada de material.`,
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesOrdemDeProducao.RetirarMaterial,
      ])
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, [props.ordemDeProducaoEstado]);

  const handleEditarRegistro = useCallback(
    (registro: MovimentacaoBaseGridModel) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEstoqueMovimentacao.InserirEditar,
        ])
      ) {
        setIdRegistroEmEdicao(registro.id);
      }
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: MovimentacaoBaseGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEstoqueMovimentacao.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        `Tem certeza de que deseja excluir a retirada de material?`
      );

      if (excluir) {
        try {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  const gridController = new GridController<MovimentacaoBaseGridModel>(
    () => gridRef.current?.instance
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  useRegistrarAtalhosGrid<MovimentacaoBaseGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const isGridInterna = true;
  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "retirada-de-material",
      () => gridRef.current?.instance,
      isGridInterna,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirFiltros()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.retiradaDeMaterial)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.filtrosNoCliente]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        {GridColunaAcoes<MovimentacaoBaseGridModel>({
          handleEditar: handleEditarRegistro,
          handleExcluir: handleExcluirRegistro,
        })}

        {GetColunasMovimentacao()}

        {GetColunasDeAuditoria(true, false, [
          { ordenarPor: "criacaoData", ordem: "desc" },
        ])}
      </DataGrid>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.retiradaDeMaterial
        )}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(65vw, 900px)"}
        altura={"auto"}
        alturaMaxima={"80vh"}
      >
        <FormRetirarMaterial
          {...props}
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
