import { DataGrid } from "devextreme-react";
import { createContext } from "react";
import { InsumoDaOrdemDeProducaoGridModel } from "../../models/insumo-da-ordem-de-producao";
import { ModalBaixarInsumosProps } from "../modal-baixar-insumo";

export type FuncaoDeInsumo = (insumo: InsumoDaOrdemDeProducaoGridModel) => void;

export class FuncoesColunaQuantidadeABaixarInsumo {
  public setRegistroEstornar: FuncaoDeInsumo;
  public setModalBaixarInsumosProps: (x: ModalBaixarInsumosProps) => void;
  public atualizaGridDeInsumos: () => void;

  constructor() {
    this.atualizaGridDeInsumos = () => {
      undefined;
    };
    this.setModalBaixarInsumosProps = () => {
      undefined;
    };
    this.setModalBaixarInsumosProps = (x: ModalBaixarInsumosProps) => {
      x;
    };
    this.setRegistroEstornar = (insumo: InsumoDaOrdemDeProducaoGridModel) => {
      insumo;
    };
  }

  definfirSetRegistroEstornar(x: FuncaoDeInsumo) {
    this.setRegistroEstornar = x;
  }

  definirSetModalBaixarInsumosProps(y: (x: ModalBaixarInsumosProps) => void) {
    this.setModalBaixarInsumosProps = y;
  }

  definirAtualizaGridDeInsumos(x: () => void) {
    this.atualizaGridDeInsumos = x;
  }
}

export class ReferenciasGridsSelecaoInsumo {
  public referenciaGridSelecaoEstoqueBaixaInsumo:
    | React.RefObject<DataGrid>
    | undefined;
  public referenciaGridSelecaoEstoqueMovimentacaoEstornarInsumo:
    | React.RefObject<DataGrid>
    | undefined;

  constructor() {
    this.referenciaGridSelecaoEstoqueBaixaInsumo = undefined;
    this.referenciaGridSelecaoEstoqueMovimentacaoEstornarInsumo = undefined;
  }
  definirReferenciaGridSelecaoEstoqueBaixaInsumo(x: React.RefObject<DataGrid>) {
    this.referenciaGridSelecaoEstoqueBaixaInsumo = x;
  }

  definirReferenciaGridSelecaoEstoqueMovimentacaoEstornarInsumo(
    x: React.RefObject<DataGrid>
  ) {
    this.referenciaGridSelecaoEstoqueMovimentacaoEstornarInsumo = x;
  }
}

interface ContextoOperacoesInsumoProps {
  funcoes: FuncoesColunaQuantidadeABaixarInsumo;
  referenciasGridInsumos: ReferenciasGridsSelecaoInsumo;
}

const ContextoOperacoesInsumo = createContext<ContextoOperacoesInsumoProps>(
  {} as ContextoOperacoesInsumoProps
);

export default ContextoOperacoesInsumo;
