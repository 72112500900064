import { useEffect, useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormGrupo,
  FormNumberBox,
  FormTextBox,
  FormTextBoxSimples,
} from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { useHookForms } from "../../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { previneDigitacaoDeCaracteres } from "../../../../../utils/common/common-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import {
  letrasDigitosSimbolosSemEspacosComColchetes,
  letrasDigitosSimbolosSemEspacosComColchetesPermiteStringVazia,
} from "../../../../../utils/common/regex-padrao";
import {
  NumeroDeSeriePrefixoAtualizarRequest,
  NumeroDeSeriePrefixoResponse,
} from "../../models/numero-de-serie-prefixo";
import NumeroDeSeriePrefixoConstantes from "../../models/numero-de-serie-prefixo.constantes";
import NumeroDeSeriePrefixoService from "../../servicos/numero-de-serie-prefixo.servico";

const novoRegistro: NumeroDeSeriePrefixoAtualizarRequest = {
  id: 0,
  prefixo: "",
  proximoSequencial: 1,
  digitosNumericos:
    NumeroDeSeriePrefixoConstantes.DigitosNumericosQuantidadePadrao,
  sufixo: "",
};

const service = new NumeroDeSeriePrefixoService();

let dadosAuditoria: AuditavelDTO | undefined = undefined;

const previneDigitacaoDeCaracteresHandler = (e: any) =>
  previneDigitacaoDeCaracteres(e, letrasDigitosSimbolosSemEspacosComColchetes);

export default function EditFormNumeroDeSeriePrefixo(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    prefixo: yup
      .string()
      .notRequired()
      .max(NumeroDeSeriePrefixoConstantes.PrefixoTamanhoMaximo)
      .matches(
        letrasDigitosSimbolosSemEspacosComColchetesPermiteStringVazia,
        "Deve conter apenas letras maiúsculas, letras sem acento, números e símbolos"
      ),
    digitosNumericos: yup.number().required().moreThan(0).integer(),
    proximoSequencial: yup.number().required().moreThan(0).integer(),
    sufixo: yup
      .string()
      .notRequired()
      .max(NumeroDeSeriePrefixoConstantes.SufixoTamanhoMaximo)
      .matches(
        letrasDigitosSimbolosSemEspacosComColchetesPermiteStringVazia,
        "Deve conter apenas letras maiúsculas, letras sem acento, números e símbolos"
      ),
  });

  const hookForm = useHookForms(schema);

  const { register, control, handleSubmit, getValues, reset, watch } = hookForm;

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta =
        await service.ObterPorIdComDadosAuditoria<NumeroDeSeriePrefixoResponse>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();

    if (!model.prefixo && !model.sufixo) {
      setCarregando(false);
      exibirNotificacaoToast({
        mensagem: 'É obrigatório a informação do campo "Prefixo" ou "Sufixo".',
        tipo: TipoNotificacao.Erro,
      });

      return;
    }

    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await service.Atualizar(model)
          : await service.Cadastrar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  const proximoNumeroDeSerie = `${watch("prefixo") ?? ""}${(
    watch("proximoSequencial") ?? 1
  )
    .toString()
    .padStart(watch("digitosNumericos") ?? 8, "0")}${watch("sufixo") ?? ""}`;

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id={"edit-form-numero-de-serie-prefixo"}>
            <input type="hidden" {...register("id")} defaultValue={0} />
            <FormGrupo>
              <Linha>
                <Coluna md={4}>
                  <FormTextBox
                    name="prefixo"
                    titulo="Prefixo"
                    control={control}
                    tamanhoMaximo={
                      NumeroDeSeriePrefixoConstantes.PrefixoTamanhoMaximo
                    }
                    somenteLeitura={props.idRegistroEmEdicao > 0}
                    transform="uppercase"
                    onKeyDown={previneDigitacaoDeCaracteresHandler}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  <FormNumberBox
                    name="digitosNumericos"
                    titulo="Dígitos numéricos"
                    tipo="number"
                    control={control}
                    quantidadeIncrementarDecrementar={
                      NumeroDeSeriePrefixoConstantes.DigitosNumericos
                        .QuantidadeIncrementoDecremento
                    }
                    exibirSetasIncrementarDecrementar
                    requerido
                    minimo={
                      NumeroDeSeriePrefixoConstantes.DigitosNumericos.Minimo
                    }
                    maximo={
                      NumeroDeSeriePrefixoConstantes.DigitosNumericos.Maximo
                    }
                    valueChangeEvent={"keyup blur"}
                  />
                </Coluna>
                <Coluna md={6}>
                  <FormNumberBox
                    name="proximoSequencial"
                    titulo="Próximo sequencial"
                    control={control}
                    quantidadeIncrementarDecrementar={
                      NumeroDeSeriePrefixoConstantes.ProximoSequencial
                        .QuantidadeIncrementoDecremento
                    }
                    exibirSetasIncrementarDecrementar
                    requerido
                    minimo={
                      NumeroDeSeriePrefixoConstantes.ProximoSequencial.Minimo
                    }
                    maximo={
                      NumeroDeSeriePrefixoConstantes.ProximoSequencial.Maximo
                    }
                    valueChangeEvent={"keyup blur"}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={4}>
                  <FormTextBox
                    name="sufixo"
                    titulo="Sufixo"
                    control={control}
                    tamanhoMaximo={
                      NumeroDeSeriePrefixoConstantes.SufixoTamanhoMaximo
                    }
                    somenteLeitura={props.idRegistroEmEdicao > 0}
                    transform="uppercase"
                    onKeyDown={previneDigitacaoDeCaracteresHandler}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormTextBoxSimples
                    titulo="Próximo lote ou número de série"
                    somenteLeitura
                    valor={proximoNumeroDeSerie}
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
