import DataGrid, { Column } from "devextreme-react/cjs/data-grid";
import { useMemo, useRef } from "react";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { VinculoDaOrdemDeProducaoGridModel } from "../../../../../models/api/vinculo/vinculo-da-ordem-de-producao-grid-model";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";

const service = new OrdemDeProducaoService();
const nameOfGridHandler = criarNameof<VinculoDaOrdemDeProducaoGridModel>();

interface GridEmbutidaVinculosDaOrdemDeProducaoProps
  extends GridMxpProps<VinculoDaOrdemDeProducaoGridModel> {
  ordemDeProducaoId: number;
}

export default function GridEmbutidaVinculosDaOrdemDeProducao(
  props: GridEmbutidaVinculosDaOrdemDeProducaoProps
) {
  const gridRef = useRef<DataGrid>(null);

  const dataSource = service.ObterDataSourceParaGridVinculos(
    props.ordemDeProducaoId
  );

  const gridController = new GridController<VinculoDaOrdemDeProducaoGridModel>(
    () => gridRef.current?.instance
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const menusDeContexto: ItemContextMenuMxp[] = [];

  useRegistrarAtalhosGrid({
    controller: gridController,
    // Adicionar handlers de manipulação se/quando forem criados.
  });

  useMenuDeContextosGrid(menusDeContexto);

  const configuracoesGrid = useMemo(
    () =>
      GridBuilder.criar("vinculos-op", () => gridRef?.current?.instance)
        .definirDataSource(dataSource)
        .definirFiltros()
        .definirRolagem()
        .configurarSelecionadorDeColunas()
        .definirGravacaoPreferenciasGrid()
        .definirPaginacao()
        .configurarExportacao("Vínculos da Ordem de Produção")
        .definirBotaoRefresh(handleAtualizarGrid)
        .definirOrdenacao()
        .definirMenuDeContexto(menusDeContexto)
        .build(),
    [gridRef, dataSource, menusDeContexto]
  );

  return (
    <DataGrid ref={gridRef} {...configuracoesGrid}>
      <Column
        key={nameOfGridHandler("vinculoPara")}
        dataField={nameOfGridHandler("vinculoPara")}
        {...obterConfiguracaoColuna("stringXG")}
        width={480}
        allowFiltering={false}
      />
      <Column
        key={nameOfGridHandler("apelidoClientePedidoDeVenda")}
        dataField={nameOfGridHandler("apelidoClientePedidoDeVenda")}
        {...obterConfiguracaoColuna("stringGG")}
        caption="Cliente"
      />
      <Column
        key={nameOfGridHandler("dataDeEntregaItemPedidoDeVenda")}
        dataField={nameOfGridHandler("dataDeEntregaItemPedidoDeVenda")}
        {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
        caption="Data de entrega"
        width={150}
      />
      <Column
        key={nameOfGridHandler("quantidade")}
        dataField={nameOfGridHandler("quantidade")}
        {...obterConfiguracaoColuna("quantidade")}
        caption="Quantidade"
      />
      <Column
        key={nameOfGridHandler("codigoItem")}
        dataField={nameOfGridHandler("codigoItem")}
        {...obterConfiguracaoColuna("stringG")}
        caption="Item"
      />
      <Column
        key={nameOfGridHandler("descricaoItem")}
        dataField={nameOfGridHandler("descricaoItem")}
        {...obterConfiguracaoColuna("stringXG")}
        caption="Item"
      />
      {GetColunasDeAuditoria(false, false, [
        { ordenarPor: "criacaoData", ordem: "desc" },
      ])}
    </DataGrid>
  );
}
