import { Button, Popup } from "devextreme-react";
import { Accordion } from "devextreme-react/accordion";
import { useAppDispatch, useAppSelector } from "../../hooks/store.hooks";
import { ErroApi } from "../../models/api/comum/erros";
import { NotificationResponseModel } from "../../models/api/comum/response-base";
import { ocultarAccordionNotifications } from "../../store/ui/ui.slice";
import { configuracoesErros } from "../api/api-utils.configuracoes-erro";
import { ButtonContainer } from "./styles";

export default function NotificacaoPopup() {
  const dispatch = useAppDispatch();

  const model = useAppSelector(
    (state) => state.estadoUI.accordionNotifications.notificacoes
  );
  const visivel = useAppSelector(
    (state) => state.estadoUI.accordionNotifications.visivel
  );
  const titulo = useAppSelector(
    (state) => state.estadoUI.accordionNotifications.titulo
  );

  const notificacao = (
    <>
      <Popup
        visible={visivel}
        dragEnabled={true}
        hideOnOutsideClick={false}
        showCloseButton={false}
        showTitle={true}
        titleRender={() => (
          <div style={{ fontSize: "16px", fontWeight: "500" }}>{titulo}</div>
        )}
        maxWidth={750}
        height="auto"
      >
        {model.mensagem}
        <div style={{ fontSize: "12pt" }}>
          <Accordion
            dataSource={model.model}
            collapsible={true}
            multiple={false}
            animationDuration={150}
            itemTitleRender={customTitle}
            itemRender={customItem}
            id="accordion-grupos"
          />
        </div>
        <ButtonContainer>
          <Button
            type="success"
            text="Ok"
            onClick={() => dispatch(ocultarAccordionNotifications())}
          />
        </ButtonContainer>
      </Popup>
    </>
  );

  return notificacao;
}

const customTitle = (item: NotificationResponseModel) => {
  return (
    <div style={{ fontSize: "13px", fontWeight: "500" }}>{item.grupo}</div>
  );
};

function formatarErro(erro: ErroApi) {
  if (
    erro.codigoErro in configuracoesErros &&
    typeof configuracoesErros[erro.codigoErro]?.formatador === "function"
  ) {
    return configuracoesErros[erro.codigoErro]!.formatador!(erro);
  } else {
    return erro.mensagem;
  }
}

const customItem = (item: NotificationResponseModel) => {
  if (item.erros && item.erros.length > 0) {
    return (
      <ul>
        {item.erros.map((item, ix) => (
          <li key={`p-${ix}`}>{formatarErro(item)}</li>
        ))}
      </ul>
    );
  } else {
    return item.notificacoes.map((item, ix) => (
      <p key={`p-${ix}`}>&#x2022; {item}</p>
    ));
  }
};
