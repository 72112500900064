import DataSource from "devextreme/data/data_source";
import { useContext, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  FormCheckBox,
  FormDateBox,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
  FormTextBox,
} from "../../../../../components/formularios";
import FormSelectBoxSimples from "../../../../../components/formularios/selectbox-simples";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { useCarregarCombos } from "../../../../../hooks/form.hooks";
import { SelectBoxLazyFiltros } from "../../../../../models/api/comum/selectboxlazy-options";
import { EstadoDoCiapDoImobilizado } from "../../../../../models/api/imobilizado/imobilizado";
import { NotaFiscalRecebidaSituacao } from "../../../../../models/api/nota-fiscal-recebida/nota-fiscal-recebida";
import { SelectItemEnumEstadoDoCiapDoImobilizado } from "../../../../../models/const/dicionario-combos/imobilizado";
import SelectItem from "../../../../../models/shared/ui/select-item";
import { ImobilizadoViewModel } from "../../../../../models/viewmodels/contabilidade/imobilizado/imobilizado.viewmodel";
import { ContextoModelosDocumentos55e67CadastroImobilizado } from "../../../../../pages/contabilidade/imobilizado/edit-form";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import ItemNotaFiscalRecebidaService from "../../../../../services/item-nota-fiscal-recebida/item-nota-fiscal-recebida.service";
import UnidadeMedidaService from "../../../../../services/unidade-medida/unidade-medida.service";
import ValorOpcaoService from "../../../../../services/valor-opcao/valor-opcao.service";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { previneDigitacaoDeCaracteres } from "../../../../../utils/common/common-utils";
import { digitosNumericosSemEspaco } from "../../../../../utils/common/regex-padrao";
import { formatarData } from "../../../../../utils/formatadores/formatador-de-datas";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../utils/formatadores/formatador-de-selectbox";
import { GridItemNotaFiscalRecebidaComImpostos } from "../../../../compras/item-nota-fiscal-recebida/grid";
import { GridEmpresa } from "../../../../empresas/empresa/grids";

interface AbaCiapImobilizadoProps {
  hookForm: UseFormReturn<ImobilizadoViewModel, any, undefined>;
  idRegistroEdicao: number;
}

const unidadeMedidaService = new UnidadeMedidaService();
const valorOpcaoService = new ValorOpcaoService();
const itemNotaFiscalRecebida = new ItemNotaFiscalRecebidaService();

const previneDigitacaoDeCaracteresHandler = (e: any) =>
  previneDigitacaoDeCaracteres(e, digitosNumericosSemEspaco);

const exibicaoFornecedores = (c: any) => {
  if (c) {
    return c.CnpjCpfCodigo
      ? FormataDescricao(
          FormatadoresSelectBox.CodigoDescricaoParenteses,
          c.Apelido,
          c.CnpjCpfCodigo
        )
      : c.Apelido;
  }

  return "";
};

const fornecedoresExpressaoDeBusca = ["CnpjCpfCodigo", "Apelido"];

const exibicaoItemNotaFiscalRecebida = (c: any) => {
  if (c) {
    return FormataDescricao(
      FormatadoresSelectBox.ItemNF,
      c.NumeroNota,
      c.SequenciaDoItemNaNota,
      formatarData(c.DataEmissaoNota),
      c.CodigoItem,
      c.Descricao
    );
  }

  return "";
};

const itemNotaFiscalRecebidaExpressaoDeBusca = [
  "NumeroNota",
  "ApelidoFornecedorNota",
  "DataEmissaoNota",
  "CodigoItem",
  "Descricao",
];

const filtrosItemNotaFiscalRecebida: SelectBoxLazyFiltros[] = [
  {
    nomeCampo: "SituacaoNota",
    operador: "=",
    valor: NotaFiscalRecebidaSituacao.Recebida,
  },
  {
    nomeCampo: "TipoDocumentoFiscalEhDaTabelaDoSped",
    operador: "=",
    valor: true,
  },
];

const itemNotaFiscalRecebidaDataSource =
  itemNotaFiscalRecebida.GetDataSourceSelectBoxLazy({
    camposRetorno: [
      "Id",
      "SequenciaDoItemNaNota",
      "DataEmissaoNota",
      "NumeroNota",
      "ChaveAcessoNota",
      "SerieNota",
      "ApelidoFornecedorNota",
      "FornecedorIdNota",
      "TipoDocumentoFiscalId",
      "UnidadeId",
      "BaseDeCalculoIcms",
      "AliquotaIcms",
      "ValorIcms",
      "ValorIcmsSt",
      "ValorIcmsDifal",
      "Quantidade",
      "Descricao",
      "CodigoItem",
    ],
    camposOrdenacao: [
      {
        nomeCampo: "DataEmissaoNota",
        desc: true,
      },
    ],
    filtros: filtrosItemNotaFiscalRecebida,
  });

export default function AbaCiapImobilizado({
  hookForm,
  idRegistroEdicao,
}: AbaCiapImobilizadoProps) {
  const { control, watch, setValue, getValues } = hookForm;
  const { modelos, setModelos } = useContext(
    ContextoModelosDocumentos55e67CadastroImobilizado
  );
  const [unidadesMedida, setUnidadesMedida] = useState<SelectItem[]>([]);
  const [tiposDocumentosFiscais, setTiposDocumentosFiscais] = useState<
    SelectItem[]
  >([]);
  const [fornecedores, setFornecedores] = useState<DataSource | undefined>();

  useEffect(() => {
    const itemId = watch("itemId");

    filtrosItemNotaFiscalRecebida.forEach((item, index) => {
      if (item.nomeCampo === "ItemId") {
        filtrosItemNotaFiscalRecebida.splice(index, 1);
      }
    });

    if (itemId) {
      filtrosItemNotaFiscalRecebida.push({
        nomeCampo: "ItemId",
        operador: "=",
        valor: itemId,
      });
    }

    itemNotaFiscalRecebidaDataSource.filter(
      filtrosItemNotaFiscalRecebida.map((x) => [
        x.nomeCampo,
        x.operador,
        x.valor,
      ])
    );

    if (itemNotaFiscalRecebidaDataSource.isLoaded()) {
      itemNotaFiscalRecebidaDataSource.reload();
    }
  }, [watch("itemId")]);

  const chaveAcessoRequerida = modelos.some(
    (x) => x == watch("ciapDoImobilizado.tipoDeDocumentoFiscalId")
  );

  useCarregarCombos(idRegistroEdicao, carregarCombos);

  async function carregarCombos() {
    await carregarUnidadesMedidas();
    await carregarTiposDeDocumentosFiscais();
    carregarFornecedores();
  }

  async function carregarUnidadesMedidas() {
    try {
      const resposta = await unidadeMedidaService.ObterListaSimples();
      checarResponse(resposta);
      setUnidadesMedida(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function carregarTiposDeDocumentosFiscais() {
    try {
      const resposta =
        await valorOpcaoService.ObterListaSimplesDoTiposDeDocumentosFiscaisDoIcmsParaOSped();
      checarResponse(resposta);
      setTiposDocumentosFiscais(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
      setModelos(
        resposta.model
          .filter(
            (x) => x.descricao.startsWith("55") || x.descricao.startsWith("57")
          )
          .map((x) => x.valor)
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function carregarFornecedores() {
    setFornecedores(
      APIBase.getDataSourceSelectBoxLazy(
        {
          camposRetorno: ["Id", "Apelido", "CnpjCpfCodigo"],
          camposOrdenacao: [
            {
              nomeCampo: "Apelido",
              desc: false,
            },
          ],
        },
        NomesEndpoints.Empresa
      )
    );
  }

  const camposSomenteLeitura = !watch("sujeitoAoCiap");

  function impedirEdicaoDasParcelasApropriadas() {
    if (idRegistroEdicao == 0) {
      return camposSomenteLeitura;
    }

    const ciap = getValues("ciapDoImobilizado");

    if (ciap) {
      const valores = Object.values(ciap);
      const bloquear = valores.some((x) => x !== null);
      return (bloquear && hookForm.formState.isValid) || camposSomenteLeitura;
    }

    return !(!ciap && !camposSomenteLeitura);
  }

  function obterEstadoDoCiap() {
    const parcelas = watch("ciapDoImobilizado.parcelas");
    const parcelasApropriadas = watch("ciapDoImobilizado.parcelasApropriadas");

    if (parcelasApropriadas == 0) {
      return EstadoDoCiapDoImobilizado.NaoIniciado;
    } else if (parcelasApropriadas > 0 && parcelasApropriadas < parcelas) {
      return EstadoDoCiapDoImobilizado.EmAndamento;
    } else if (parcelasApropriadas == parcelas) {
      return EstadoDoCiapDoImobilizado.Concluido;
    }
  }

  function InicializarCamposAPartirDoItemDaNotaFiscal(e: any) {
    setValue("ciapDoImobilizado.dataEmissao", e.selectedItem.DataEmissaoNota);
    setValue("ciapDoImobilizado.numero", e.selectedItem.NumeroNota);
    setValue("ciapDoImobilizado.chaveAcesso", e.selectedItem.ChaveAcessoNota);
    setValue("ciapDoImobilizado.serie", e.selectedItem.SerieNota);
    setValue("ciapDoImobilizado.quantidade", e.selectedItem.Quantidade);
    setValue("ciapDoImobilizado.fornecedorId", e.selectedItem.FornecedorIdNota);
    setValue(
      "ciapDoImobilizado.tipoDeDocumentoFiscalId",
      e.selectedItem.TipoDocumentoFiscalId
    );
    setValue("ciapDoImobilizado.unidadeId", e.selectedItem.UnidadeId);
    setValue(
      "ciapDoImobilizado.baseCalculoIcms",
      e.selectedItem.BaseDeCalculoIcms
    );
    setValue("ciapDoImobilizado.aliquotaIcms", e.selectedItem.AliquotaIcms);
    setValue("ciapDoImobilizado.valorIcms", e.selectedItem.ValorIcms);
    setValue("ciapDoImobilizado.valorIcmsSt", e.selectedItem.ValorIcmsSt);
    setValue("ciapDoImobilizado.valorIcmsDifal", e.selectedItem.ValorIcmsDifal);
  }

  return (
    <>
      <div style={{ marginTop: "5px" }}></div>
      <Linha>
        <Coluna md={4} centralizar>
          <FormCheckBox
            titulo={"Sujeito ao CIAP"}
            name={"sujeitoAoCiap"}
            control={control}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={8}>
          <FormSelectBoxLazy
            name="ciapDoImobilizado.itemNotaFiscalId"
            titulo="Item da nota fiscal recebida"
            control={control}
            nomeCampoChave="Id"
            nomeCampoExibicao={exibicaoItemNotaFiscalRecebida}
            expressaoDeBusca={itemNotaFiscalRecebidaExpressaoDeBusca}
            dataSource={itemNotaFiscalRecebidaDataSource}
            somenteLeitura={camposSomenteLeitura}
            lupaConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar item da nota fiscal recebida",
              componente: (r) => (
                <GridItemNotaFiscalRecebidaComImpostos
                  ref={r}
                  filtrosRealizadosNoServidor={filtrosItemNotaFiscalRecebida}
                />
              ),
            }}
            labelSemDados="Sem dados"
            onSelectionChanged={InicializarCamposAPartirDoItemDaNotaFiscal}
          />
        </Coluna>
        <Coluna md={4}>
          <FormSelectBoxSimples
            titulo="Estado"
            dataSource={SelectItemEnumEstadoDoCiapDoImobilizado}
            requerido
            valor={obterEstadoDoCiap()}
            somenteLeitura
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={5}>
          <FormSelectBox
            name="ciapDoImobilizado.tipoDeDocumentoFiscalId"
            titulo="Documento fiscal"
            control={control}
            dataSource={tiposDocumentosFiscais}
            habilitaBusca
            requerido
            somenteLeitura={camposSomenteLeitura}
          />
        </Coluna>
        <Coluna md={7}>
          <FormTextBox
            name="ciapDoImobilizado.chaveAcesso"
            titulo="Chave de acesso"
            control={control}
            tamanhoMaximo={44}
            onKeyDown={previneDigitacaoDeCaracteresHandler}
            somenteLeitura={camposSomenteLeitura}
            requerido={chaveAcessoRequerida}
            onChange={(chaveAcesso: string) => {
              setValue(
                "ciapDoImobilizado.numero",
                chaveAcesso.substring(26, 34)
              );
              setValue(
                "ciapDoImobilizado.serie",
                chaveAcesso.substring(23, 24)
              );
            }}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={2}>
          <FormTextBox
            name="ciapDoImobilizado.numero"
            titulo="Número"
            control={control}
            tamanhoMaximo={9}
            requerido
            somenteLeitura={camposSomenteLeitura}
          />
        </Coluna>
        <Coluna md={2}>
          <FormTextBox
            name="ciapDoImobilizado.serie"
            titulo="Série"
            control={control}
            tamanhoMaximo={3}
            somenteLeitura={camposSomenteLeitura}
          />
        </Coluna>
        <Coluna md={3}>
          <FormDateBox
            name="ciapDoImobilizado.dataEmissao"
            titulo="Emissão"
            control={control}
            requerido
            exibirBotaoLimpar
            tipo="date"
            aceitaValorCustomizado={true}
            formatoExibicao="dd/MM/yy"
            aceitaDigitacaoComMascara={true}
            somenteLeitura={camposSomenteLeitura}
          />
        </Coluna>
        <Coluna md={5}>
          <FormSelectBoxLazy
            name="ciapDoImobilizado.fornecedorId"
            titulo="Fornecedor"
            control={control}
            requerido
            nomeCampoChave="Id"
            nomeCampoExibicao={exibicaoFornecedores}
            expressaoDeBusca={fornecedoresExpressaoDeBusca}
            dataSource={fornecedores}
            lupaConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar fornecedor",
              componente: (r) => <GridEmpresa ref={r} />,
            }}
            labelSemDados="Sem dados"
            somenteLeitura={camposSomenteLeitura}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={4}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "70%" }}>
              <FormNumberBox
                name="ciapDoImobilizado.quantidade"
                titulo="Quantidade"
                control={control}
                formato={obterFormatStringNumero(2)}
                requerido
                somenteLeitura={camposSomenteLeitura}
                minimo={0}
              />
            </div>
            <div style={{ marginLeft: "0.2em", width: "50%" }}>
              <FormSelectBox
                name="ciapDoImobilizado.unidadeId"
                control={control}
                dataSource={unidadesMedida}
                somenteLeitura={camposSomenteLeitura}
              />
            </div>
          </div>
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.baseCalculoIcms"
            titulo="Base de cálculo do ICMS"
            control={control}
            requerido
            formato={obterFormatStringNumero(2)}
            somenteLeitura={camposSomenteLeitura}
            minimo={0}
          />
        </Coluna>
        <Coluna md={2}>
          <FormNumberBox
            name="ciapDoImobilizado.aliquotaIcms"
            titulo="Alíquota do ICMS"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura={camposSomenteLeitura}
            minimo={0}
            maximo={100}
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.valorIcms"
            titulo="Valor do ICMS"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura={camposSomenteLeitura}
            minimo={0}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.valorIcmsSt"
            titulo="Valor do ICMS ST"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura={camposSomenteLeitura}
            minimo={0}
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.valorIcmsFrete"
            titulo="Valor do ICMS do frete"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura={camposSomenteLeitura}
            minimo={0}
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.valorIcmsDifal"
            titulo="Valor do ICMS DIFAL"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura={camposSomenteLeitura}
            minimo={0}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.parcelas"
            titulo="Parcelas"
            control={control}
            formato={obterFormatStringNumero(0)}
            requerido
            somenteLeitura={camposSomenteLeitura}
            minimo={0}
            maximo={999}
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.parcelasApropriadas"
            titulo="Parcelas apropriadas"
            control={control}
            formato={obterFormatStringNumero(0)}
            requerido={idRegistroEdicao == 0}
            somenteLeitura={impedirEdicaoDasParcelasApropriadas()}
            minimo={0}
            maximo={999}
          />
        </Coluna>
      </Linha>
    </>
  );
}
