import { DataGrid } from "devextreme-react";
import { createContext } from "react";

interface SeletorContextModel {
  refGrid: DataGrid | null | undefined;
  setRefGrid: (func: () => DataGrid | null | undefined) => void;
  setFiltroPadraoNoCliente: (filtro?: any) => void;
  filtroPadraoNoCliente?: any;
}

export const ContextoSeletor = createContext<SeletorContextModel>({
  refGrid: null,
  setRefGrid: () => {
    // implementação default
  },
  setFiltroPadraoNoCliente: () => {
    // implementação default
  },
  filtroPadraoNoCliente: undefined,
});
