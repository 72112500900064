import DataGrid, { Column } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import ImportadorDeRegistros, {
  colunaParaNumero,
  colunaUtcParaData,
  MxpSchemaImportar,
} from "../../../../../components/formularios/importador";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { MenuEdicaoOrdemDeProducao } from "../../../../../components/ordem-de-producao/menu-edicao-op";
import { ProvedorMenuEdicaoOrdemDeProducao } from "../../../../../components/ordem-de-producao/provedor-menu-op";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import {
  quantidadeValorMaximo,
  quantidadeValorMinimo,
} from "../../../../../utils/common/constantes";
import criarNameof from "../../../../../utils/common/cria-name-of";
import { navegarParaMxpAntigo } from "../../../../../utils/common/menu-utils";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import {
  GestorEventoClickSimples,
  GestorEventoClickUnicaLinha,
} from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import yupPt from "../../../../../utils/validacao/validacao";
import FormIncluirReservaOrdemProducao from "../../../../estoque/reserva/componentes/formulario/form-incluir-reserva-ordem-producao";
import {
  OrdemDeProducaoCadastrarVariosExcelRequest,
  OrdemDeProducaoGridModel,
  quantidadeDePerdaPrevistaMaxima,
  quantidadeDePerdaPrevistaMinima,
} from "../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";
import { EstadoDecodificado } from "../../utils/enums/ordem-de-producao-enums";
import FormOrdemDeProducao from "../formularios";

const service = new OrdemDeProducaoService();

const schema: MxpSchemaImportar<OrdemDeProducaoCadastrarVariosExcelRequest> = {
  "Item *": {
    prop: "codigoItem",
    type: String,
    dataType: "string",
  },
  "Quantidade *": {
    prop: "quantidade",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Necessidade *": {
    prop: "necessidadeData",
    type: colunaUtcParaData,
    dataType: "date",
  },
  "Perda prevista": {
    prop: "quantidadeDePerdaPrevista",
    type: colunaParaNumero,
    dataType: "number",
  },
  Observações: {
    prop: "observacoes",
    type: String,
    dataType: "string",
  },
};

const schemaYup = yupPt.object({
  codigoItem: yupPt.string().required().label("Item"),
  quantidade: yupPt
    .number()
    .required()
    .moreThan(quantidadeValorMinimo.toNumber())
    .lessThan(quantidadeValorMaximo.toNumber())
    .label("Quantidade"),
  necessidadeData: yupPt.date().required().label("Necessidade"),
  quantidadeDePerdaPrevista: yupPt
    .number()
    .notRequired()
    .min(quantidadeDePerdaPrevistaMinima.toNumber())
    .lessThan(quantidadeDePerdaPrevistaMaxima.toNumber())
    .label("Perda prevista"),
});

const nameOfGridHandler = criarNameof<OrdemDeProducaoGridModel>();

export default function GridOrdemDeProducao(
  props: GridMxpProps<OrdemDeProducaoGridModel>
) {
  const gridRef = useRef<DataGrid>(null);
  const parametroId = useParametroId();

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const [modalImportacaoVisivel, setModalImportacaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);
  const [ordemDeProducaoDaReserva, setOrdemDeProducaoDaReserva] = useState<
    OrdemDeProducaoGridModel | undefined
  >(undefined);

  const dataSource = service.ObterDataSourceParaGrid<OrdemDeProducaoGridModel>(
    props.filtrosNoServidor
  );

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEdicao(parametroId);
    }
  }, []);

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesOrdemDeProducao.InserirEditar,
      ])
    ) {
      setIdRegistroEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: OrdemDeProducaoGridModel) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesOrdemDeProducao.InserirEditar,
        ])
      ) {
        setIdRegistroEdicao(registro.id);
      }
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesOrdemDeProducao.Excluir,
        ])
      ) {
        return;
      }

      const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (!excluir) {
        return;
      }

      try {
        const resposta = await service.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    },
    []
  );

  function obterMensagemExclusao(registro: OrdemDeProducaoGridModel) {
    return (
      <div>
        Tem certeza de que deseja excluir a ordem de produção{" "}
        {registro.numero ?? "sem número"}?<br />
        Ao excluir uma ordem de produção, os registros a seguir que forem
        vinculados a ela também serão excluídos: reservas, tarefas e anotações,
        números de série e anexos.
      </div>
    );
  }

  const gridController = new GridController<OrdemDeProducaoGridModel>(
    () => gridRef.current?.instance
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const handleModalReservaCallback = useCallback(() => {
    setOrdemDeProducaoDaReserva(undefined);
  }, [setOrdemDeProducaoDaReserva]);

  const handleCallbackFormularioEdicao = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEdicao(NaN);

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  useRegistrarAtalhosGrid<OrdemDeProducaoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleImportarExcel = useCallback(
    async (registros: OrdemDeProducaoCadastrarVariosExcelRequest[]) => {
      const resultado = await service.CadastrarEmMassaExcel(registros);
      handleAtualizarGrid();
      return resultado;
    },
    [service]
  );

  const consultarOperacoesRoteiroGantt = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      if (registro.id) {
        const rota = `/OperacaoOrdemProducao/Index?of=${registro.id}`;
        navegarParaMxpAntigo(rota);
      }
    },
    []
  );

  const consultarApontamentosDeOperacoes = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      const rota = `/ApontamentoHora/Index?IdOp=${registro.id}`;
      navegarParaMxpAntigo(rota);
    },
    []
  );

  const consultarItem = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      if (registro.itemCodigo) {
        const rota = `/Item/PorCodigo?cod=${registro.itemCodigo}`;
        navegarParaMxpAntigo(rota);
      }
    },
    []
  );

  const itensMenuDeContexto: ItemContextMenuMxp[] = [
    {
      text: "Consultar",
      icon: "ic-material-symbols-outlined ic-manage-search",
      hint: "Menu com opções de consulta",
      exibirNaLinhaDaGrid: "menuDeContexto",
      exibirNoMenuPrincipal: true,
      items: [
        {
          text: "Operações: roteiro/gantt",
          icon: "dx-icon-bulletlist",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            consultarOperacoesRoteiroGantt,
            () => gridController
          ),
        },
        {
          text: "Apontamento de operações",
          icon: "ic-material-symbols-outlined ic-pending-actions",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            consultarApontamentosDeOperacoes,
            () => gridController
          ),
        },
        {
          text: "Item",
          icon: "ic-material-symbols-outlined ic-app-registration",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            consultarItem,
            () => gridController
          ),
        },
      ],
    },
    {
      text: "Ações",
      icon: "ic-material-symbols-outlined ic-vertical",
      hint: "Menu com opções de ação",
      exibirNoMenuPrincipal: true,
      items: [
        {
          text: "Importar ordens de produção de arquivo Excel",
          icon: "ic-material-symbols-outlined ic-file-upload",
          gestorEventoClick: new GestorEventoClickSimples(() =>
            setModalImportacaoVisivel(true)
          ),
        },
      ],
    },
    {
      text: "Ações",
      icon: "ic-material-symbols-outlined ic-vertical",
      hint: "Menu com opções de ação",
      exibirNoMenuPrincipal: false,
      exibirNaLinhaDaGrid: "menuDeContexto",
      items: [
        {
          text: "Reservar",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            setOrdemDeProducaoDaReserva,
            () => gridController
          ),
        },
      ],
    },
  ];

  useMenuDeContextosGrid(itensMenuDeContexto);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "ordem-de-producao",
      () => gridRef.current?.instance,
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.ordensProducao)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirMenuDeContexto(itensMenuDeContexto)
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.filtrosNoCliente]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        {GridColunaAcoes<OrdemDeProducaoGridModel>({
          handleEditar: handleEditarRegistro,
          handleExcluir: handleExcluirRegistro,
        })}
        <Column
          key={nameOfGridHandler("numero")}
          dataField={nameOfGridHandler("numero")}
          {...obterConfiguracaoColuna("codigo")}
          caption="Nº"
          alignment="right"
          sortOrder="desc"
        />
        <Column
          key={nameOfGridHandler("itemCodigo")}
          dataField={nameOfGridHandler("itemCodigo")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Item"
        />
        <Column
          key={nameOfGridHandler("itemDescricao")}
          dataField={nameOfGridHandler("itemDescricao")}
          {...obterConfiguracaoColuna("stringXG")}
          caption="Descrição"
        />
        <Column
          key={nameOfGridHandler("itemDescricaoComplementar")}
          dataField={nameOfGridHandler("itemDescricaoComplementar")}
          {...obterConfiguracaoColuna("stringXG")}
          caption="Descrição complementar"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("grupoCodigo")}
          dataField={nameOfGridHandler("grupoCodigo")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Grupo"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("grupoDescricao")}
          dataField={nameOfGridHandler("grupoDescricao")}
          {...obterConfiguracaoColuna("stringXG")}
          caption="Descrição do grupo"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("itemDesenho")}
          dataField={nameOfGridHandler("itemDesenho")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Desenho"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("itemDesenhoRevisao")}
          dataField={nameOfGridHandler("itemDesenhoRevisao")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Revisão do desenho"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("quantidade")}
          dataField={nameOfGridHandler("quantidade")}
          {...obterConfiguracaoColuna("quantidade")}
        />
        <Column
          key={nameOfGridHandler("quantidadeDePerdaPrevista")}
          dataField={nameOfGridHandler("quantidadeDePerdaPrevista")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Qt de perda prevista"
          visible={false}
          width={180}
        />
        <Column
          key={nameOfGridHandler("quantidadeBruta")}
          dataField={nameOfGridHandler("quantidadeBruta")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Qt bruta"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("itemUnidadePrincipalCodigo")}
          dataField={nameOfGridHandler("itemUnidadePrincipalCodigo")}
          {...obterConfiguracaoColuna("stringPP")}
          caption="Un"
          width={60}
        />
        <Column
          key={nameOfGridHandler("quantidadeNaUnidadeDeVenda")}
          dataField={nameOfGridHandler("quantidadeNaUnidadeDeVenda")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Qt na un de venda"
          visible={false}
          width={150}
        />
        <Column
          key={nameOfGridHandler("itemUnidadeDeVendaCodigo")}
          dataField={nameOfGridHandler("itemUnidadeDeVendaCodigo")}
          {...obterConfiguracaoColuna("stringP")}
          caption="Un de venda"
          visible={false}
          width={120}
        />
        <Column
          key={nameOfGridHandler("necessidadeData")}
          dataField={nameOfGridHandler("necessidadeData")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Necessidade"
        />
        <Column
          key={nameOfGridHandler("estadoDecodificado")}
          dataField={nameOfGridHandler("estadoDecodificado")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Estado"
        />
        <Column
          key={nameOfGridHandler("estadoConfiguravel")}
          dataField={nameOfGridHandler("estadoConfiguravel")}
          {...obterConfiguracaoColuna("stringMG")}
          caption="Estado configurável"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("quantidadeConcluida")}
          dataField={nameOfGridHandler("quantidadeConcluida")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Qt concluída"
        />
        <Column
          key={nameOfGridHandler("itemPesoLiquidoUnitario")}
          dataField={nameOfGridHandler("itemPesoLiquidoUnitario")}
          {...obterConfiguracaoColuna("peso")}
          caption="Peso líq unitário (kg)"
          visible={false}
          width={180}
        />
        <Column
          key={nameOfGridHandler("pesoLiquidoTotal")}
          dataField={nameOfGridHandler("pesoLiquidoTotal")}
          {...obterConfiguracaoColuna("peso")}
          caption="Peso líq total (kg)"
          visible={false}
          width={180}
        />
        <Column
          key={nameOfGridHandler("itemPesoBrutoUnitario")}
          dataField={nameOfGridHandler("itemPesoBrutoUnitario")}
          {...obterConfiguracaoColuna("peso")}
          caption="Peso bruto unitário (kg)"
          visible={false}
          width={180}
        />
        <Column
          key={nameOfGridHandler("pesoBrutoTotal")}
          dataField={nameOfGridHandler("pesoBrutoTotal")}
          {...obterConfiguracaoColuna("peso")}
          caption="Peso bruto total (kg)"
          visible={false}
          width={180}
        />
        <Column
          key={nameOfGridHandler("quantidadeAConcluir")}
          dataField={nameOfGridHandler("quantidadeAConcluir")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Qt a concluir"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("emissaoData")}
          dataField={nameOfGridHandler("emissaoData")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Emissão"
          visible={false}
          width={100}
        />
        <Column
          key={nameOfGridHandler("emitidoPorApelido")}
          dataField={nameOfGridHandler("emitidoPorApelido")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Emitido por"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("quantidadeSobra")}
          dataField={nameOfGridHandler("quantidadeSobra")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Qt sobra"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("origemDecodificada")}
          dataField={nameOfGridHandler("origemDecodificada")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Origem"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("inicioPrevistoOuRealizadoData")}
          dataField={nameOfGridHandler("inicioPrevistoOuRealizadoData")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Início"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("fimPrevistoOuRealizadoData")}
          dataField={nameOfGridHandler("fimPrevistoOuRealizadoData")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Fim"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("tempoRealizadoEmHoras")}
          dataField={nameOfGridHandler("tempoRealizadoEmHoras")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Tempo realizado em horas"
          visible={false}
          width={220}
        />
        <Column
          key={nameOfGridHandler("tempoPrevistoEmHoras")}
          dataField={nameOfGridHandler("tempoPrevistoEmHoras")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Tempo previsto em horas"
          visible={false}
          width={220}
        />
        <Column
          key={nameOfGridHandler("quantidadeOuTempoRealizadoEmPercentual")}
          dataField={nameOfGridHandler(
            "quantidadeOuTempoRealizadoEmPercentual"
          )}
          {...obterConfiguracaoColuna("percentual")}
          caption="Qt ou tempo realizado %"
          visible={false}
          width={200}
        />
        <Column
          key={nameOfGridHandler("quantidadeConcluidaEmPercentual")}
          dataField={nameOfGridHandler("quantidadeConcluidaEmPercentual")}
          {...obterConfiguracaoColuna("percentual")}
          caption="Qt concluída em %"
          visible={false}
          width={150}
        />
        <Column
          key={nameOfGridHandler("maoDeObraCusto")}
          dataField={nameOfGridHandler("maoDeObraCusto")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Custo da mão de obra"
          visible={false}
          width={180}
        />
        <Column
          key={nameOfGridHandler("observacoes")}
          dataField={nameOfGridHandler("observacoes")}
          {...obterConfiguracaoColuna("stringXXG")}
          caption="Observações"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("insumosDisponibilidadeDecodificada")}
          dataField={nameOfGridHandler("insumosDisponibilidadeDecodificada")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Disponibilidade dos insumos"
          visible={false}
          width={230}
        />
        <Column
          key={nameOfGridHandler("insumosBaixasEstadoDecodificado")}
          dataField={nameOfGridHandler("insumosBaixasEstadoDecodificado")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Estado das baixas dos insumos"
          visible={false}
          width={230}
        />
        <Column
          key={nameOfGridHandler("itemPontoDeRessuprimento")}
          dataField={nameOfGridHandler("itemPontoDeRessuprimento")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Ponto de ressuprimento"
          visible={false}
          width={180}
        />
        {GetColunasDeAuditoria(true, false, [
          { ordenarPor: "criacaoData", ordem: "desc" },
        ])}
      </DataGrid>

      {/*Modal Formulário*/}
      <ProvedorMenuEdicaoOrdemDeProducao>
        <ModalMxp
          titulo={NormalizaTituloModal.Normalizar(
            idRegistroEdicao,
            NomesModais.ordemDeProducao,
            true
          )}
          visivel={!Number.isNaN(idRegistroEdicao)}
          handleFechar={handleFecharModal}
          largura={"99vw"}
          altura={"99vh"}
          alturaMaxima={"92vh"}
          larguraMaxima={"98vw"}
          componentesAdicionais={
            idRegistroEdicao !== 0 ? MenuEdicaoOrdemDeProducao : undefined
          }
        >
          <FormOrdemDeProducao
            idRegistroEmEdicao={idRegistroEdicao}
            handleCallback={handleCallbackFormularioEdicao}
          />
        </ModalMxp>
      </ProvedorMenuEdicaoOrdemDeProducao>

      <ModalMxp
        titulo={"Nova reserva"}
        visivel={!!ordemDeProducaoDaReserva}
        handleFechar={handleModalReservaCallback}
      >
        <FormIncluirReservaOrdemProducao
          idOrdemDeProducao={ordemDeProducaoDaReserva?.id ?? 0}
          codigoItem={ordemDeProducaoDaReserva?.itemCodigo ?? ""}
          descricaoItem={ordemDeProducaoDaReserva?.itemDescricao ?? ""}
          codigoOrdemDeProducao={ordemDeProducaoDaReserva?.numero ?? undefined}
          ordemDeProducaoEhSugerida={
            ordemDeProducaoDaReserva?.estadoDecodificado ==
            EstadoDecodificado.Sugerida
          }
          handleCallback={handleModalReservaCallback}
        />
      </ModalMxp>

      <ImportadorDeRegistros
        titulo="Importar ordens de produção de arquivo Excel"
        onFechar={() => setModalImportacaoVisivel(false)}
        nomeDaPlanilha="Ordens de Produção"
        visivel={modalImportacaoVisivel}
        onImportar={handleImportarExcel}
        modeloUrl={
          "../modelosXlsx/Planilha_Modelo_Ordens_de_Produção_MAXIPROD.xlsx"
        }
        schema={schema}
        schemaYup={schemaYup}
      />
    </>
  );
}
