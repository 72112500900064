import { useEffect, useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormFile,
  FormGrupo,
  FormTextBox,
} from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import {
  CertificadoDigitalRequestDTO,
  CertificadoDigitalResponseDTO,
} from "../../models/certificado-digital";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CertificadoDigitalConstantes from "../../models/certificado-digital.constantes";
import CertificadoDigitalService from "../../servicos/certificado-digital.service";

const novoRegistro: CertificadoDigitalRequestDTO = {
  id: 0,
  descricao: null,
  senha: null,
  arquivo: null,
};

const servico = new CertificadoDigitalService();

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function EditFormCertificadoDigital(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    descricao: yup
      .string()
      .nullable()
      .max(CertificadoDigitalConstantes.DescricaoTamanhoMaximo),
    senha: yup
      .string()
      .required()
      .max(CertificadoDigitalConstantes.SenhaTamanhoMaximo),
    arquivo: yup.mixed<File>().required(),
  });

  const { register, control, handleSubmit, getValues, reset } =
    useForm<CertificadoDigitalRequestDTO>({
      resolver: yupResolver(schema),
    });

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta =
        await servico.ObterPorIdComDadosAuditoria<CertificadoDigitalResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta = await servico.uploadAsync(
        model as CertificadoDigitalRequestDTO
      );

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-certificado-digital">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <FormGrupo>
              <Linha>
                <Coluna md={12}>
                  <FormTextBox
                    name="descricao"
                    titulo="Descrição"
                    control={control}
                    tamanhoMaximo={
                      CertificadoDigitalConstantes.DescricaoTamanhoMaximo
                    }
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={5}>
                  <FormTextBox
                    name="senha"
                    titulo="Senha"
                    control={control}
                    requerido
                    tipo="password"
                    tamanhoMaximo={
                      CertificadoDigitalConstantes.SenhaTamanhoMaximo
                    }
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={5}>
                  <FormFile
                    name="arquivo"
                    control={control}
                    extensoesAceitas={
                      CertificadoDigitalConstantes.ExtensoesAceitas
                    }
                    limparArquivosSelecionados={true}
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
