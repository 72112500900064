import { ReactNode } from "react";
import { ErroGenerico } from "../../../../features/comum/util/erros/comum.erros";
import { ErroCentroDeTrabalho } from "../../../../features/producao/centro-de-trabalho/utils/erros/centro-de-trabalho.erros";
import { ErroInsumoDaOrdemDeProducao } from "../../../../features/producao/insumo-da-ordem-de-producao/util/erros/insumo-da-ordem-de-producao.erros";
import { ErroOrdemDeProducao } from "../../../../features/producao/ordem-de-producao/utils/erros/ordem-de-producao.erros";
import { TipoNotificacao } from "../../../../utils/common/notificacoes-utils";

export * from "./erros.base";

export type ErroApi =
  | ErroGenerico
  | ErroCentroDeTrabalho
  | ErroOrdemDeProducao
  | ErroInsumoDaOrdemDeProducao;

export enum TipoErro {
  Toast,
  Popup,
  Confirmacao,
  // TODO: Accordion
}

export interface ConfiguracaoErroBase<T extends TipoErro = TipoErro> {
  tipo: T;
}

export interface ConfiguracaoErroToast
  extends ConfiguracaoErroBase<TipoErro.Toast> {
  tipoToast?: TipoNotificacao;
  formatador?: (erro: ErroApi) => string;
}

export interface ConfiguracaoErroPopup
  extends ConfiguracaoErroBase<TipoErro.Popup> {
  titulo?: string;
  formatador?: (erro: ErroApi) => ReactNode;
}

export interface ConfiguracaoErroConfimacao
  extends ConfiguracaoErroBase<TipoErro.Confirmacao> {
  titulo?: string;
  formatador?: (erro: ErroApi) => ReactNode;
}

export type ConfiguracaoErro =
  | ConfiguracaoErroToast
  | ConfiguracaoErroPopup
  | ConfiguracaoErroConfimacao;

export type ConfiguracoesErros<T extends ErroApi = ErroApi> = Partial<
  Record<T["codigoErro"], ConfiguracaoErro>
>;
