import SelectItem from "../../../../../models/shared/ui/select-item";

export enum EstadoDecodificado {
  Sugerida = "Sugerida",
  AProduzir = "A produzir",
  Fechada = "Fechada",
  Cancelada = "Cancelada",
  Suspensa = "Suspensa",
}

export enum OrdemDeProducaoEstado {
  Sugerida = 83,
  AProduzir = 70,
  Fechada = 67,
  Cancelada = 75,
  Suspensa = 66,
}

export class OrdemDeProducaoEstadoHelper {
  public static tipoDescricao: {
    [key in OrdemDeProducaoEstado]: string;
  } = {
    [OrdemDeProducaoEstado.Sugerida]: EstadoDecodificado.Sugerida,
    [OrdemDeProducaoEstado.AProduzir]: EstadoDecodificado.AProduzir,
    [OrdemDeProducaoEstado.Fechada]: EstadoDecodificado.Fechada,
    [OrdemDeProducaoEstado.Cancelada]: EstadoDecodificado.Cancelada,
    [OrdemDeProducaoEstado.Suspensa]: EstadoDecodificado.Suspensa,
  };

  public static getDescricao(tipo: OrdemDeProducaoEstado): string {
    return (
      OrdemDeProducaoEstadoHelper.tipoDescricao[tipo] ||
      "Descrição desconhecida"
    );
  }

  public static tipoSequenciamentoAsSelectItem: SelectItem[] = Object.keys(
    OrdemDeProducaoEstado
  )
    .map((x) => parseInt(x))
    .filter((x) => !isNaN(x))
    .map((valor) => ({
      valor,
      descricao: this.getDescricao(valor as OrdemDeProducaoEstado),
    }));
}
