import { useEffect, useState } from "react";
import GitInfo from "react-git-info/macro";
import { RemoverSessaoReduxELocalStorage } from "../../../utils/oauth/oauth-utils";

const ChaveVersaoLocalStorage = "versao";
const ChaveVersaoLoginLocalStorage = "login_versao";

export default function ChecarVersao() {
  const [checado, setChecado] = useState(false);
  const loginLegado = process.env.REACT_APP_LOGIN_LEGADO == "true";
  const usarHash =
    process.env.REACT_APP_USAR_ULTIMO_COMMIT_COMO_VERSAO_LOCALSTORAGE == "true";
  const versaoLogin = process.env.REACT_APP_LOGIN_VERSAO;

  useEffect(() => {
    if (!checado && !loginLegado) {
      setChecado(true);
      const gitInfo = GitInfo();
      const versao = localStorage.getItem(ChaveVersaoLocalStorage);
      const versaoAtual = usarHash ? gitInfo.commit.hash : gitInfo.branch;
      const versaoLoginAtual = localStorage.getItem(
        ChaveVersaoLoginLocalStorage
      );

      if (!versaoAtual || !versaoLoginAtual) {
        return;
      }

      if (!versao && versaoAtual) {
        localStorage.setItem(ChaveVersaoLocalStorage, versaoAtual);
      } else if (versao != versaoAtual || versaoLogin != versaoLoginAtual) {
        localStorage.setItem(ChaveVersaoLocalStorage, versaoAtual ?? "");
        localStorage.setItem(ChaveVersaoLoginLocalStorage, versaoLogin ?? "");
        RemoverSessaoReduxELocalStorage();
      }
    }
  }, []);

  return <></>;
}
