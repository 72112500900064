import { createContext } from "react";
import { ItemContextMenuMxp } from "../../../utils/context-menu/context-menu-utils";

export class ItensMenuEdicaoOrdemDeProducao {
  itens: ItemContextMenuMxp[];

  constructor() {
    this.itens = [];
  }
}

interface ContextoMenuEdicaoOrdemDeProducaoProps {
  menu: ItensMenuEdicaoOrdemDeProducao;
  setMenu: (itens: ItensMenuEdicaoOrdemDeProducao) => void;
}

const ContextoMenuEdicaoOrdemDeProducao =
  createContext<ContextoMenuEdicaoOrdemDeProducaoProps>(
    {} as ContextoMenuEdicaoOrdemDeProducaoProps
  );

export default ContextoMenuEdicaoOrdemDeProducao;
