import { DataSourceOpcoesBuilder } from "../../../../../utils/grid/data-source-factory";
import { ItemGridModel } from "../../models/item.api";
import { Estado, ProcedenciaDecodificadaTipo } from "../../models/item.enums";

export default class ItensFiltrosHelpers {
  static readonly FiltrarAtivosSemProcedenciaAgregadaOuManutencao: DataSourceOpcoesBuilder<ItemGridModel> =
    {
      camposFiltro: [
        { campo: "estado", operador: "=", valor: Estado.Ativo },
        {
          campo: "procedencia",
          operador: "<>",
          valor: ProcedenciaDecodificadaTipo.Agregado,
        },
        {
          campo: "procedencia",
          operador: "<>",
          valor: ProcedenciaDecodificadaTipo.ServicoManutencao,
        },
      ],
    };
}
