import { ValueChangedEvent } from "devextreme/ui/check_box";
import { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  FormCheckBox,
  FormDateBox,
  FormSelectBoxLazy,
  FormSelectBoxLazyMxp,
  FormTextBox,
} from "../../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../../components/formularios/selectbox-lazy-mxp";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import { GridEmpresa } from "../../../../../../parts/empresas/empresa/grids";
import { NomesEndpoints } from "../../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../../services/comum/serviceBase";
import APINumeroDeSeriePrefixo from "../../../../../../services/item/numero-de-serie-prefixo/numero-de-serie-prefixo.service";
import FiltrosGridItem from "../../../../../../utils/filtros-grid/itens/item/filtros-grid-item";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../../utils/formatadores/formatador-de-selectbox";
import DataSourceFactory from "../../../../../../utils/grid/data-source-factory";
import GridItem from "../../../../../itens/item/componentes/grid";
import { ItemGridModel } from "../../../../../itens/item/models/item.api";
import {
  Estado,
  EstocagemTipo,
} from "../../../../../itens/item/models/item.enums";
import { LoteRequestDTO } from "../../../models/lote.api";
import LoteContantes from "../../../models/lote.constantes";
import { UtilizaPrefixoCheckboxContainer } from "../../utiliza-prefixo-checkbox-container/styles";

interface LoteAbaDadosGerais {
  loteDataRequerido: boolean | undefined;
  isRegistroEmEdicao: boolean;
  controleLoteFabricacao: boolean | undefined;
  hookForms: UseFormReturn<LoteRequestDTO>;
}

function carregaItens() {
  return DataSourceFactory.CriarParaSelectBoxLazy<ItemGridModel>(
    `${NomesEndpoints.Item}/grid`,
    {
      camposRetorno: [
        "id",
        "codigo",
        "descricao",
        "estocagemPor",
        "utilizaPrefixoESufixo",
      ],
      camposFiltro: [
        { campo: "estocagemPor", operador: "<>", valor: EstocagemTipo.Codigo },
        { campo: "estocagemPor", operador: "<>", valor: EstocagemTipo.Interno },
        { campo: "estado", operador: "<>", valor: Estado.Inativo },
      ],
      camposOrdenacao: [
        {
          campo: "codigo",
          desc: false,
        },
      ],
    }
  );
}

const exibicaoEBuscaItens = assertConfiguracaoExibicaoEBuscaType<ItemGridModel>(
  {
    nomeCampoChave: "id",
    expressaoDeBusca: ["codigo", "descricao"],
    nomeCampoExibicao: (c) => {
      if (c) {
        return c.descricao == null
          ? `${c.codigo}`
          : `${c.codigo} (${c.descricao})`;
      }

      return "";
    },
  }
);

const fabricantes = APIBase.getDataSourceSelectBoxLazy(
  {
    camposRetorno: ["Id", "Apelido", "RazaoSocial", "CnpjCpfCodigo"],
    camposOrdenacao: [
      {
        nomeCampo: "Apelido",
        desc: false,
      },
    ],
  },
  NomesEndpoints.Empresa
);

const fabricantesExprecaoBusca = ["Apelido", "RazaoSocial"];
const exibeFabricante = (c: any) => {
  if (c) {
    return c.CnpjCpfCodigo
      ? FormataDescricao(
          FormatadoresSelectBox.CodigoDescricaoParenteses,
          c.Apelido,
          c.CnpjCpfCodigo
        )
      : c.Apelido;
  }

  return "";
};

export default function LoteAbaDadosGerais(props: LoteAbaDadosGerais) {
  const { getValues, watch, setValue, trigger, control } = props.hookForms;

  const [loteDoFabricanteOuNumeroDeSerie, setLoteDoFabricanteOuNumeroDeSerie] =
    useState("");

  const [utilizaPrefixoDesabilitado, setUtilizaPrefixoDesabilitado] =
    useState(false);

  const [itens] = useState(carregaItens);

  const itemIdInicialDoLoteEmEdicao = props.isRegistroEmEdicao
    ? (getValues().itemId as number)
    : undefined;

  async function utilizaPrefixoCheckBoxHandler(e: ValueChangedEvent) {
    if (!e.event) {
      return;
    }

    const model = getValues();

    if (model.itemId && e.value == true) {
      const forcarObterNumeroSerie = true;
      ajustaLoteDoFabricanteOuNumeroDeSerieComPrefixo(
        model.itemId,
        forcarObterNumeroSerie
      );
    }

    const loteDoFabricanteOuNumeroDeSerieTexto =
      loteDoFabricanteOuNumeroDeSerie ||
      (getValues().loteDoFabricanteOuNumeroDeSerie as string);

    setValue(
      "loteDoFabricanteOuNumeroDeSerie",
      e.value ? loteDoFabricanteOuNumeroDeSerieTexto : ""
    );
  }

  const onItemChanged = useCallback(async (e: any) => {
    const itemSelecionado = e.selectedItem as ItemGridModel;

    if (props.isRegistroEmEdicao) {
      habilitaDesabilitaUtilizaPrefixo(itemSelecionado);
      return;
    }

    if (itemSelecionado.utilizaPrefixoESufixo) {
      ajustaLoteDoFabricanteOuNumeroDeSerieComPrefixo(itemSelecionado.id);
    } else {
      setValue("loteDoFabricanteOuNumeroDeSerie", "");
    }

    inicializarCheckboxUtilizaPrefixoConformeItemSelecionado(itemSelecionado);
  }, []);

  function inicializarCheckboxUtilizaPrefixoConformeItemSelecionado(
    itemSelecionado: ItemGridModel
  ) {
    setValue("utilizaPrefixo", itemSelecionado.utilizaPrefixoESufixo);

    habilitaDesabilitaUtilizaPrefixo(itemSelecionado);

    trigger("utilizaPrefixo");
  }

  function habilitaDesabilitaUtilizaPrefixo(itemSelecionado: ItemGridModel) {
    if (!itemSelecionado.utilizaPrefixoESufixo) {
      setUtilizaPrefixoDesabilitado(true);
    } else {
      setUtilizaPrefixoDesabilitado(false);
    }
  }

  async function ajustaLoteDoFabricanteOuNumeroDeSerieComPrefixo(
    itemId: number,
    forcar: boolean = false
  ) {
    if (itemId == itemIdInicialDoLoteEmEdicao && !forcar) {
      setLoteDoFabricanteOuNumeroDeSerie(
        getValues().loteDoFabricanteOuNumeroDeSerie as string
      );

      return;
    }

    if (itemId > 0) {
      const numeroDeSerieOuLotePrefixo =
        await APINumeroDeSeriePrefixo.getNumeroSerieOuLotePrefixoAsync(itemId);

      setLoteDoFabricanteOuNumeroDeSerie(numeroDeSerieOuLotePrefixo);

      if (watch("utilizaPrefixo")) {
        setValue("loteDoFabricanteOuNumeroDeSerie", numeroDeSerieOuLotePrefixo);
        trigger("loteDoFabricanteOuNumeroDeSerie");
      }
    }
  }

  return (
    <TabContainer>
      <Linha>
        <Coluna md={6}>
          <FormSelectBoxLazyMxp
            name="itemId"
            titulo="Item"
            control={control}
            requerido
            configuracoesExibicaoEBusca={exibicaoEBuscaItens}
            dataSource={itens}
            seletorConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar item",
              componenteGrid: (
                <GridItem
                  filtrosNoCliente={FiltrosGridItem.itemLoteOuSerieAtivo}
                />
              ),
            }}
            labelSemDados="Sem dados"
            onSelectionChanged={onItemChanged}
            somenteLeitura={props.isRegistroEmEdicao}
          />
        </Coluna>
        <Coluna md={6}>
          <FormSelectBoxLazy
            name="fabricanteId"
            titulo="Fabricante"
            control={control}
            requerido
            nomeCampoChave="Id"
            nomeCampoExibicao={exibeFabricante}
            expressaoDeBusca={fabricantesExprecaoBusca}
            dataSource={fabricantes}
            lupaConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar fabricante",
              componente: (r) => <GridEmpresa ocultarBotaoNovo ref={r} />,
            }}
            labelSemDados="Sem dados"
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <FormTextBox
            name="loteDoFabricanteOuNumeroDeSerie"
            titulo="Lote do fabricante / número de série"
            control={control}
            requerido
            tamanhoMaximo={
              LoteContantes.LoteDoFabricanteOuNumeroDeSerieMaxLength
            }
            somenteLeitura={watch("utilizaPrefixo")}
          />
        </Coluna>
        <Coluna md={6} centralizar>
          <UtilizaPrefixoCheckboxContainer>
            <FormCheckBox
              name="utilizaPrefixo"
              titulo="Utilizar prefixo e sufixo do item"
              control={control}
              requerido
              onValueChanged={utilizaPrefixoCheckBoxHandler}
              desabilitado={utilizaPrefixoDesabilitado}
            />
          </UtilizaPrefixoCheckboxContainer>
        </Coluna>
      </Linha>
      {props.controleLoteFabricacao && (
        <Linha>
          <Coluna md={3}>
            <FormDateBox
              name="fabricacaoData"
              titulo="Data de fabricação"
              control={control}
              exibirBotaoLimpar
              tipo="date"
              aceitaValorCustomizado={true}
              formatoExibicao="dd/MM/yyyy"
              aceitaDigitacaoComMascara={true}
              requerido={props.loteDataRequerido}
            />
          </Coluna>
          <Coluna md={3}>
            <FormDateBox
              name="vencimentoData"
              titulo="Data de vencimento"
              control={control}
              exibirBotaoLimpar
              tipo="date"
              aceitaValorCustomizado={true}
              formatoExibicao="dd/MM/yyyy"
              aceitaDigitacaoComMascara={true}
              requerido={props.loteDataRequerido}
            />
          </Coluna>
          <Coluna md={3}>
            <FormDateBox
              name="reanaliseData"
              titulo="Data de reanálise"
              control={control}
              exibirBotaoLimpar
              tipo="date"
              aceitaValorCustomizado={true}
              formatoExibicao="dd/MM/yyyy"
              aceitaDigitacaoComMascara={true}
            />
          </Coluna>
        </Linha>
      )}
    </TabContainer>
  );
}
