import {
  ConfiguracoesErros,
  TipoErro,
} from "../../../../models/api/comum/erros";
import { ErroGenerico } from "./comum.erros";

export const configuracoesErrosGenericos = Object.freeze<
  ConfiguracoesErros<ErroGenerico>
>({
  "MXP-404": {
    tipo: TipoErro.Toast,
    formatador(erro) {
      // Fazer sanity check (também ajuda pq o intellisense automaticamente
      // seta o tipo do erro para RegistroNaoEncontrado).
      if (erro.codigoErro !== "MXP-404") return erro.mensagem;

      let nomeAmigavel: string;
      switch (erro.dadosExtras.nomeEntidade) {
        default:
          if (erro.dadosExtras.nomeEntidade.startsWith("Maxiprod.")) {
            // Converte de PascalCase para uma string "normal" (separada por espaços)
            nomeAmigavel = erro.dadosExtras.nomeEntidade
              .split(".")
              .pop()!
              .split(/\.?(?=[A-Z])/)
              .join(" ");
          } else {
            nomeAmigavel = erro.dadosExtras.nomeEntidade;
          }
          nomeAmigavel =
            nomeAmigavel[0].toLocaleUpperCase() +
            nomeAmigavel.substring(1).toLocaleLowerCase();
          break;
      }

      return `${nomeAmigavel} não encontrado. Por favor atualize a página e tente novamente.`;
    },
  },
});
