import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CheckBox, TabPanel } from "devextreme-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import CamposOcultosDosAnexos from "../../../../../components/arquivo/campos-hidden";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormCheckBox,
  FormDateBox,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazyMxp,
} from "../../../../../components/formularios";
import { ItemSelectionChangedType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import { FixarContainer } from "../../../../../components/layout/fixar-container/styles";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { ResponseBase } from "../../../../../models/api/comum/response-base";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../models/shared/ui/select-item";
import ShowHide, {
  ShowHideItem,
} from "../../../../../parts/layout/showhide/showhide";
import { RenderOnDemandPromisse } from "../../../../../parts/utils/load-on-demand";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import {
  quantidadeValorMaximo,
  quantidadeValorMinimo,
} from "../../../../../utils/common/constantes";
import { verificarValidacaoDasAbasComRedirecionamento } from "../../../../../utils/common/form-utils";
import NomesModais from "../../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import FiltrosGridItem from "../../../../../utils/filtros-grid/itens/item/filtros-grid-item";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import { DataSourceOpcoesBuilder } from "../../../../../utils/grid/data-source-factory";
import ComboItemMxp from "../../../../itens/item/componentes/select-box-lazy";
import { ItemGridModel } from "../../../../itens/item/models/item.api";
import {
  Estado,
  EstocagemTipo,
  ProcedenciaDecodificadaTipo,
} from "../../../../itens/item/models/item.enums";
import GridEstadoConfiguravelDaOrdemDeProducao from "../../../estado-configuravel-da-ordem-de-producao/componentes/grid";
import EstadoConfiguravelDaOrdemDeProducaoService from "../../../estado-configuravel-da-ordem-de-producao/services/estado-configuravel-da-ordem-de-producao.service";
import FiltrosGridEstadoConfiguravelDaOrdemDeProducao from "../../../estado-configuravel-da-ordem-de-producao/utils/filtros-grid/filtros";
import {
  OrdemDeProducaoRequest,
  OrdemDeProducaoResponse,
  quantidadeDePerdaPrevistaMaxima,
  quantidadeDePerdaPrevistaMinima,
} from "../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";
import {
  OrdemDeProducaoEstado,
  OrdemDeProducaoEstadoHelper,
} from "../../utils/enums/ordem-de-producao-enums";
import OrdemDeProducaoAbaAnexos from "../abas-formulario/abas/anexos";
import OrdemDeProducaoAbaConclusoes from "../abas-formulario/abas/conclusoes-ordem-de-producao";
import OrdemDeProducaoAbaInsumos from "../abas-formulario/abas/insumos";
import {
  dicionarioDosIndicesDasAbasPorCampo,
  OrdemDeProducaoAbasIndices,
} from "../abas-formulario/abas/ordem-de-producao-abas";
import OrdemDeProducaoAbaOutrosDados from "../abas-formulario/abas/outros-dados";
import OrdemDeProducaoAbaReservas from "../abas-formulario/abas/reservas";
import OrdemDeProducaoAbaRetiradaDeMaterial from "../abas-formulario/abas/retirada-de-material";
import OrdemDeProducaoAbaTarefasEAnotacoes from "../abas-formulario/abas/tarefas-e-anotacoes";
import OrdemDeProducaoAbaVinculos from "../abas-formulario/abas/vinculos";
import FormConcluirOrdemDeProducao from "./concluir-ordem-de-producao";
import { EstadoConfiguravelContainer, EstadoContainer } from "./style";

const novoRegistro: OrdemDeProducaoRequest = {
  id: 0,
  numero: NaN,
  itemId: null,
  quantidade: NaN,
  fixarQuantidade: true,
  quantidadeDePerdaPrevista: NaN,
  necessidadeData: new Date(),
  fixarNecessidadeData: true,
  estado: OrdemDeProducaoEstado.AProduzir,
  estadoConfiguravelId: null,
  observacoes: "",
  arquivos: [],
};

const filtrosPadroesParaItem: DataSourceOpcoesBuilder<ItemGridModel> = {
  camposRetorno: [
    "id",
    "descricao",
    "codigo",
    "estocagemPor",
    "perdaPrevista",
    "usaQuantidadeInteira",
  ],
  camposFiltro: [
    { campo: "estado", operador: "<>", valor: Estado.Inativo },
    { campo: "estado", operador: "<>", valor: Estado.Digitacao },
    {
      campo: "procedencia",
      operador: "<>",
      valor: ProcedenciaDecodificadaTipo.Conjunto,
    },
    {
      campo: "procedencia",
      operador: "<>",
      valor: ProcedenciaDecodificadaTipo.Alternativo,
    },
    {
      campo: "procedencia",
      operador: "<>",
      valor: ProcedenciaDecodificadaTipo.Agregado,
    },
  ],
};

let dadosAuditoria: AuditavelDTO | undefined = undefined;

const ordemDeProducaoService = new OrdemDeProducaoService();
const estadoConfiguravelDaOrdemDeProducaoService =
  new EstadoConfiguravelDaOrdemDeProducaoService();

const [estadosConfiguraveis, estadosConfiguraveisExibicaoEBusca] =
  estadoConfiguravelDaOrdemDeProducaoService.GetDadosSelectBox();

const estadoOrdemDeProducaoAsSelectItem: SelectItem[] =
  OrdemDeProducaoEstadoHelper.tipoSequenciamentoAsSelectItem;

export default function FormOrdemDeProducao(props: IFormularioEditavelBase) {
  const [carregando, setCarregando] = useState(false);
  const [idConcluirOp, setIdConcluirOp] = useState(NaN);
  const [estadoDaOp, setEstadoDaOp] = useState<OrdemDeProducaoEstado>();
  const [itemUsaQuantidadeInteira, setitemUsaQuantidadeInteira] =
    useState(false);
  const [ordemDeProducao, setOrdemDeProducao] = useState<
    OrdemDeProducaoResponse | undefined
  >(undefined);
  const [promessa, setPromessa] = useState<Promise<any> | undefined>(undefined);
  const [usaAba, setUsaAba] = useState(false);

  const tabPanelRef = useRef<TabPanel>(null);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    itemId: yup.number().required().moreThan(-1).integer(),
    necessidadeData: yup.date().required(),
    quantidade: yup
      .number()
      .required()
      .moreThan(quantidadeValorMinimo.toNumber())
      .lessThan(quantidadeValorMaximo.toNumber())
      .test(
        "is-integer",
        "Valor deve ser um número inteiro.",
        function (value) {
          return itemUsaQuantidadeInteira ? Number.isInteger(value) : true;
        }
      ),
    quantidadeDePerdaPrevista: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .notRequired()
      .min(quantidadeDePerdaPrevistaMinima.toNumber())
      .lessThan(quantidadeDePerdaPrevistaMaxima.toNumber())
      .test(
        "is-integer",
        "Valor deve ser um número inteiro.",
        function (value) {
          return itemUsaQuantidadeInteira ? Number.isInteger(value) : true;
        }
      ),
    fixarQuantidade: yup.bool().required(),
    fixarNecessidadeData: yup.bool().required(),
    estadoConfiguravelId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .notRequired()
      .moreThan(-1)
      .integer(),
  });

  const isRegistroEmEdicao = props.idRegistroEmEdicao > 0;

  const hookForms = useForm<OrdemDeProducaoRequest>({
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, getValues, reset, register, setValue, watch } =
    hookForms;

  const fieldArrayAnexos = useFieldArray({
    control,
    name: "arquivos",
  });

  useEffect(() => {
    limparTela();
  }, []);

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();

    return () => limparTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const promessaOp =
        ordemDeProducaoService.ObterPorIdComDadosAuditoria<OrdemDeProducaoResponse>(
          props.idRegistroEmEdicao
        );
      setPromessa(promessaOp);
      const [resposta, anexos] = await Promise.all([
        promessaOp,
        ordemDeProducaoService.obterAnexos(props.idRegistroEmEdicao),
      ]);

      checarResponse(resposta);
      resposta.model.arquivos = anexos;

      setOrdemDeProducao(resposta.model);
      reset(resposta.model);
      dadosAuditoria = resposta.model;
      setEstadoDaOp(resposta.model.estado);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();

    try {
      let resposta: ResponseBase;

      if (isRegistroEmEdicao) {
        const [respostaApi, respostaAnexo] = await Promise.all([
          ordemDeProducaoService.Atualizar(model),
          ordemDeProducaoService.anexoAlterarLista(
            model.id,
            model.arquivos ?? []
          ),
        ]);

        resposta = respostaApi;

        checarResponse(respostaAnexo);
      } else {
        const respostaInicial =
          await ordemDeProducaoService.CadastrarComRetorno<
            OrdemDeProducaoRequest,
            OrdemDeProducaoResponse
          >(model as OrdemDeProducaoRequest);

        if (respostaInicial.sucesso) {
          const id = respostaInicial.model.id;
          const respostaAnexo = await ordemDeProducaoService.anexoAlterarLista(
            id,
            model.arquivos ?? []
          );
          checarResponse(respostaAnexo);
        }

        resposta = {
          sucesso: respostaInicial.sucesso,
          mensagem: respostaInicial.mensagem,
          erros: respostaInicial.erros,
        };
      }

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
      fechar(ResultadoAcaoFormulario.AcaoConcluida);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function limparTela() {
    setPromessa(undefined);
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  async function handleSubmitError(errors: object) {
    verificarValidacaoDasAbasComRedirecionamento<OrdemDeProducaoRequest>(
      tabPanelRef,
      errors,
      dicionarioDosIndicesDasAbasPorCampo,
      OrdemDeProducaoAbasIndices.Insumos
    );
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function funcaoParaBaixarAnexo(idAnexo: number) {
    return ordemDeProducaoService.anexoObterDadosParaDownload(
      getValues().id,
      idAnexo
    );
  }

  async function onItemChanged(e: ItemSelectionChangedType<ItemGridModel>) {
    //Se for formulário de edição, nao precisa buscar a quantidade de perda prevista
    //Pois ele já foi preenchido e não é possível alterar um item na edição de uma OP.
    if (isRegistroEmEdicao) {
      return;
    }

    setValue("quantidadeDePerdaPrevista", e.selectedItem?.perdaPrevista ?? NaN);
    setValue("estocagemPor", e.selectedItem?.estocagemPor ?? undefined);
    setitemUsaQuantidadeInteira(e.selectedItem?.usaQuantidadeInteira ?? false);

    if (e.selectedItem?.estocagemPor == EstocagemTipo.Serie) {
      setValue("fixarQuantidade", true);
    }
  }

  const fecharConcluirOp = useCallback(
    async (resultado: ResultadoAcaoFormulario) => {
      setIdConcluirOp(NaN);
      props.handleCallback(resultado);
    },
    [props.handleCallback]
  );

  const ocultarConcluirOp = useCallback(() => {
    setIdConcluirOp(NaN);
  }, []);

  const mostrarConcluirOp = useCallback(() => {
    setIdConcluirOp(props.idRegistroEmEdicao);
  }, [props.idRegistroEmEdicao]);

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id={"edit-form-ordem-de-producao"}>
            <input type="hidden" {...register("id")} defaultValue={0} />
            <CamposOcultosDosAnexos
              register={register}
              arquivos={fieldArrayAnexos.fields}
              key={"campos-ocultos-anexo"}
            />
            <ShowHide
              id="accordion-container"
              usarAba={usaAba}
              height={"75vh"}
              idRegistroEmEdicao={getValues("numero")}
            >
              <ShowHideItem text="Ordem de produção" aberto>
                <div style={{ padding: "0px" }}>
                  <Linha>
                    <Coluna md={2}>
                      <FormNumberBox
                        name={"numero"}
                        titulo="Número"
                        control={control}
                        somenteLeitura
                      />
                    </Coluna>
                    <Coluna md={6}>
                      <ComboItemMxp
                        name={"itemId"}
                        titulo="Item"
                        control={control}
                        somenteLeitura={isRegistroEmEdicao}
                        dataSourceOpcoes={filtrosPadroesParaItem}
                        filtrosNoCliente={FiltrosGridItem.itemAtivo}
                        filtrosNoServidor={
                          FiltrosGridItem.itemGridDaOrdemDeProducao
                        }
                        onSelectionChanged={onItemChanged}
                      />
                    </Coluna>
                    <Coluna md={2} offset={2}>
                      <EstadoContainer>
                        <FormSelectBox
                          name={"estado"}
                          titulo="Estado"
                          control={control}
                          somenteLeitura
                          dataSource={estadoOrdemDeProducaoAsSelectItem}
                          habilitaBusca
                          tipoBusca="contains"
                        />
                      </EstadoContainer>
                    </Coluna>
                  </Linha>
                  <Linha>
                    <Coluna md={2}>
                      <FormNumberBox
                        name={"quantidade"}
                        titulo="Quantidade"
                        formato={obterFormatStringNumero(5)}
                        control={control}
                        minimo={quantidadeValorMinimo.toNumber()}
                        maximo={quantidadeValorMaximo.toNumber()}
                        requerido
                      />
                    </Coluna>
                    <Coluna md={1} centralizar>
                      <FixarContainer>
                        <FormCheckBox
                          name={"fixarQuantidade"}
                          titulo="Fixar"
                          desabilitado={
                            watch("estocagemPor") == EstocagemTipo.Serie
                          }
                          control={control}
                        />
                      </FixarContainer>
                    </Coluna>
                    <Coluna md={2}>
                      <FormDateBox
                        name={"necessidadeData"}
                        titulo="Necessidade"
                        control={control}
                        exibirBotaoLimpar
                        tipo="date"
                        aceitaValorCustomizado={true}
                        formatoExibicao="dd/MM/yyyy"
                        aceitaDigitacaoComMascara={true}
                        requerido
                      />
                    </Coluna>
                    <Coluna md={1} centralizar>
                      <FixarContainer>
                        <FormCheckBox
                          name={"fixarNecessidadeData"}
                          titulo="Fixar"
                          control={control}
                        />
                      </FixarContainer>
                    </Coluna>
                    <Coluna md={2} offset={4}>
                      <EstadoConfiguravelContainer>
                        <FormSelectBoxLazyMxp
                          name={"estadoConfiguravelId"}
                          titulo="Estado configurável"
                          control={control}
                          configuracoesExibicaoEBusca={
                            estadosConfiguraveisExibicaoEBusca
                          }
                          dataSource={estadosConfiguraveis}
                          seletorConfig={{
                            modo: "selecaoUnica",
                            titulo:
                              "Selecionar estado configurável de ordem de produção",
                            componenteGrid: (
                              <GridEstadoConfiguravelDaOrdemDeProducao
                                filtrosNoCliente={
                                  FiltrosGridEstadoConfiguravelDaOrdemDeProducao.apenasAtivos
                                }
                              />
                            ),
                          }}
                          labelSemDados="Sem dados"
                        />
                      </EstadoConfiguravelContainer>
                    </Coluna>
                  </Linha>
                </div>
              </ShowHideItem>
              <ShowHideItem
                text="Insumos"
                aberto
                style={{ minHeight: 300, maxHeight: 800 }}
              >
                {isRegistroEmEdicao ? (
                  <RenderOnDemandPromisse promessa={promessa}>
                    <OrdemDeProducaoAbaInsumos
                      idRegistro={props.idRegistroEmEdicao}
                      ordemDeProducaoEstado={estadoDaOp}
                      control={control}
                    />
                  </RenderOnDemandPromisse>
                ) : (
                  <p>
                    <b>
                      Para visualizar os insumos, é necessário salvar a ordem de
                      produção.
                    </b>
                  </p>
                )}
              </ShowHideItem>
              <ShowHideItem text="Reservas" carregarApenasQuandoAberto>
                {isRegistroEmEdicao ? (
                  <RenderOnDemandPromisse promessa={promessa}>
                    <OrdemDeProducaoAbaReservas
                      ordemDeProducaoId={ordemDeProducao?.id ?? 0}
                      ordemDeProducaoCodigo={ordemDeProducao?.numero}
                      ordemDeProducaoIdItem={ordemDeProducao?.itemId ?? 0}
                      ordemDeProducaoDescricaoItem={
                        ordemDeProducao?.itemDescricao ?? ""
                      }
                      ordemDeProducaoEhSugerida={
                        ordemDeProducao?.estado ==
                        OrdemDeProducaoEstado.Sugerida
                      }
                    />
                  </RenderOnDemandPromisse>
                ) : (
                  <p>
                    <b>Para visualizar as reservas, é necessário criar a OP.</b>
                  </p>
                )}
              </ShowHideItem>
              <ShowHideItem text="Vínculos" carregarApenasQuandoAberto>
                {isRegistroEmEdicao ? (
                  <RenderOnDemandPromisse promessa={promessa}>
                    <OrdemDeProducaoAbaVinculos
                      idRegistro={props.idRegistroEmEdicao}
                      control={control}
                    />
                  </RenderOnDemandPromisse>
                ) : (
                  <p>
                    <b>Para visualizar as reservas, é necessário criar a OP.</b>
                  </p>
                )}
              </ShowHideItem>
              <ShowHideItem text="Aparas" carregarApenasQuandoAberto>
                <p>Ainda será implementado</p>
              </ShowHideItem>
              <ShowHideItem
                text="Retirada de materiais"
                carregarApenasQuandoAberto
              >
                {isRegistroEmEdicao ? (
                  <RenderOnDemandPromisse promessa={promessa}>
                    <OrdemDeProducaoAbaRetiradaDeMaterial
                      ordemDeProducaoId={props.idRegistroEmEdicao}
                      ordemDeProducaoEstado={estadoDaOp}
                    />
                  </RenderOnDemandPromisse>
                ) : (
                  <p>
                    <b>
                      Para visualizar as retiradas de materiais, é necessário
                      criar a OP.
                    </b>
                  </p>
                )}
              </ShowHideItem>
              <ShowHideItem text="Conclusões" carregarApenasQuandoAberto>
                {isRegistroEmEdicao ? (
                  <RenderOnDemandPromisse promessa={promessa}>
                    <OrdemDeProducaoAbaConclusoes
                      ordemDeProducaoId={props.idRegistroEmEdicao}
                    />
                  </RenderOnDemandPromisse>
                ) : (
                  <p>
                    <b>
                      Para visualizar as conclusões da ordem de produção, é
                      necessário criar a OP.
                    </b>
                  </p>
                )}
              </ShowHideItem>
              <ShowHideItem text="Outros dados">
                <OrdemDeProducaoAbaOutrosDados
                  idRegistro={props.idRegistroEmEdicao}
                  control={control}
                  watch={watch}
                />
              </ShowHideItem>
              <ShowHideItem text="Anexos">
                <RenderOnDemandPromisse promessa={promessa}>
                  <OrdemDeProducaoAbaAnexos
                    operacoesFieldArray={fieldArrayAnexos}
                    idRegistro={props.idRegistroEmEdicao}
                    getValues={getValues}
                    funcaoParaBaixarAnexo={funcaoParaBaixarAnexo}
                  />
                </RenderOnDemandPromisse>
              </ShowHideItem>
              <ShowHideItem
                text="Tarefas e anotações"
                carregarApenasQuandoAberto
              >
                {isRegistroEmEdicao ? (
                  <OrdemDeProducaoAbaTarefasEAnotacoes
                    ordemDeProducaoId={ordemDeProducao?.id ?? 0}
                  />
                ) : (
                  <p>
                    <b>
                      Para visualizar as tarefas e anotações, é necessário criar
                      a OP.
                    </b>
                  </p>
                )}
              </ShowHideItem>
            </ShowHide>
          </ProvedorAjuda>
        </FormMxp>

        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp
            handleClick={handleSubmit(handleSalvar, handleSubmitError)}
          />
          <BotaoCancelarMxp handleClick={handleCancelar} />
          <Button
            key="btn-concluir-op"
            type="normal"
            text="Concluir"
            visible={
              isRegistroEmEdicao &&
              watch("estado") == OrdemDeProducaoEstado.AProduzir
            }
            onClick={mostrarConcluirOp}
          />
          <CheckBox
            value={usaAba}
            text="Usar aba"
            onValueChanged={(e) => setUsaAba(e.value)}
          />
        </ToolbarMxp>
      </ContainerFormMxp>

      <ModalMxp
        titulo={`Concluir ${NomesModais.ordemDeProducao}`}
        visivel={!Number.isNaN(idConcluirOp)}
        handleFechar={ocultarConcluirOp}
        largura={"max(50vw, 800px)"}
        altura={"auto"}
      >
        <FormConcluirOrdemDeProducao
          idRegistroEmEdicao={idConcluirOp}
          handleCallback={fecharConcluirOp}
        />
      </ModalMxp>
    </>
  );
}
