import {
  DataSourceFiltragem,
  DataSourceRawFilter,
} from "../../../../utils/grid/data-source-factory";
import { PedidoDeVendaGridModel } from "../models/pedidos-de-venda.api";
import { PedidoDeVendaEstadoDecodificado } from "../models/pedidos-de-venda.enum";

export class FiltrosGridPedidoVenda {
  static readonly pedidoNaoFinalizado: DataSourceFiltragem<PedidoDeVendaGridModel>[] =
    [
      {
        campo: "estadoDecodificado",
        operador: "<>",
        valor: PedidoDeVendaEstadoDecodificado.Faturado,
      },
      {
        campo: "estadoDecodificado",
        operador: "<>",
        valor: PedidoDeVendaEstadoDecodificado.Cancelado,
      },
    ];

  static readonly pedidoNaoFinalizadoRaw: DataSourceRawFilter<PedidoDeVendaGridModel> =
    [
      ["estadoDecodificado", "<>", PedidoDeVendaEstadoDecodificado.Faturado],
      "and",
      ["estadoDecodificado", "<>", PedidoDeVendaEstadoDecodificado.Cancelado],
    ];
}
