import { UseFormReturn } from "react-hook-form";
import * as yup from "yup";
import {
  FormDateBox,
  FormSelectBox,
  FormSelectBoxLazyMxp,
  FormTextBox,
} from "../../../../../components/formularios";
import FormTextArea from "../../../../../components/formularios/textarea";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import SelectItem from "../../../../../models/shared/ui/select-item";
import { EstocagemTipo } from "../../../../itens/item/models/item.enums";
import ComboEmpresaMxp from "../../../../sistema/empresa/form/select-box-lazy";
import { DadosRastreabilidadeLoteRequest } from "../../models/lote.api";
import LoteServico from "../../servicos/lote.servico";
import GridLote from "../grid";

const apiLote = new LoteServico();

export const novaRastreabilidadeLote: DadosRastreabilidadeLoteRequest = {
  criarNovo: true,
  gerarLoteManualmente: true,
  id: null,
  observacoes: "",
  observacoesInternas: "",
  fabricanteId: null,
  fabricacaoData: null,
  vencimentoData: null,
  reanaliseData: null,
  loteDoFabricanteOuNumeroDeSerie: "",
};

export const yupRastreabilidadeLote = (
  estocagemTipo: () => EstocagemTipo | null | undefined
) =>
  yup
    .object()
    .notRequired()
    .shape({
      criarNovo: yup.boolean().required(),
      gerarLoteManualmente: yup.boolean().required(),
      loteDoFabricanteOuNumeroDeSerie: yup
        .string()
        .test(
          "loteDoFabricanteOuNumeroDeSerie-is-required-if-criarNovo",
          "Necessário informar o lote",
          function (value) {
            const { criarNovo, gerarLoteManualmente } = this.parent;
            if (
              criarNovo &&
              gerarLoteManualmente &&
              estocagemTipo() === EstocagemTipo.LoteFabricante
            ) {
              return value !== undefined && value !== null && value.length > 0;
            }
            return true;
          }
        ),
      id: yup
        .number()
        .notRequired()
        .test(
          "idlote-is-required-if-criarNovo",
          "Necessário informar o lote",
          function (value) {
            const { criarNovo, gerarLoteManualmente } = this.parent;
            if (
              (!criarNovo || !gerarLoteManualmente) &&
              estocagemTipo() === EstocagemTipo.LoteFabricante
            ) {
              return value !== null && value !== undefined && value > 0;
            }
            return true;
          }
        ),
      fabricanteId: yup
        .number()
        .notRequired()
        .test(
          "fabricanteId-is-required-if-criarNovo",
          "Necessário informar o fabricante",
          function (value) {
            const { criarNovo } = this.parent;
            if (
              !criarNovo &&
              estocagemTipo() === EstocagemTipo.LoteFabricante
            ) {
              return value !== null && value !== undefined && value > 0;
            }
            return true;
          }
        ),
    });

export interface RastreabilidadePorLoteRequest {
  rastreabilidadeLote: DadosRastreabilidadeLoteRequest;
}

interface RastreabilidadePorLoteProps<T extends RastreabilidadePorLoteRequest> {
  hookForms: UseFormReturn<T>;
  itemUtilizaPrefixo: boolean;
}

const datasourceAcao: SelectItem[] = [
  {
    valor: true,
    descricao: "Criar novo lote",
  },
  {
    valor: false,
    descricao: "Selecionar lote existente",
  },
];

const datasourceGerarLote: SelectItem[] = [
  {
    valor: true,
    descricao: "Manualmente",
  },
  {
    valor: false,
    descricao: "Automaticamente, com o prefixo",
  },
];

const [dataSourceLoteGrid, loteGridExibicaoEBusca] =
  apiLote.GetDadosSelectBoxLote();

export default function RastreabilidadePorLote(
  props: RastreabilidadePorLoteProps<RastreabilidadePorLoteRequest>
) {
  const { control, watch } = props.hookForms;

  return (
    <TabContainer>
      <Linha>
        <Coluna md={3}>
          <FormSelectBox
            name="rastreabilidadeLote.criarNovo"
            titulo="Ação"
            control={control}
            dataSource={datasourceAcao}
          />
        </Coluna>
        {watch("rastreabilidadeLote.criarNovo") &&
          !props.itemUtilizaPrefixo && (
            <Coluna md={3}>
              <FormSelectBox
                name="rastreabilidadeLote.gerarLoteManualmente"
                titulo="Gerar lote"
                control={control}
                dataSource={datasourceGerarLote}
              />
            </Coluna>
          )}

        {watch("rastreabilidadeLote.criarNovo") &&
          watch("rastreabilidadeLote.gerarLoteManualmente") && (
            <Coluna md={3}>
              <FormTextBox
                name="rastreabilidadeLote.loteDoFabricanteOuNumeroDeSerie"
                titulo="Lote"
                control={control}
              />
            </Coluna>
          )}
        {(!watch("rastreabilidadeLote.criarNovo") ||
          !watch("rastreabilidadeLote.gerarLoteManualmente")) && (
          <Coluna md={3}>
            <FormSelectBoxLazyMxp
              name="rastreabilidadeLote.id"
              titulo="Lote"
              control={control}
              dataSource={dataSourceLoteGrid}
              configuracoesExibicaoEBusca={loteGridExibicaoEBusca}
              seletorConfig={{
                modo: "selecaoUnica",
                titulo: "Selecionar o lote",
                componenteGrid: <GridLote />,
              }}
              labelSemDados="Sem dados"
            />
          </Coluna>
        )}
        {watch("rastreabilidadeLote.criarNovo") && (
          <Coluna md={3}>
            <ComboEmpresaMxp
              name="rastreabilidadeLote.fabricanteId"
              titulo="Fabricante"
              control={control}
              tituloSeletor="Selecionar empresa fabricante"
            />
          </Coluna>
        )}
        {watch("rastreabilidadeLote.criarNovo") &&
          watch("rastreabilidadeLote.gerarLoteManualmente") && (
            <>
              <Coluna md={3}>
                <FormDateBox
                  name="rastreabilidadeLote.fabricacaoData"
                  titulo="Fabricação"
                  control={control}
                  tipo="date"
                  aceitaValorCustomizado={true}
                  formatoExibicao="dd/MM/yyyy"
                  aceitaDigitacaoComMascara={true}
                  requerido
                />
              </Coluna>
              <Coluna md={3}>
                <FormDateBox
                  name="rastreabilidadeLote.vencimentoData"
                  titulo="vencimento"
                  control={control}
                  tipo="date"
                  aceitaValorCustomizado={true}
                  formatoExibicao="dd/MM/yyyy"
                  aceitaDigitacaoComMascara={true}
                  requerido
                />
              </Coluna>
              <Coluna md={3}>
                <FormDateBox
                  name="rastreabilidadeLote.reanaliseData"
                  titulo="Reanalise"
                  control={control}
                  tipo="date"
                  aceitaValorCustomizado={true}
                  formatoExibicao="dd/MM/yyyy"
                  aceitaDigitacaoComMascara={true}
                  requerido
                />
              </Coluna>
            </>
          )}
      </Linha>
      {watch("rastreabilidadeLote.criarNovo") &&
        watch("rastreabilidadeLote.gerarLoteManualmente") && (
          <>
            <Linha>
              <Coluna md={6}>
                <FormTextArea
                  name="rastreabilidadeLote.observacoes"
                  titulo="Observações"
                  valor={""}
                  control={control}
                  height={20}
                />
              </Coluna>
              <Coluna md={6}>
                <FormTextArea
                  name="rastreabilidadeLote.observacoesInternas"
                  titulo="Observações internas"
                  valor={""}
                  control={control}
                  height={20}
                />
              </Coluna>
            </Linha>
          </>
        )}
    </TabContainer>
  );
}
