import DataGrid, { Column } from "devextreme-react/cjs/data-grid";
import NumberBox, { NumberBoxTypes } from "devextreme-react/cjs/number-box";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { GridNumberBoxProps } from "../../../../components/templates-celulas-grid/inputs/number-box/number-box-grid.edit";
import ContextoOperacoesInsumo from "../../../../features/producao/insumo-da-ordem-de-producao/componentes/contexto-funcoes-insumo";
import { useSeletorGridMxp } from "../../../../hooks/seletor.hooks";
import { EstoqueMovimentacaoSelecionarGridModel } from "../../../../models/api/estoque-movimentacao/estoque-movimentacao";
import { GridMxpProps } from "../../../../models/shared/ui/grid";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../utils/grid/grid-builder";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import { colunasEstoqueMovimentacao } from "../colunasEstoqueMovimentacao";

export interface GridSelecionarEstoqueMovimentacaoProps
  extends GridMxpProps<EstoqueMovimentacaoSelecionarGridModel> {
  dataSource: DataSource | ArrayStore;
}

const nameOfGridHandler = criarNameof<EstoqueMovimentacaoSelecionarGridModel>();

const QuantidadeEditavelComponent = (props: any) => {
  const onValueChanged = useCallback(
    (e: NumberBoxTypes.ValueChangedEvent) => {
      props.data.setValue(e.value);
    },
    [props]
  );

  return (
    <NumberBox
      name="quantidadeMovimentar"
      defaultValue={props.data.value}
      onValueChanged={onValueChanged}
      min={0}
      {...GridNumberBoxProps}
    />
  );
};

export function GridSelecaoEstoqueMovimentacao(
  props: GridSelecionarEstoqueMovimentacaoProps
) {
  const gridRef = useRef<DataGrid>(null);
  const { referenciasGridInsumos } = useContext(ContextoOperacoesInsumo);

  useEffect(() => {
    referenciasGridInsumos.definirReferenciaGridSelecaoEstoqueMovimentacaoEstornarInsumo(
      gridRef
    );
  }, []);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "grid-selecao-estoque-movimentacao",
      () => gridRef?.current?.instance,
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(props.dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.estoqueMovimentacao)
      .definirOrdenacao()
      .definirOperacoesNoLadoDoCliente()
      .definirEditavel()
      .build();
  }, [props.filtrosNoCliente]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        <Column
          key={nameOfGridHandler("quantidadeParaEstornar")}
          dataField={nameOfGridHandler("quantidadeParaEstornar")}
          {...obterConfiguracaoColuna("quantidade")}
          allowEditing={true}
          showEditorAlways={true}
          width={200}
          visibleIndex={8}
          caption="Quantidade para estornar"
          editCellComponent={QuantidadeEditavelComponent}
        />
        {colunasEstoqueMovimentacao}
      </DataGrid>
    </>
  );
}
