import { UseFormReturn } from "react-hook-form";
import FormTextArea from "../../../../../../components/formularios/textarea";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import { LoteRequestDTO } from "../../../models/lote.api";

interface LoteAbaObservacoesProps {
  hookForms: UseFormReturn<LoteRequestDTO>;
}
export default function LoteAbaObservacoes(props: LoteAbaObservacoesProps) {
  const { control } = props.hookForms;
  return (
    <TabContainer>
      <Linha>
        <Coluna md={8}>
          <FormTextArea
            name="observacoes"
            titulo={"Observações"}
            valor={""}
            control={control}
            height={25}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={8}>
          <FormTextArea
            name="observacoesInternas"
            titulo={"Observações internas"}
            valor={""}
            control={control}
            height={25}
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
