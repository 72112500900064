import { AxiosResponse, HttpStatusCode } from "axios";
import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/cjs/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useMemo, useRef } from "react";
import { FieldArrayMethodProps } from "react-hook-form";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { ResponseBaseArquivo } from "../../../../../models/api/comum/response-base";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import exibirNotificacaoToast from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import { GestorEventoClickUnicaLinha } from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import {
  createUrlFromBlobAndDownload,
  extractFileNameFromContentDisposition,
} from "../../../../../utils/file/file-utils";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { ArquivoBaseModel } from "../../models/arquivo.api";

export interface OperacoesFieldArray {
  insert: (
    i: number,
    value: ArquivoBaseModel | ArquivoBaseModel[],
    options?: FieldArrayMethodProps
  ) => void;
  remove: (index?: number | number[]) => void;
}

interface GridAnexosProps {
  idRegistroRelacionado: number;
  permissaoIncluir?: string;
  permissaoExcluir?: string;
  permissaoVisualizarEBaixar?: string;
  operacoesFieldArray: OperacoesFieldArray;
  funcaoParaBaixarAnexo: (
    idArquivo: number
  ) => Promise<AxiosResponse<ResponseBaseArquivo, any>>;
  arquivos: ArquivoBaseModel[];
}

const nameOfGridHandler = criarNameof<ArquivoBaseModel>();

const getBase64 = (file: File): Promise<string | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve((reader.result as string).split(",")[1] as string);
    reader.onerror = reject;
  });

function obterIndexParaInsercaoNoArray(arquivos: ArquivoBaseModel[]) {
  const index = arquivos.length - 1;

  if (index < 0) {
    return 0;
  }

  return index;
}

export default function GridAnexos(props: GridAnexosProps) {
  const gridRef = useRef<DataGrid>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const { insert, remove } = props.operacoesFieldArray;

  const dataSource = useMemo(() => {
    return new ArrayStore<ArquivoBaseModel, number>({
      data: props.arquivos,
      key: "id",
    });
  }, [props.arquivos, props.idRegistroRelacionado]);

  const atualizarGrid = useCallback(() => {
    if (gridRef.current?.instance.refresh) {
      gridRef.current?.instance.refresh();
    }
  }, []);

  const baixarAnexo = useCallback(async (registro: ArquivoBaseModel) => {
    if (registro.id == 0) {
      exibirNotificacaoToast({
        mensagem: `Não é posssível baixar um anexo que ainda não foi salvo.`,
      });
      return;
    }

    if (
      props.permissaoVisualizarEBaixar &&
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        props.permissaoVisualizarEBaixar,
      ])
    ) {
      return;
    }

    try {
      const resposta = await props.funcaoParaBaixarAnexo(registro.id);

      if (resposta.status != HttpStatusCode.Ok) {
        checarResponse(resposta.data);
      }
      const filename = extractFileNameFromContentDisposition(resposta.headers);

      createUrlFromBlobAndDownload(resposta, filename);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }, []);

  const gridController = new GridController<ArquivoBaseModel>(
    () => gridRef.current?.instance
  );

  const acoesDeContexto: ItemContextMenuMxp[] = [
    {
      text: "Baixar anexo",
      icon: "ic-material-symbols-outlined ic-download",
      gestorEventoClick: new GestorEventoClickUnicaLinha(
        baixarAnexo,
        () => gridController
      ),
      exibirNaLinhaDaGrid: "sempre",
      hint: "Baixar anexo",
    },
  ];

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("grid-anexos", () => gridRef.current?.instance)
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .configurarExportacao("Anexos")
      .definirPaginacao()
      .definirBotaoRefresh(atualizarGrid)
      .definirOrdenacao()
      .definirBotaoAdicional({
        stylingMode: "contained",
        text: "Anexar",
        icon: "attach",
        type: "success",
        onClick: () => {
          inputFileRef.current?.click();
        },
      })
      .definirMenuDeContexto(acoesDeContexto)
      .build();
  }, [dataSource]);

  useMenuDeContextosGrid(acoesDeContexto);

  const onChangeFiles = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e?.target?.files) {
        return;
      }

      if (
        props.permissaoIncluir &&
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          props.permissaoIncluir,
        ])
      ) {
        return;
      }

      Array.from(e.target.files).forEach(async (anexo) => {
        const name = anexo.name;
        const nomeSemExtensao = name?.substring(0, name.lastIndexOf("."));
        if (
          props.arquivos.some(
            (item) => item.nomeOriginalSemExtensao == nomeSemExtensao
          )
        ) {
          exibirNotificacaoToast({
            mensagem: `Já existe um anexo com o nome "${nomeSemExtensao}".`,
          });
          return;
        }
        const valor = await getBase64(anexo as File);

        insert(obterIndexParaInsercaoNoArray(props.arquivos), {
          id: 0,
          nomeOriginalSemExtensao: nomeSemExtensao,
          extensao: "." + name?.split(".").pop(),
          arquivo: valor,
        } as ArquivoBaseModel);
      });

      atualizarGrid();
    },
    [props.arquivos, props.idRegistroRelacionado]
  );

  const excluirRegistro = useCallback(
    async (registro: ArquivoBaseModel) => {
      if (
        props.permissaoExcluir &&
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          props.permissaoExcluir,
        ])
      ) {
        return;
      }

      const confirmacao = await exibirConfirmacao(
        "Confirmar exclusão",
        `Tem certeza de que deseja excluir o anexo "${registro.nomeOriginalSemExtensao}.${registro.extensao}"? ` +
          `Após excluir, clique em "Salvar" para confirmar a operação.`
      );

      if (!confirmacao) {
        return;
      }

      const indexARemover = props.arquivos.findIndex(
        (x) => x.nomeOriginalSemExtensao == registro.nomeOriginalSemExtensao
      );

      if (indexARemover != -1) {
        remove(indexARemover);
      }

      atualizarGrid();
    },
    [props.arquivos]
  );

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        {GridColunaAcoes<ArquivoBaseModel>({
          handleExcluir: excluirRegistro,
        })}
        <Column
          key={nameOfGridHandler("nomeOriginalSemExtensao")}
          dataField={nameOfGridHandler("nomeOriginalSemExtensao")}
          {...obterConfiguracaoColuna("stringXG")}
          caption="Nome"
        />
        <Column
          key={nameOfGridHandler("extensao")}
          dataField={nameOfGridHandler("extensao")}
          {...obterConfiguracaoColuna("stringP")}
          caption="Extensão"
        />
        {GetColunasDeAuditoria()},
      </DataGrid>

      <input
        ref={inputFileRef}
        type="file"
        style={{ display: "none" }}
        id="input-file"
        multiple
        accept="*"
        onChange={onChangeFiles}
      />
    </>
  );
}
