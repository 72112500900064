import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/cjs/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useFiltroDinamicoLadoClienteGrid } from "../../../../../hooks/grid.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { decodificaBooleanoEmSimNao } from "../../../../../models/api/comum/decodificadores";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApiComoModal,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { CentroDeTrabalhoGridModel } from "../../models/centro-de-trabalho.api";
import CentroDeTrabalhoServico from "../../servicos/centro-de-trabalho.servico";
import FormCentroDeTrabalho from "../formulario";

const service = new CentroDeTrabalhoServico();
const nameOfGridHandler = criarNameof<CentroDeTrabalhoGridModel>();

interface GridCentroDeTrabalhoProps
  extends GridMxpProps<CentroDeTrabalhoGridModel> {
  abrirModalNovoRegistro?: boolean;
}

export default function GridCentroDeTrabalho(props: GridCentroDeTrabalhoProps) {
  const gridRef = useRef<DataGrid>(null);
  const parametroId = useParametroId();
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const dataSource = service.ObterDataSourceParaGrid<CentroDeTrabalhoGridModel>(
    props.filtrosNoServidor
  );

  const handleNovoRegistro = useCallback(() => {
    setIdRegistroEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: CentroDeTrabalhoGridModel) => {
      setIdRegistroEdicao(registro.id);
    },
    []
  );

  useEffect(() => {
    if (props.abrirModalNovoRegistro) {
      setIdRegistroEdicao(0);
    }
  }, []);

  const handleExcluirRegistro = useCallback(
    async (registro: CentroDeTrabalhoGridModel) => {
      const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (excluir) {
        try {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApiComoModal(erro);
        }
      }
    },
    []
  );

  function obterMensagemExclusao(registro: CentroDeTrabalhoGridModel) {
    return `Tem certeza de que deseja excluir o centro de trabalho ${registro.codigo}?`;
  }

  const gridController = new GridController<CentroDeTrabalhoGridModel>(
    () => gridRef.current?.instance
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "centros-de-trabalho",
      () => gridRef.current?.instance,
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.centrosDeTrabalho)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.filtrosNoCliente]);

  useFiltroDinamicoLadoClienteGrid(
    () => gridRef.current?.instance,
    props.filtrosNoCliente
  );

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<CentroDeTrabalhoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEdicao(NaN);

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-centro-de-trabalho"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<CentroDeTrabalhoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column
            key={nameOfGridHandler("ativo")}
            dataField={nameOfGridHandler("ativo")}
            {...obterConfiguracaoColuna("boolSimNao")}
            caption="Ativo"
            visible={false}
            cellRender={({ data }) => {
              const dados = data as CentroDeTrabalhoGridModel;
              return decodificaBooleanoEmSimNao(dados.ativo);
            }}
          />
          <Column
            key={nameOfGridHandler("codigo")}
            dataField={nameOfGridHandler("codigo")}
            {...obterConfiguracaoColuna("stringP")}
            sortIndex={0}
            sortOrder="asc"
            caption="Código"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Descrição"
          />
          <Column
            key={nameOfGridHandler("centroDeCustosFormatado")}
            dataField={nameOfGridHandler("centroDeCustosFormatado")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Centro de custos"
          />
          <Column
            key={nameOfGridHandler("tipo")}
            dataField={nameOfGridHandler("tipo")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Tipo"
          />
          <Column
            key={nameOfGridHandler("custoPorHora")}
            dataField={nameOfGridHandler("custoPorHora")}
            {...obterConfiguracaoColuna("custoPorHora")}
            cellRender={({ data }) => {
              const dados = data as CentroDeTrabalhoGridModel;
              return dados.custoPorHora
                ? formatarNumero(dados.custoPorHora, 2)
                : "";
            }}
          />
          <Column
            key={nameOfGridHandler("horaPorDia")}
            dataField={nameOfGridHandler("horaPorDia")}
            {...obterConfiguracaoColuna("horasPorDia")}
            cellRender={({ data }) => {
              const dados = data as CentroDeTrabalhoGridModel;
              return dados.horaPorDia
                ? formatarNumero(dados.horaPorDia, 2)
                : "";
            }}
          />
          <Column
            key={nameOfGridHandler("unidade")}
            dataField={nameOfGridHandler("unidade")}
            {...obterConfiguracaoColuna("unidade")}
          />
          <Column
            key={nameOfGridHandler("centroDeTrabalhoGrupo")}
            dataField={nameOfGridHandler("centroDeTrabalhoGrupo")}
            {...obterConfiguracaoColuna("centroDeTrabalhoGrupo")}
          />
          <Column
            key={nameOfGridHandler("grupoDeCentroDeTrabalhoCalendario")}
            dataField={nameOfGridHandler("grupoDeCentroDeTrabalhoCalendario")}
            {...obterConfiguracaoColuna("grupoDeCentroDeTrabalhoCalendario")}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEdicao,
          NomesModais.centroDeTrabalho
        )}
        visivel={!Number.isNaN(idRegistroEdicao)}
        handleFechar={handleFecharModal}
        largura={"max(50vw, 800px)"}
        altura={"min(86vh, 750px)"}
      >
        <FormCentroDeTrabalho
          idRegistroEmEdicao={idRegistroEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
