import getApi from "../../../../configs/api";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import {
  CadastrarReservaParaOrdemDeProducaoRequest,
  CriarReservaParaOrdemDeProducaoResponse,
} from "../models/reserva.api";

export default class ReservaService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Reserva);
  }

  public async CadastrarReservaOrdemProducaoAsync(
    request: CadastrarReservaParaOrdemDeProducaoRequest
  ) {
    const api = getApi();
    const response = await api.post<CriarReservaParaOrdemDeProducaoResponse>(
      `${this._nomeEndpoint}/CriarParaOrdemDeProducao`,
      request
    );

    return response.data;
  }
}
