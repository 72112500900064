import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/cjs/tab-panel";
import { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import CamposOcultosDosAnexos from "../../../../../components/arquivo/campos-hidden";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { QuebrarLinhas } from "../../../../../components/texto/quebrar-linhas";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { ResponseBase } from "../../../../../models/api/comum/response-base";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import ApiConfiguracoes from "../../../../../services/configuracoes/configuracoes.service";
import APILoteENumeroDeSeriePrefixo from "../../../../../services/item/numero-de-serie-prefixo/numero-de-serie-prefixo.service";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirAlerta, exibirConfirmacao } from "../../../../../utils/dialogos";
import { ItemResponse } from "../../../../itens/item/models/item.api";
import { LoteRequestDTO, LoteResponseDTO } from "../../models/lote.api";
import LoteContantes from "../../models/lote.constantes";
import LoteServico from "../../servicos/lote.servico";
import { abrirModalDetalhesDoLoteMxp1 } from "../../utils/lote.utils";
import LoteAbaAnexos from "../abas-formulario/anexos";
import LoteAbaDadosGerais from "../abas-formulario/dados-gerais";
import LoteAbaHistorico from "../abas-formulario/historico";
import LoteAbaObservacoes from "../abas-formulario/observacoes";

let dadosAuditoria: AuditavelDTO | undefined = undefined;

const service = new LoteServico();

const novoRegistro: LoteRequestDTO = {
  id: 0,
  itemId: null,
  fabricanteId: null,
  loteDoFabricanteOuNumeroDeSerie: null,
  historico: null,
  reanaliseData: null,
  vencimentoData: null,
  fabricacaoData: null,
  observacoes: null,
  observacoesInternas: null,
  arquivos: [],
  utilizaPrefixo: false,
};

export default function FormLote(props: IFormularioEditavelBase) {
  const [carregando, setCarregando] = useState(false);
  const [loteDataRequerido, setLoteDataRequerido] = useState(true);
  const [controleLoteFabricacao, setControleLoteFabricacao] = useState(true);

  const isRegistroEmEdicao = props.idRegistroEmEdicao != 0;

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    itemId: yup.number().required().moreThan(-1).integer(),
    fabricanteId: yup.number().required().moreThan(-1).integer(),
    loteDoFabricanteOuNumeroDeSerie: yup
      .string()
      .required()
      .max(LoteContantes.LoteDoFabricanteOuNumeroDeSerieMaxLength),
    fabricacaoData: yup.string().when("$deveSerObrigatorio", (_, schema) => {
      return loteDataRequerido ? schema.required() : schema.nullable();
    }),
    vencimentoData: yup.string().when("$deveSerObrigatorio", (_, schema) => {
      return loteDataRequerido ? schema.required() : schema.nullable();
    }),
  });

  const hookForms = useForm<LoteRequestDTO>({
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, getValues, reset, formState, control } =
    hookForms;

  const fieldArrayAnexos = useFieldArray({
    control,
    name: "arquivos",
  });

  useEffect(() => {
    ApiConfiguracoes.obterConfiguracoesDeEstoque().then((resposta) => {
      setLoteDataRequerido(resposta.loteDatasRequerido ?? false);
      setControleLoteFabricacao(resposta.controleLoteFabricacao ?? false);
    });
  }, []);

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      reset(novoRegistro);
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      const [resposta, anexos] = await Promise.all([
        service.ObterPorIdComDadosAuditoria<LoteResponseDTO>(
          props.idRegistroEmEdicao
        ),
        service.ObterAnexos(props.idRegistroEmEdicao),
      ]);

      checarResponse(resposta);

      resposta.model.arquivos = anexos;
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoComErro);
  }

  async function handlerVerificacaoDeTranscricaoDoPrefixoESufixoDoLote(
    model: LoteRequestDTO
  ) {
    if (!model.utilizaPrefixo) {
      return true;
    }

    const resultado =
      await APILoteENumeroDeSeriePrefixo.verificaSeTranscricaoDePrefixoOuSufixoNaoEncontrouAlgumDadoAsync(
        model.itemId ?? 0
      );

    if (resultado.algumDadoNaoFoiEncontrado) {
      const confirmacao = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        renderToString(
          <QuebrarLinhas texto={resultado.mensagemDeDadosNaoEncontrados} />
        )
      );

      return confirmacao;
    } else {
      return true;
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues() as LoteRequestDTO;
    try {
      let resposta: ResponseBase;

      const confirmaCriacaoDeLoteComDadosNaoEncontradosEmTagPrefixoESufixoDoLote =
        await handlerVerificacaoDeTranscricaoDoPrefixoESufixoDoLote(model);

      if (
        !confirmaCriacaoDeLoteComDadosNaoEncontradosEmTagPrefixoESufixoDoLote
      ) {
        return;
      }

      if (!(await verificarSeItemUtilizaPrefixo(model))) {
        return;
      }

      if (props.idRegistroEmEdicao > 0) {
        const [respostaApi, respostaAnexo] = await Promise.all([
          service.Atualizar(model),
          service.AnexoAlterarLista(model.id, model.arquivos ?? []),
        ]);

        resposta = respostaApi;

        checarResponse(respostaApi);
        checarResponse(respostaAnexo);
      } else {
        const respostaInicial = await service.CadastrarComRetorno<
          LoteRequestDTO,
          LoteResponseDTO
        >(model as LoteRequestDTO);
        if (respostaInicial.sucesso) {
          const id = respostaInicial.model.id;
          const respostaAnexo = await service.AnexoAlterarLista(
            id,
            model.arquivos ?? []
          );
          await verificaSeLoteDoFabricanteNumeroSerieSaoDiferentesExibeAlerta(
            model.loteDoFabricanteOuNumeroDeSerie ?? "",
            respostaInicial.model.loteDoFabricanteOuNumeroDeSerie ?? ""
          );
          checarResponse(respostaAnexo);
        }

        resposta = {
          sucesso: respostaInicial.sucesso,
          mensagem: respostaInicial.mensagem,
          erros: respostaInicial.erros,
        };
      }

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
      fechar(ResultadoAcaoFormulario.AcaoCancelada);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  async function verificarSeItemUtilizaPrefixo(model: LoteRequestDTO) {
    try {
      const resposta = await APIBase.obterPorId<ItemResponse>(
        model.itemId!,
        NomesEndpoints.Item
      );

      if (!resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Erro,
        });

        return false;
      }

      if (!resposta.model.utilizaPrefixo && model.utilizaPrefixo) {
        const mensagemDeSugestaoBase =
          ' marque a opção "Utilizar prefixo e sufixo" e selecione o prefixo e sufixo do lote.';

        const mensagemDeSugestao =
          model.id == 0
            ? `Desmarque o campo "Utilizar prefixo e sufixo do item" ou entre no cadastro do item, ${mensagemDeSugestaoBase}`
            : `Entre no cadastro do item, ${mensagemDeSugestaoBase}`;

        exibirAlerta(
          "Atenção",
          `Não foi possível salvar o lote, pois no cadastro do item ${resposta.model.codigo}, não está marcada a ` +
            'opção "Utilizar prefixo e sufixo".</br>' +
            `<b>Sugestão:</b> ${mensagemDeSugestao}`
        );

        return false;
      }

      return true;
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
      return false;
    }
  }

  async function verificaSeLoteDoFabricanteNumeroSerieSaoDiferentesExibeAlerta(
    loteDoFabricanteNumeroSerieInicial: string,
    loteDoFabricanteNumeroSerieFinal: string
  ) {
    if (
      loteDoFabricanteNumeroSerieInicial == "" ||
      loteDoFabricanteNumeroSerieFinal == ""
    ) {
      return;
    }

    if (
      loteDoFabricanteNumeroSerieInicial !== loteDoFabricanteNumeroSerieFinal
    ) {
      await exibirAlerta(
        JanelasDeNotificacaoTitulos.Atencao,
        `O lote do fabricante número ${loteDoFabricanteNumeroSerieInicial} já foi utilizado. Foi criado o lote do fabricante ${loteDoFabricanteNumeroSerieFinal}.`
      );
    }
  }

  async function handleCancelar() {
    if (formState.isDirty) {
      const confirmacao = await exibirConfirmacao(
        "Aviso",
        "Há dados não salvos. Deseja cancelar?"
      );

      if (!confirmacao) {
        return;
      }
    }

    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function funcaoParaBaixarAnexo(idAnexo: number) {
    return service.AnexoObterDadosParaDownload(getValues().id, idAnexo);
  }

  async function handleImprimir() {
    abrirModalDetalhesDoLoteMxp1(props.idRegistroEmEdicao);
  }

  return (
    <ContainerFormMxp data-testid="edit-form-lote">
      <FormMxp carregando={carregando}>
        <ProvedorAjuda id="edit-form-lote">
          <input type="hidden" {...register("id")} defaultValue={0} />
          <CamposOcultosDosAnexos
            register={register}
            arquivos={fieldArrayAnexos.fields}
            key={"campos-ocultos-anexo"}
          />
          <TabPanel
            key={props.idRegistroEmEdicao}
            deferRendering={false}
            showNavButtons
            swipeEnabled={false}
            itemTitleRender={(item) => item.text}
            height={"95%"}
          >
            <Item text="Dados gerais">
              <LoteAbaDadosGerais
                loteDataRequerido={loteDataRequerido ?? undefined}
                isRegistroEmEdicao={isRegistroEmEdicao}
                controleLoteFabricacao={controleLoteFabricacao}
                hookForms={hookForms}
              />
            </Item>
            <Item text="Observações">
              <LoteAbaObservacoes hookForms={hookForms} />
            </Item>
            <Item text="Histórico">
              <LoteAbaHistorico dataSource={getValues().historico} />
            </Item>
            <Item text="Anexos">
              <LoteAbaAnexos
                operacoesFieldArray={fieldArrayAnexos}
                idRegistro={props.idRegistroEmEdicao}
                hookForms={hookForms}
                funcaoParaBaixarAnexo={funcaoParaBaixarAnexo}
              />
            </Item>
          </TabPanel>
        </ProvedorAjuda>
      </FormMxp>
      <ToolbarMxp dadosAuditoria={dadosAuditoria}>
        <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
        <BotaoCancelarMxp handleClick={handleCancelar} />
        <Button
          key="btn-imprimir-lote"
          type="normal"
          text="Imprimir"
          visible={isRegistroEmEdicao}
          onClick={handleImprimir}
          icon="print"
        />
      </ToolbarMxp>
    </ContainerFormMxp>
  );
}
