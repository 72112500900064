export enum PedidoDeVendaEstado {
  Digitacao = 0,
  Cancelado = 2,
  Aaprovar = 5,
  Aprovado = 6,
  Faturado = 7,
  FaturadoEntregaFutura = 8,
}

// WARN: Enum não corresponde ao retorno real da coluna pois atualmente (2024-09-17)
//       essa coluna está retornando "Faturado c/ entrega futura" para o estado "Aprovado"
export enum PedidoDeVendaEstadoDecodificado {
  Digitacao = "Digitação",
  Cancelado = "Cancelado",
  Aaprovar = "A aprovar",
  Aprovado = "Aprovado",
  Faturado = "Faturado",
  FaturadoEntregaFutura = "Faturado c/ entrega futura",
}

const pedidoDeVendaDecodificacaoMap = Object.freeze({
  [PedidoDeVendaEstado.Digitacao]: PedidoDeVendaEstadoDecodificado.Digitacao,
  [PedidoDeVendaEstado.Cancelado]: PedidoDeVendaEstadoDecodificado.Cancelado,
  [PedidoDeVendaEstado.Aaprovar]: PedidoDeVendaEstadoDecodificado.Aaprovar,
  [PedidoDeVendaEstado.Aprovado]: PedidoDeVendaEstadoDecodificado.Aprovado,
  [PedidoDeVendaEstado.Faturado]: PedidoDeVendaEstadoDecodificado.Faturado,
  [PedidoDeVendaEstado.FaturadoEntregaFutura]:
    PedidoDeVendaEstadoDecodificado.FaturadoEntregaFutura,
});

export const decodificarPedidoDeVenda = (
  valor: PedidoDeVendaEstado
): PedidoDeVendaEstadoDecodificado => pedidoDeVendaDecodificacaoMap[valor];
