import getApi from "../../configs/api";
import { MovimentacaoBaseGridModel } from "../../features/estoque/estoque-movimentacao/models/estoque-movimentacao.api";
import DataSourceFactory, {
  DataSourceFiltragem,
  DataSourceRawFilter,
} from "../../utils/grid/data-source-factory";
import { NomesEndpoints } from "../comum/nomesEndpoints";
import { ServiceBase } from "../comum/serviceBase";

export class EstoqueMovimentacaoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.EstoqueMovimentacao);
  }

  public async VerificaSeInsumoPossuiMovimentacoes(idInsumo: number) {
    const api = getApi();
    const response = await api.get<boolean>(
      `${this._nomeEndpoint}/VerificaSeInsumoPossuiMovimentacoes?id=${idInsumo}`
    );

    return response.data;
  }

  public ObterDataSourceParaGridRetiradaDeMaterial(
    id: number,
    filtroPadrao?: DataSourceFiltragem<MovimentacaoBaseGridModel>[],
    filtroPadraoExato?: DataSourceRawFilter<MovimentacaoBaseGridModel>
  ) {
    return DataSourceFactory.CriarParaGrid<MovimentacaoBaseGridModel, number>(
      `${this._nomeEndpoint}/GridRetiradaDeMaterial?id=${id}`,
      filtroPadrao,
      filtroPadraoExato
    );
  }

  public ObterDataSourceParaConclusoesOrdemDeProducao(
    id: number,
    filtroPadrao?: DataSourceFiltragem<MovimentacaoBaseGridModel>[],
    filtroPadraoExato?: DataSourceRawFilter<MovimentacaoBaseGridModel>
  ) {
    return DataSourceFactory.CriarParaGrid<MovimentacaoBaseGridModel, number>(
      `${this._nomeEndpoint}/GridConclusoesOrdemDeProducao?id=${id}`,
      filtroPadrao,
      filtroPadraoExato
    );
  }
}
