import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormCheckBox,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
} from "../../../../components/formularios";
import FormTextArea from "../../../../components/formularios/textarea";

import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import GridContaDaParteB from "../../../../features/contabilidade/conta-parte-b/componentes/grid";
import { ContaDaParteBGridModel } from "../../../../features/contabilidade/conta-parte-b/models/conta-parte-b";
import { TipoTributoContaParteB } from "../../../../features/contabilidade/conta-parte-b/models/conta-parte-b.enums";
import FiltrosGridContaParteB from "../../../../features/contabilidade/conta-parte-b/models/conta-parte-b.filtros";
import { ContaParteBService } from "../../../../features/contabilidade/conta-parte-b/servicos/conta-parte-b.service";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import { AdicaoExclusaoCompensacaoLalurGridModel } from "../../../../models/api/adicao-exclusao-compensacao/adicao-exclusao-compensacao-lalur-irpj/adicao-exclusao-compensacao-lalur-irpj";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { IndicadorDoLancamento } from "../../../../models/api/lancamento-parte-b/lancamento-parte-b-comum";
import {
  LancamentoNaParteBLalurRequest,
  LancamentoNaParteBLalurResponse,
} from "../../../../models/api/lancamento-parte-b/lancamento-parte-b-lalur/lancamento-parte-b-lalur";
import { SelectItemEnumIndicadorDoLancamentoLancamentoNaParteB } from "../../../../models/const/dicionario-combos/lancamento-parte-b";
import { PermissoesApuracaoLalurIrpj } from "../../../../models/permissoes/fiscal/apuracao-lalur-irpj/permissoes-apuracao-lalur-irpj";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { GridAdicaoExclusaoCompensacaoLalur } from "../../../../parts/fiscal/adicao-exclusao-compensacao-lalur-irpj/grid";
import AdicaoExclusaoCompensacaoLalurIrpjService from "../../../../services/adicao-exclusao-compensacao-lalur-irpj/adicao-exclusao-compensacao-lalur-irpj.service";
import { LancamentoNaParteBDoLalurService } from "../../../../services/lancamento-parte-b-lalur/lancamento-parte-b-lalur";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../utils/common/permissoes-utils";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../utils/formatadores/formatador-de-selectbox";

const LancamentoNaParteBLalurService = new LancamentoNaParteBDoLalurService();
const contaParteBService = new ContaParteBService();
const adicaoExclusaoCompensacaoLalurService =
  new AdicaoExclusaoCompensacaoLalurIrpjService();
const nameofContaDaParteBGrid = criarNameof<ContaDaParteBGridModel>();
const nameofAdicaoExclusaoCompensacaoGrid =
  criarNameof<AdicaoExclusaoCompensacaoLalurGridModel>();

function carregaContaParteB() {
  return contaParteBService.GetDataSourceSelectBoxLazy({
    camposRetorno: [
      nameofContaDaParteBGrid("id"),
      nameofContaDaParteBGrid("codigo"),
      nameofContaDaParteBGrid("descricao"),
    ],
    filtros: [
      {
        nomeCampo: nameofContaDaParteBGrid("ativo"),
        operador: "=",
        valor: true,
      },
      {
        nomeCampo: nameofContaDaParteBGrid("tipoTributo"),
        operador: "=",
        valor: TipoTributoContaParteB.IRPJ,
      },
    ],
    camposOrdenacao: [
      {
        nomeCampo: nameofContaDaParteBGrid("descricao"),
        desc: false,
      },
    ],
  });
}

function carregaAdicoesExclusoesCompensacoesLalur() {
  return adicaoExclusaoCompensacaoLalurService.ObterDataSourceSelectBoxLazy({
    camposRetorno: [
      nameofAdicaoExclusaoCompensacaoGrid("id"),
      nameofAdicaoExclusaoCompensacaoGrid("codigo"),
      nameofAdicaoExclusaoCompensacaoGrid("descricao"),
      nameofAdicaoExclusaoCompensacaoGrid("tipoDecodificado"),
    ],
    camposOrdenacao: [
      {
        nomeCampo: nameofAdicaoExclusaoCompensacaoGrid("descricao"),
        desc: false,
      },
    ],
  });
}

interface EditFormLancamentoNaParteBLalurProps
  extends FormularioEdicaoBaseProps {
  idApuracaoDoLalurEDoIrpj: number;
}

const contaParteBExpressaoBusca = [
  nameofContaDaParteBGrid("codigo"),
  nameofContaDaParteBGrid("descricao"),
];
const exibeContaParteB = (c: ContaDaParteBGridModel | null) => {
  if (c) {
    return c.descricao == null ? `${c.codigo}` : `${c.codigo} (${c.descricao})`;
  }

  return "";
};

const adicaoExclusaoCompensacaoExpressaoBusca = [
  nameofAdicaoExclusaoCompensacaoGrid("codigo"),
  nameofAdicaoExclusaoCompensacaoGrid("descricao"),
];
const exibeadicaoExclusaoCompensacao = (
  c: AdicaoExclusaoCompensacaoLalurGridModel | null
) => {
  if (c) {
    return c.descricao == null
      ? `${c.codigo}`
      : FormataDescricao(
          FormatadoresSelectBox.TipoCodigoDescricaoTracoEspaco,
          c.tipoDecodificado,
          c.codigo,
          c.descricao
        );
  }

  return "";
};

export default function EditFormLancamentoNaParteBLalur(
  props: EditFormLancamentoNaParteBLalurProps
) {
  const novoRegistro: LancamentoNaParteBLalurRequest = {
    id: 0,
    apuracaoDoLalurEDoIrpjId: props.idApuracaoDoLalurEDoIrpj,
    contaDaParteBDoLalurOuDoLacsId: 0,
    incluirNaParteA: false,
    adicaoOuExclusaoOuCompensacaoDoLalurId: undefined,
    indicadorDoLancamento: undefined,
    historico: "",
    tributacaoDiferida: false,
    valor: 0,
  };

  const [carregando, setCarregando] = useState(false);
  const [contaParteB] = useState(carregaContaParteB);
  const [adicoesExclusoesCompensacoes] = useState(
    carregaAdicoesExclusoesCompensacoesLalur
  );
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
  const [incluirNaParteA, setIncluirNaParteA] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    contaDaParteBDoLalurOuDoLacsId: yup
      .number()
      .required("Campo obrigatório.")
      .moreThan(0, "Campo obrigatório.")
      .integer(),
    valor: yup.number().moreThan(0).required(),
    indicadorDoLancamento: yup
      .mixed<IndicadorDoLancamento>()
      .transform((v) => (v ? v : null))
      .oneOf(
        Object.values(IndicadorDoLancamento).map((x) => x as number),
        "Valor inválido"
      )
      .test("valida-indicador-do-lancamento", "Indicador inválido", (v) => {
        return incluirNaParteA
          ? v == IndicadorDoLancamento.CR || v == IndicadorDoLancamento.DB
          : true;
      })
      .required(),
    historico: yup.string().required().max(500),
    tributacaoDiferida: yup.bool().oneOf([true, false]),
    incluirNaParteA: yup.bool().required(),
    adicaoOuExclusaoOuCompensacaoDoLalurId: yup
      .number()
      .integer()
      .nullable()
      .test(
        "valida-adicaoOuExclusaoOuCompensacaoDoLalurId",
        "Campo obrigatório.",
        (v) => {
          return incluirNaParteA ? v != null : true;
        }
      ),
    apuracaoDoLalurEDoIrpjId: yup.number().moreThan(-1).integer().required(),
  });

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  const hookForm = useHookForms<LancamentoNaParteBLalurRequest>(schema);
  useCarregarRegistro(props.idRegistroEdicao, CarregarRegistro);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset } = hookForm;

  async function CarregarRegistro() {
    try {
      setCarregando(true);
      const resposta =
        await LancamentoNaParteBLalurService.ObterPorIdComDadosAuditoria<LancamentoNaParteBLalurResponse>(
          props.idRegistroEdicao
        );
      reset(resposta?.model);
      setDadosAuditoria(resposta.model);
    } catch (error) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function handleSalvar() {
    try {
      setCarregando(true);
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesApuracaoLalurIrpj.InserirEditar,
        ])
      ) {
        return;
      }
      const model = getValues();
      const resposta =
        props.idRegistroEdicao > 0
          ? await LancamentoNaParteBLalurService.Atualizar(model)
          : await LancamentoNaParteBLalurService.Cadastrar(model);

      checarResponse(resposta);

      if (resposta?.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({ concluido: true, precisaAtualizar: true });
      }
    } catch (error: any) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  async function onChangeIncluirNaParteA(e: any) {
    setIncluirNaParteA(e.value);
  }

  return (
    <FormBase2
      visivel={props.visivel}
      carregando={carregando}
      onClickSalvar={handleSubmit(handleSalvar)}
      onClickCancelar={handleCancelar}
      configuracoesModal={props.configuracoesModal}
      modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
      titulo={NomesModais.lancamentoNaParteB}
      auditoria={dadosAuditoria}
    >
      <input type="hidden" {...register("id")} defaultValue={0} />
      <ProvedorAjuda id="edit-form-lancamento-parte-b-lalur">
        <Linha>
          <Coluna md={12}>
            <FormSelectBoxLazy
              name="contaDaParteBDoLalurOuDoLacsId"
              titulo="Conta da parte B"
              control={control}
              requerido
              nomeCampoChave={nameofContaDaParteBGrid("id")}
              nomeCampoExibicao={exibeContaParteB}
              expressaoDeBusca={contaParteBExpressaoBusca}
              dataSource={contaParteB}
              lupaConfig={{
                modo: "selecaoUnica",
                titulo: "Selecionar conta da parte B",
                componente: () => (
                  <GridContaDaParteB
                    filtrosNoCliente={FiltrosGridContaParteB.ativo}
                  />
                ),
              }}
              labelSemDados="Sem dados"
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={3}>
            <FormNumberBox
              name="valor"
              titulo="Valor"
              minimo={0}
              formato={obterFormatStringNumero(2)}
              control={control}
              requerido
            />
          </Coluna>
          <Coluna md={5}>
            <FormSelectBox
              name="indicadorDoLancamento"
              titulo="Indicador do lançamento"
              control={control}
              requerido
              dataSource={SelectItemEnumIndicadorDoLancamentoLancamentoNaParteB}
              defaultValue={null}
            />
          </Coluna>
          <Coluna md={4} centralizar>
            <FormCheckBox
              name="tributacaoDiferida"
              titulo="Realização de valores cuja tributação tenha sido diferida"
              control={control}
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={12}>
            <FormTextArea
              name="historico"
              titulo="Histórico"
              control={control}
              requerido
              tamanhoMaximo={500}
              valor={""}
              height={10}
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={3} centralizar>
            <FormCheckBox
              name="incluirNaParteA"
              titulo="Tem reflexo na parte A"
              control={control}
              onValueChanged={onChangeIncluirNaParteA}
            />
          </Coluna>
          <Coluna md={9}>
            <FormSelectBoxLazy
              name="adicaoOuExclusaoOuCompensacaoDoLalurId"
              titulo="Adição, exclusão ou compensação de prejuízo"
              control={control}
              nomeCampoChave={nameofAdicaoExclusaoCompensacaoGrid("id")}
              nomeCampoExibicao={exibeadicaoExclusaoCompensacao}
              expressaoDeBusca={adicaoExclusaoCompensacaoExpressaoBusca}
              dataSource={adicoesExclusoesCompensacoes}
              lupaConfig={{
                modo: "selecaoUnica",
                titulo:
                  "Selecionar adição, exclusão ou compensação de prejuízo",
                componente: (r) => (
                  <GridAdicaoExclusaoCompensacaoLalur ref={r} />
                ),
              }}
              labelSemDados="Sem dados"
              desabilitado={!incluirNaParteA}
              requerido={incluirNaParteA}
            />
          </Coluna>
        </Linha>
      </ProvedorAjuda>
    </FormBase2>
  );
}
