import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { NumeroDeSeriePrefixoPermissoes } from "../../../../../models/permissoes/producao/numero-de-serie-prefixo/NumeroDeSeriePrefixoPermissoes";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridNumeroDeSeriePrefixo from "../../componentes/grid";

export default function PageNumeroDeSeriePrefixo() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="numero-de-serie-prefixo-page"
        titulo={NomesTelas.numeroDeSeriePrefixoESufixo}
        area={["Itens"]}
      >
        <RequerPermissao
          codigoPermissoes={[NumeroDeSeriePrefixoPermissoes.Consultar]}
        >
          <GridNumeroDeSeriePrefixo />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
