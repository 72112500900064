import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormNumberBox,
  FormSelectBox,
  FormTextBox,
} from "../../../../../components/formularios";
import FormTextArea from "../../../../../components/formularios/textarea";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { digitosNumericosSemEspaco } from "../../../../../utils/common/regex-padrao";
import { NUMERO_INTEIRO_FORMATADOR } from "../../../../../utils/formatadores/formatador-de-numeros";
import {
  SerieFiscalRequestDTO,
  SerieFiscalResponseDTO,
} from "../../models/serie-fiscal";
import SerieFiscalConstantes from "../../models/serie-fiscal.constantes";
import {
  SelectItemEnumTipoDocumentoEletronico,
  TipoDocumentoEletronico,
  tipoDocumentoEletronicoDecodificado,
} from "../../models/serie-fiscal.enums";
import SerieFiscalService from "../../servicos/serie-fiscal.service";

const novoRegistro: SerieFiscalRequestDTO = {
  id: 0,
  serie: "",
  proximoNumero: 0,
  tipoDocumentoEletronico: TipoDocumentoEletronico.Nenhum,
  observacoes: null,
};

const tiposDocumentoParaCarecteresNumericosNaSerie: TipoDocumentoEletronico[] =
  [
    TipoDocumentoEletronico.MDFe,
    TipoDocumentoEletronico.NFe,
    TipoDocumentoEletronico.NFCe,
  ];

const service = new SerieFiscalService();

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function EditFormSerieFiscal(props: IFormularioEditavelBase) {
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    serie: yup
      .string()
      .transform((v) => (v ? v : undefined))
      .required()
      .when(["tipoDocumentoEletronico", "id"], (valores, schema) => {
        const tipoDocumentoEletronico = valores[0] as TipoDocumentoEletronico;
        const id = valores[1] as number;

        // Caso seja alteração, não é necessário validar tamanho máximo pois o campo é bloqueado
        if (id > 0) {
          return schema;
        }

        if (
          tipoDocumentoEletronico ==
            TipoDocumentoEletronico.ConformeOperacaoFiscal ||
          tipoDocumentoEletronico == TipoDocumentoEletronico.Nenhum
        ) {
          return schema.max(4);
        }

        schema = schema.max(3);

        if (
          tiposDocumentoParaCarecteresNumericosNaSerie.includes(
            tipoDocumentoEletronico
          )
        ) {
          schema = schema.matches(
            new RegExp(digitosNumericosSemEspaco),
            "Preencha apenas com números para este tipo de documento."
          );
        }

        return schema;
      }),
    proximoNumero: yup
      .number()
      .transform((v) => (v ? v : undefined))
      .required()
      .min(SerieFiscalConstantes.ProximoNumero.Minimo)
      .max(SerieFiscalConstantes.ProximoNumero.Maximo),
    tipoDocumentoEletronico: yup
      .mixed<TipoDocumentoEletronico>()
      .oneOf(
        Object.values(TipoDocumentoEletronico).map((x) => x as number),
        "Valor inválido"
      )
      .required()
      .test(
        "testTipoParaSerie",
        `Para série que possui 4 caracteres, só é permitido "${
          tipoDocumentoEletronicoDecodificado[TipoDocumentoEletronico.Nenhum]
        }" ou "${
          tipoDocumentoEletronicoDecodificado[
            TipoDocumentoEletronico.ConformeOperacaoFiscal
          ]
        }"`,
        function (value) {
          if (
            !this.parent.serie ||
            this.parent.serie.length != SerieFiscalConstantes.SerieTamanho
          ) {
            return true;
          }

          return (
            this.parent.serie.length == SerieFiscalConstantes.SerieTamanho &&
            (value == TipoDocumentoEletronico.Nenhum ||
              value == TipoDocumentoEletronico.ConformeOperacaoFiscal)
          );
        }
      ),
  });

  const { register, control, handleSubmit, getValues, reset, watch } =
    useForm<SerieFiscalRequestDTO>({
      resolver: yupResolver(schema),
    });

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta =
        await service.ObterPorIdComDadosAuditoria<SerieFiscalResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await service.Atualizar(model)
          : await service.Cadastrar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  const serieSomenteLeitura = watch("id") > 0;

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-serie-fiscal">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <Linha>
              <Coluna md={6}>
                <FormTextBox
                  name="serie"
                  titulo={"Série"}
                  requerido
                  tamanhoMaximo={SerieFiscalConstantes.SerieTamanho}
                  control={control}
                  somenteLeitura={serieSomenteLeitura}
                />
              </Coluna>
              <Coluna md={6}>
                <FormNumberBox
                  name="proximoNumero"
                  titulo="Próximo número"
                  control={control}
                  requerido
                  formato={NUMERO_INTEIRO_FORMATADOR}
                  minimo={SerieFiscalConstantes.ProximoNumero.Minimo}
                  maximo={SerieFiscalConstantes.ProximoNumero.Maximo}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormSelectBox
                  name="tipoDocumentoEletronico"
                  titulo="Tipo de documento"
                  control={control}
                  requerido
                  dataSource={SelectItemEnumTipoDocumentoEletronico}
                  habilitaBusca
                  tipoBusca="contains"
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormTextArea
                  name="observacoes"
                  titulo={"Observações"}
                  valor={""}
                  control={control}
                  tamanhoMaximo={SerieFiscalConstantes.ObservacoesTamanhoMaximo}
                  height={25}
                />
              </Coluna>
            </Linha>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
