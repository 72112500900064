import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/cjs/accordion";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import {
  FormCheckBox,
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazyMxp,
  FormTextBox,
} from "../../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../../components/formularios/selectbox-lazy-mxp";
import FormMxp from "../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import { EnderecoEstoqueGridModel } from "../../../../../../models/api/endereco-estoque/endereco-estoque";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../../models/shared/ui/select-item";
import { GridEnderecoEstoque } from "../../../../../../parts/estoque/grids/grid-padrao";
import { ContaContabilService } from "../../../../../../services/contabilidade/conta-contabil/conta-contabil.service";
import { EnderecoDeEstoqueService } from "../../../../../../services/estoque/endereco-estoque/endereco-estoque.service";
import {
  checarErrosDeConfirmacaoResponse,
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import {
  quantidadeValorMaximo,
  quantidadeValorMinimo,
} from "../../../../../../utils/common/constantes";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import {
  FinalidadeTipo,
  FinalidadeTipoHelper,
} from "../../../../../contabilidade/conta-contabil/models/conta-contabil.enum";
import {
  EstoqueMovimentacaoTipo,
  EstoqueMovimentacaoTipoHelper,
} from "../../../../../estoque/estoque-movimentacao/models/estoque-movimentacao.enums";
import {
  novaRastreabilidadeLote,
  yupRastreabilidadeLote,
} from "../../../../../estoque/lote/componentes/formulario-cadastro-de-rastreabilidade";
import { EstocagemTipo } from "../../../../../itens/item/models/item.enums";
import ComboEmpresaMxp from "../../../../../sistema/empresa/form/select-box-lazy";
import { ConcluirOrdemDeProducaoRequest } from "../../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../../servicos/ordem-de-producao.service";
import ConcluirOpAbaRastreabilidadeLote from "../../abas-formulario/abas/rastreabilidade-lote/rastreabilidade-lote";

const service = new OrdemDeProducaoService();
const apiEnderecoEstoque = new EnderecoDeEstoqueService();
const apiContaContabil = new ContaContabilService();

const novoRegistro: ConcluirOrdemDeProducaoRequest = {
  idOrdemDeProducao: 0,
  idItemDaOrdemDeProducao: 0,
  quantidade: 0,
  itemEstocagemTipo: EstocagemTipo.Codigo,
  itemUsaQuantidadeInteira: false,
  fecharOp: false,
  forcarConclusao: false,
  destino: EstoqueMovimentacaoTipo.Estoque,
  idContaContabilDestino: undefined,
  idDono: undefined,
  idPosse: undefined,
  codigoItem: "",
  codigoOrdemDeProducao: "",
  rastreabilidadeLote: novaRastreabilidadeLote,
};

const dataSourceEnderecoEstoqueGrid =
  apiEnderecoEstoque.GetDataSourceSelectBoxLazy({
    camposRetorno: ["id", "codigo", "descricao"],
    camposOrdenacao: [
      {
        nomeCampo: "codigo",
        desc: false,
      },
    ],
  });

const enderecoEstoqueGridExibicaoEBusca =
  assertConfiguracaoExibicaoEBuscaType<EnderecoEstoqueGridModel>({
    expressaoDeBusca: ["codigo", "descricao"],
    nomeCampoChave: "id",
    nomeCampoExibicao: (c) => {
      if (c) {
        return c.descricao == null
          ? `${c.codigo}`
          : `${c.codigo} - ${c.descricao}`;
      }

      return "";
    },
  });

const datasouceDestinos = EstoqueMovimentacaoTipoHelper.AsSelectItems().filter(
  (x) =>
    x.valor === EstoqueMovimentacaoTipo.Estoque ||
    x.valor === EstoqueMovimentacaoTipo.ContaContabil
);

const dataSourceContaContabilGrid =
  apiContaContabil.GetDataSourceSelectBoxContasDeEstoque();

let fecharOp: boolean = true;
let quantidadeDaOp: number = 0;
let dataSourceContaContabilGridCarregado: any[] = [];

export default function FormConcluirOrdemDeProducao(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);
  const [finalidadeContaContabil, setFinalidadeContaContabil] =
    useState<FinalidadeTipo>(FinalidadeTipo.Estoque);
  const [
    dataSourceContaContabilGridFiltrado,
    setDataSourceContaContabilGridFiltrado,
  ] = useState<SelectItem[]>([]);

  const schema = yup.object<ConcluirOrdemDeProducaoRequest>().shape({
    idOrdemDeProducao: yup.number().required().positive().integer(),
    idItemDaOrdemDeProducao: yup.number().required().positive().integer(),
    quantidade: yup
      .number()
      .required()
      .positive()
      .test(
        "is-integer",
        "Quantidade deve ser um número inteiro",
        function (value) {
          const { itemUsaQuantidadeInteira } = this.parent;
          return itemUsaQuantidadeInteira ? Number.isInteger(value) : true;
        }
      ),
    destino: yup
      .number()
      .oneOf([
        EstoqueMovimentacaoTipo.Estoque,
        EstoqueMovimentacaoTipo.ContaContabil,
      ]),
    idContaContabilDestino: yup.number().required().positive().integer(),
    idDono: yup
      .number()
      .notRequired()
      .test(
        "dono-is-required-if-finalidade",
        "Necessário informar o dono",
        function (value) {
          if (
            finalidadeContaContabil ===
              FinalidadeTipo.EstoqueDeTerceirosEmNossoPoder ||
            finalidadeContaContabil ===
              FinalidadeTipo.EstoqueDeTerceirosEmPoderDeTerceiros
          ) {
            return value !== null && value !== undefined && value > 0;
          }
          return true;
        }
      ),
    idPosse: yup
      .number()
      .notRequired()
      .test(
        "posse-is-required-if-finalidade",
        "Necessário informar a posse",
        function (value) {
          if (
            finalidadeContaContabil ===
              FinalidadeTipo.EstoqueEmPoderDeTerceiros ||
            finalidadeContaContabil ===
              FinalidadeTipo.EstoqueDeTerceirosEmPoderDeTerceiros
          ) {
            return value !== null && value !== undefined && value > 0;
          }
          return true;
        }
      ),
    rastreabilidadeLote: yupRastreabilidadeLote(
      (): EstocagemTipo => getValues("itemEstocagemTipo")
    ),
  });

  const hookForm = useForm<ConcluirOrdemDeProducaoRequest>({
    resolver: yupResolver(schema),
  });

  const { register, control, getValues, handleSubmit, reset, watch, setValue } =
    hookForm;

  useEffect(() => {
    filtraContasContabeis();
  }, [watch("destino")]);

  useEffect(() => {
    setFinalidadeContaContabil(
      dataSourceContaContabilGridCarregado.filter(
        (y) => y.id == getValues("idContaContabilDestino")
      )[0]?.finalidade ?? FinalidadeTipo.Estoque
    );
  }, [watch("idContaContabilDestino")]);

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();

    return () => limparTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);

      // Carregar opções com limite de 1000
      dataSourceContaContabilGrid.loadOptions().take = 1000;
      const loadContaContabilTask = dataSourceContaContabilGrid.load();

      // Obter dados para concluir a ordem de produção
      const resposta = await service.ObterDadosParaConcluirOrdemDeProducao(
        props.idRegistroEmEdicao
      );
      checarResponse(resposta);

      if (!resposta.sucesso) {
        return;
      }

      // Esperar o carregamento das contas contábeis
      await loadContaContabilTask;
      dataSourceContaContabilGridCarregado =
        dataSourceContaContabilGrid.items();

      const model = resposta.model;

      // Atualizar rastreabilidade do lote
      model.rastreabilidadeLote = novaRastreabilidadeLote;
      if (model.rastreabilidadeLoteId !== undefined) {
        model.rastreabilidadeLote.criarNovo = false;
        model.rastreabilidadeLote.id = model.rastreabilidadeLoteId;
      }

      // Atualizar variáveis globais
      quantidadeDaOp = model.quantidade;
      fecharOp = model.fecharOp;

      // Resetar o formulário com o modelo atualizado
      reset(model);

      // Filtrar contas contábeis
      filtraContasContabeis();
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    reset(novoRegistro);
  }

  function filtraContasContabeis() {
    const destino = getValues("destino");
    const isEstoque = destino == EstoqueMovimentacaoTipo.Estoque;

    const contasFiltradas = dataSourceContaContabilGridCarregado.filter((x) =>
      isEstoque
        ? FinalidadeTipoHelper.isFinalidadeDeEstoque(x.finalidade)
        : !FinalidadeTipoHelper.isFinalidadeDeEstoque(x.finalidade)
    );

    const contasFormatadas = contasFiltradas.map((x) => ({
      valor: x.id,
      descricao: x.apelido,
    }));

    setDataSourceContaContabilGridFiltrado(contasFormatadas);
    if (
      contasFiltradas.length > 0 &&
      !contasFiltradas.some((x) => x.id == getValues("idContaContabilDestino"))
    ) {
      setValue("idContaContabilDestino", contasFiltradas[0].id);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const podeConcluir =
        await service.VerificarSePodeConcluirOpSemForcarConclusao(model);
      if (!podeConcluir.sucesso) {
        const confirmacaoForcar = await checarErrosDeConfirmacaoResponse(
          podeConcluir
        );
        if (!confirmacaoForcar) {
          return;
        }
        model.forcarConclusao = true;
      }
      const resposta = await service.Concluir(model);
      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });

        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function onQuantidadeChange(e: number) {
    if (!fecharOp) {
      return;
    }

    setValue("fecharOp", e >= quantidadeDaOp);
  }

  return (
    <ContainerFormMxp>
      <FormMxp carregando={carregando}>
        <ProvedorAjuda id="edit-form-concluir-ordem-de-producao">
          <input
            type="hidden"
            {...register("idOrdemDeProducao")}
            defaultValue={0}
          />
          <input
            type="hidden"
            {...register("idItemDaOrdemDeProducao")}
            defaultValue={0}
          />
          <FormGrupo>
            <Linha>
              <Coluna md={3}>
                <FormTextBox
                  name="codigoOrdemDeProducao"
                  titulo="Ordem de produção"
                  control={control}
                  requerido
                  somenteLeitura
                />
              </Coluna>
              <Coluna md={4}>
                <FormTextBox
                  name="codigoItem"
                  titulo="Item"
                  control={control}
                  requerido
                  somenteLeitura
                />
              </Coluna>
              <Coluna md={3}>
                <FormNumberBox
                  name="quantidade"
                  titulo="Quantidade"
                  control={control}
                  minimo={quantidadeValorMinimo.toNumber()}
                  quantidadeIncrementarDecrementar={1}
                  maximo={quantidadeValorMaximo.toNumber()}
                  requerido
                  onChange={onQuantidadeChange}
                />
              </Coluna>
              <Coluna md={2} centralizar>
                <FormCheckBox
                  name="fecharOp"
                  titulo="Fechar OP"
                  control={control}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={3}>
                <FormSelectBox
                  name="destino"
                  titulo="Destino"
                  control={control}
                  dataSource={datasouceDestinos}
                  requerido
                />
              </Coluna>
              <Coluna md={4}>
                <FormSelectBox
                  name="idContaContabilDestino"
                  titulo="Conta"
                  control={control}
                  dataSource={dataSourceContaContabilGridFiltrado}
                  labelSemDados="Sem dados"
                  requerido
                />
              </Coluna>
              <Coluna md={5}>
                <FormSelectBoxLazyMxp
                  name="idEnderecoDeEstoque"
                  titulo="Endereço de estoque"
                  control={control}
                  dataSource={dataSourceEnderecoEstoqueGrid}
                  configuracoesExibicaoEBusca={
                    enderecoEstoqueGridExibicaoEBusca
                  }
                  seletorConfig={{
                    modo: "selecaoUnica",
                    titulo: "Selecionar o endereço de estoque",
                    componenteGrid: <GridEnderecoEstoque />,
                  }}
                  labelSemDados="Sem dados"
                />
              </Coluna>
            </Linha>
            <Linha>
              {FinalidadeTipoHelper.isFinalidadeDeTerceiros(
                finalidadeContaContabil
              ) && (
                <Coluna md={6}>
                  <ComboEmpresaMxp
                    name="idDono"
                    titulo="Dono"
                    control={control}
                    requerido
                    tituloSeletor="Selecionar empresa dona do estoque"
                  />
                </Coluna>
              )}
              {FinalidadeTipoHelper.isFinalidadeEmTerceiros(
                finalidadeContaContabil
              ) && (
                <Coluna md={6}>
                  <ComboEmpresaMxp
                    name="idPosse"
                    titulo="Em poder de"
                    control={control}
                    requerido
                    tituloSeletor="Selecionar empresa posse do estoque"
                  />
                </Coluna>
              )}
            </Linha>
            {(watch("itemEstocagemTipo") == EstocagemTipo.LoteFabricante ||
              watch("itemEstocagemTipo") == EstocagemTipo.Serie) && (
              <TabPanel
                key={props.idRegistroEmEdicao}
                deferRendering={false}
                showNavButtons
                swipeEnabled={false}
                itemTitleRender={(item) => item.text}
              >
                {watch("itemEstocagemTipo") == EstocagemTipo.LoteFabricante && (
                  <Item text="Rastreabilidade">
                    <ConcluirOpAbaRastreabilidadeLote hookForms={hookForm} />
                  </Item>
                )}
              </TabPanel>
            )}
          </FormGrupo>
        </ProvedorAjuda>
      </FormMxp>
      <ToolbarMxp>
        <Button
          type="success"
          text={"Concluir"}
          icon={"save"}
          onClick={handleSubmit(handleSalvar) as any}
        />
        <BotaoCancelarMxp handleClick={handleCancelar} />
      </ToolbarMxp>
    </ContainerFormMxp>
  );
}
