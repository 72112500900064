import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../components/formularios/selectbox-lazy-mxp";
import { EnderecoEstoqueGridModel } from "../../../models/api/endereco-estoque/endereco-estoque";
import { DataSourceOpcoesBuilder } from "../../../utils/grid/data-source-factory";
import { NomesEndpoints } from "../../comum/nomesEndpoints";
import { ServiceBase } from "../../comum/serviceBase";

export class EnderecoDeEstoqueService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.EnderecoEstoque);
  }

  public GetDadosSelectBoxItem(
    dataSourceOpcoes?: DataSourceOpcoesBuilder<EnderecoEstoqueGridModel>
  ): [
    DataSource<EnderecoEstoqueGridModel, number>,
    ConfiguracaoExibicaoEBusca<EnderecoEstoqueGridModel>
  ] {
    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<EnderecoEstoqueGridModel>({
        quantidadeRegistros: dataSourceOpcoes?.quantidadeRegistros,
        camposRetorno: dataSourceOpcoes?.camposRetorno || [
          "id",
          "codigo",
          "descricao",
        ],
        camposOrdenacao: dataSourceOpcoes?.camposOrdenacao || [
          { campo: "codigo", desc: false },
        ],
        camposFiltro: dataSourceOpcoes?.camposFiltro,
      });

    const configs =
      assertConfiguracaoExibicaoEBuscaType<EnderecoEstoqueGridModel>({
        expressaoDeBusca: ["codigo", "descricao"],
        nomeCampoChave: "id",
        nomeCampoExibicao: (c) => {
          if (c) {
            return c.descricao == null
              ? `${c.codigo}`
              : `${c.codigo} - ${c.descricao}`;
          }

          return "";
        },
      });
    return [dataSource, configs];
  }
}
