import { EditorStyle } from "devextreme/common";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";
import { selecionarInputAoFocarNoElemento } from "../../../../utils/inputs/inputs-utils";
import "./number-box-grid-edit.css";

export const GridNumberBoxProps = {
  onFocusIn: selecionarInputAoFocarNoElemento,
  className: "grid-edit-number-box",
  format: obterFormatStringNumero(5),
  stylingMode: "outlined" as EditorStyle,
};
