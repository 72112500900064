import { AxiosResponse } from "axios";
import { UseFormGetValues } from "react-hook-form";
import TabContainer from "../../../../../../../components/layout/tab-container";
import RequerPermissao from "../../../../../../../components/seguranca/requer-permissao";
import { ResponseBaseArquivo } from "../../../../../../../models/api/comum/response-base";
import { PermissoesOrdemDeProducao } from "../../../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import GridAnexos, {
  OperacoesFieldArray,
} from "../../../../../../sistema/arquivo/componentes/grid";
import { OrdemDeProducaoRequest } from "../../../../models/ordem-de-producao.api";

interface OrdemDeProducaoAnexosProps {
  idRegistro: number;
  getValues: UseFormGetValues<OrdemDeProducaoRequest>;
  funcaoParaBaixarAnexo: (
    idAnexo: number
  ) => Promise<AxiosResponse<ResponseBaseArquivo, any>>;
  operacoesFieldArray: OperacoesFieldArray;
}

export default function OrdemDeProducaoAbaAnexos(
  props: OrdemDeProducaoAnexosProps
) {
  return (
    <TabContainer>
      <RequerPermissao
        codigoPermissoes={[PermissoesOrdemDeProducao.ConsultarAnexos]}
      >
        <GridAnexos
          idRegistroRelacionado={props.idRegistro}
          operacoesFieldArray={props.operacoesFieldArray}
          permissaoIncluir={PermissoesOrdemDeProducao.InserirAnexos}
          permissaoExcluir={PermissoesOrdemDeProducao.ExcluirAnexos}
          permissaoVisualizarEBaixar={PermissoesOrdemDeProducao.ConsultarAnexos}
          funcaoParaBaixarAnexo={props.funcaoParaBaixarAnexo}
          arquivos={props.getValues().arquivos}
        />
      </RequerPermissao>
    </TabContainer>
  );
}
