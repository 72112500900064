import getApi from "../../../configs/api";
import { ResponseModel } from "../../../models/api/comum/response-base";
import {
  CalcularDepreciacaoRequest,
  CalcularDepreciacaoResponse,
} from "../../../models/api/imobilizado/imobilizado";
import { NomesEndpoints } from "../../comum/nomesEndpoints";
import { ServiceBase } from "../../comum/serviceBase";

export class ImobilizadoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Imobilizado);
  }

  public async CalcularDepreciacao(model: CalcularDepreciacaoRequest) {
    const api = getApi();
    const response = await api.post<ResponseModel<CalcularDepreciacaoResponse>>(
      `${this._nomeEndpoint}/CalcularDepreciacao`,
      model
    );

    return response.data;
  }
}
