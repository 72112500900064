import getApi from "../../../../configs/api";
import { ResponseModel } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { TarefaOuAnotacaoResponse } from "../models/tarefa-ou-anotacao.api";

export default class TarefaOuAnotacaoServico extends ServiceBase {
  constructor() {
    super(NomesEndpoints.TarefaOuAnotacao);
  }

  public async FinalizarTarefaOuAnotacao(id: number) {
    const api = getApi();
    const response = await api.patch<ResponseModel<TarefaOuAnotacaoResponse>>(
      `${this._nomeEndpoint}/FinalizarTarefaOuAnotacao?tarefaOuAnotacaoId=${id}`
    );

    return response.data;
  }
}
