import { GridComponentSimples } from "../../../../../components/componentes-simples/grid-simples";
import {
  ConfiguracoesErros,
  TipoErro,
} from "../../../../../models/api/comum/erros";
import { QuantidadeInsuficienteParaBaixarPorExplosao } from "./insumo-da-ordem-de-producao.erros";

export const configuracoesErrosInsumoDaOrdemDeProducao =
  Object.freeze<ConfiguracoesErros>({
    "INSOP-0001": {
      tipo: TipoErro.Confirmacao,
      formatador(erro) {
        const dadosExtras = (
          erro as QuantidadeInsuficienteParaBaixarPorExplosao
        ).dadosExtras;
        return (
          <div>
            <p>
              Não foi possível prosseguir com a conclusão da ordem de produção,
              pois os insumos abaixo não possuem estoque suficiente para
              realizar a explosão.
            </p>
            {dadosExtras && dadosExtras.length > 0 && (
              <GridComponentSimples
                dados={dadosExtras}
                nomesColunas={[
                  "Código",
                  "Descrição",
                  "Quantidade necessária",
                  "Quantidade disponível",
                ]}
                colunasParaIgnorar={["itemId"]}
              />
            )}
          </div>
        );
      },
    },
  });
