import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  FormSelectBox,
  FormSelectBoxLazyMxp,
} from "../../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import SelectItem from "../../../../../../models/shared/ui/select-item";
import { GridEnderecoEstoque } from "../../../../../../parts/estoque/grids/grid-padrao";
import { ContaContabilService } from "../../../../../../services/contabilidade/conta-contabil/conta-contabil.service";
import { EnderecoDeEstoqueService } from "../../../../../../services/estoque/endereco-estoque/endereco-estoque.service";
import { FinalidadeTipoHelper } from "../../../../../contabilidade/conta-contabil/models/conta-contabil.enum";
import {
  EstoqueMovimentacaoTipo,
  EstoqueMovimentacaoTipoHelper,
} from "../../../models/estoque-movimentacao.enums";

export interface SelecionarEstoqueDestinoRequest {
  idEnderecoDeEstoque?: number | null;
  idContaContabilDestino?: number | null;
  destino: EstoqueMovimentacaoTipo;
}

interface SelecionarEstoqueDestinoProps<
  T extends SelecionarEstoqueDestinoRequest
> {
  hookForms: UseFormReturn<T>;
}

const apiEnderecoEstoque = new EnderecoDeEstoqueService();
const apiContaContabil = new ContaContabilService();

const datasouceDestinos = EstoqueMovimentacaoTipoHelper.AsSelectItems().filter(
  (x) =>
    x.valor === EstoqueMovimentacaoTipo.Estoque ||
    x.valor === EstoqueMovimentacaoTipo.ContaContabil
);

const [dataSourceEnderecoEstoqueGrid, enderecoEstoqueGridExibicaoEBusca] =
  apiEnderecoEstoque.GetDadosSelectBoxItem();

const dataSourceContaContabilGrid =
  apiContaContabil.GetDataSourceSelectBoxContasDeEstoque();

let dataSourceContaContabilGridCarregado: any[] = [];

export default function SelecionarEstoqueDestino(
  props: SelecionarEstoqueDestinoProps<SelecionarEstoqueDestinoRequest>
) {
  const [
    dataSourceContaContabilGridFiltrado,
    setDataSourceContaContabilGridFiltrado,
  ] = useState<SelectItem[]>([]);

  const { control, getValues, setValue, watch } = props.hookForms;

  useEffect(() => {
    CarregarContasContabeis();
  }, []);

  useEffect(() => {
    filtraContasContabeis();
  }, [watch("destino")]);

  useEffect(() => {
    if (!getValues("idContaContabilDestino")) {
      filtraContasContabeis();
    }
  }, [watch("idContaContabilDestino")]);

  async function CarregarContasContabeis() {
    // Carregar opções com limite de 1000
    dataSourceContaContabilGrid.loadOptions().take = 1000;
    await dataSourceContaContabilGrid.load();
    dataSourceContaContabilGridCarregado = dataSourceContaContabilGrid.items();

    // Filtrar contas contábeis
    filtraContasContabeis();
  }

  function filtraContasContabeis() {
    const destino = getValues("destino");
    const isEstoque = destino == EstoqueMovimentacaoTipo.Estoque;

    const contasFiltradas = dataSourceContaContabilGridCarregado.filter((x) =>
      isEstoque
        ? FinalidadeTipoHelper.isFinalidadeDeEstoque(x.finalidade)
        : !FinalidadeTipoHelper.isFinalidadeDeEstoque(x.finalidade)
    );

    const contasFormatadas = contasFiltradas.map((x) => ({
      valor: x.id,
      descricao: x.apelido,
    }));

    setDataSourceContaContabilGridFiltrado(contasFormatadas);
    if (
      contasFiltradas.length > 0 &&
      !contasFiltradas.some((x) => x.id == getValues("idContaContabilDestino"))
    ) {
      setValue("idContaContabilDestino", contasFiltradas[0].id);
    }
  }

  return (
    <Linha>
      <Coluna md={3}>
        <FormSelectBox
          name="destino"
          titulo="Destino"
          control={control}
          dataSource={datasouceDestinos}
          requerido
        />
      </Coluna>
      <Coluna md={4}>
        <FormSelectBox
          name="idContaContabilDestino"
          titulo="Conta"
          control={control}
          dataSource={dataSourceContaContabilGridFiltrado}
          labelSemDados="Sem dados"
          requerido
        />
      </Coluna>
      <Coluna md={5}>
        <FormSelectBoxLazyMxp
          name="idEnderecoDeEstoque"
          titulo="Endereço de estoque"
          control={control}
          dataSource={dataSourceEnderecoEstoqueGrid}
          configuracoesExibicaoEBusca={enderecoEstoqueGridExibicaoEBusca}
          seletorConfig={{
            modo: "selecaoUnica",
            titulo: "Selecionar o endereço de estoque",
            componenteGrid: <GridEnderecoEstoque />,
          }}
          labelSemDados="Sem dados"
        />
      </Coluna>
    </Linha>
  );
}
