export enum OrigemInsumo {
  EstruturaDoItem = 1,
  Manual = 2,
}

export enum TipoDoEstoqueInsumo {
  ConsumirEstoque = 1,
  ManterInsumo = 2,
}

export enum EstadoDaTerceirizacao {
  FornecidoPorNos = 1,
  FornecidoPeloCliente = 2,
  SemTerceirizacao = 3,
}

export enum InsumoEstado {
  /// <summary>
  /// A baixar.
  /// </summary>
  ABaixar = 1,

  /// <summary>
  /// Baixa parcial.
  /// </summary>
  BaixadoParcialmente = 2,

  /// <summary>
  /// Baixado.
  /// </summary>
  BaixadoCompletamente = 3,
}

export enum InsumoTipoBaixa {
  /// <summary>
  /// Manual.
  /// </summary>
  Manual = 1,

  /// <summary>
  /// Por explosão.
  /// </summary>
  PorExplosao = 2,

  /// <summary>
  /// Não exige baixa.
  /// </summary>
  NaoExigeBaixa = 3,
}
